<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        {{ options.actionType | capitalize }} {{ options.objectType }}
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <template v-if="options.objectType == 'bank account'">
          <div class="form-group half">
            <label>Name of bank</label>
            <input v-model="form.bank_name" type="text" class="form-control" placeholder="EMI">
            <span v-show="formErrors.bank_name" class="invalid-feedback">{{ formErrors.bank_name }}</span>
          </div>
          <div class="form-group half">
            <label>Type of account</label>
            <input v-model="form.account_type" type="text" class="form-control" placeholder="SEPA">
            <span v-show="formErrors.account_type" class="invalid-feedback">{{ formErrors.account_type }}</span>
          </div>
        </template>
        <template v-else-if="options.objectType == 'merchant account'">
          <div class="form-group half">
            <label>Type of license</label>
            <input v-model="form.license_type" type="text" class="form-control" placeholder="EMI">
            <span v-show="formErrors.license_type" class="invalid-feedback">{{ formErrors.license_type }}</span>
          </div>
          <div class="form-group half">
            <label>Type of Zone</label>
            <input v-model="form.zone_type" type="text" class="form-control" placeholder="SEPA">
            <span v-show="formErrors.zone_type" class="invalid-feedback">{{ formErrors.zone_type }}</span>
          </div>
        </template>
        <div class="form-group half">
          <label>Open/Integration fee</label>
          <div class="inpt-block">
            <input v-model="form.open_integration_fee" type="text" class="form-control" placeholder="2.500.00">
            <div class="currency-symbol">€</div>
          </div>
          <span v-show="formErrors.open_integration_fee" class="invalid-feedback">{{ formErrors.open_integration_fee }}</span>
        </div>
        <div class="form-group half">
          <label>Onboarding time</label>
          <div class="inpt-block">
            <input v-model="form.onboarding_time" type="text" class="form-control" placeholder="3">
            <div class="desc">works day</div>
          </div>
          <span v-show="formErrors.onboarding_time" class="invalid-feedback">{{ formErrors.onboarding_time }}</span>
        </div>
        <div class="form-group">
          <label>Country</label>
          <div class="inpt-block">
            <select class="form-control" style="width: 100%;" v-model="form.country_id">
              <option value="null">Select country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
          </div>
          <span v-show="formErrors.country_id" class="invalid-feedback">{{ formErrors.country_id }}</span>
        </div>
        <div class="form-group half">
          <label>Incoming fee</label>
          <input v-model="form.incoming_fee" type="text" class="form-control" placeholder="0.2%, min 10.00€, max 100.00€">
          <span v-show="formErrors.incoming_fee" class="invalid-feedback">{{ formErrors.incoming_fee }}</span>
        </div>
        <div class="form-group half">
          <label>Outgoing fee</label>
          <input v-model="form.outgoing_fee" type="text" class="form-control" placeholder="0.5%, min 15.00€, max 350.00€">
          <span v-show="formErrors.outgoing_fee" class="invalid-feedback">{{ formErrors.outgoing_fee }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  created () {
    this.getCountries()
    if (this.options.actionType == 'edit' ) {
      this.getAccount(this.options.id)
    }
  },
  data() {
    return {
      message: '',
      error: '',
      countries: [],
      form: {
        bank_name: '',
        account_type: '',
        open_integration_fee: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: '',
        license_type: '',
        zone_type: '',
        country_id: null,
      },
      formErrors: {
        bank_name: '',
        account_type: '',
        open_integration_fee: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: '',
        license_type: '',
        zone_type: '',
        country_id: '',
      }
    };
  },
  methods: {
    clearErrors() {
      for (let key in this.form) {
        this.formErrors[key] = ''
      }
    },
    getCountries() {
      const that = this
      axios.get('admin/data/countries')
        .then(function (response) {
          that.countries = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getAccount () {
      const that = this
      axios.get('admin/accounts/get-account/' + this.options.id, this.form)
      .then(function (response) { 
        that.form = response.data
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    async submit() {
      const that = this
      this.clearErrors()
      if (this.options.actionType == 'create') {
        this.form.type = (this.options.objectType == 'bank account') ? 1 : 2;
      }
      axios.post(this.options.url, this.form)
      .then(function (response) { 
        if (response.data.errors) {
          const errors = response.data.errors
          for (let key in errors) {
            that.formErrors[key] = errors[key][0]
          }
        }
        if (response.data.success) {
          that.close()
          that.$noty.success(that.options.successfullMessage)
          that.$store.dispatch('editItemFromTable', {
            id: that.options.id,
            table: that.options.table,
            actionType: that.options.actionType
          })
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
}
</style>
