<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Company {{ options.company.name }}
      </div>
    </div>
    <div class="modal__body">
      <overlay-scrollbars>
        <div class="form">
          <div class="form-group select">
            <label for="ubo_residential">UBO Residential</label>
            <div class="select-wrapper">
              <SelectMultiple
                :list="options.countries"
                value_key="id"
                name_key="name"
                placeholder="Select Country"
                :chosen_values="ubo_residential"
                @selected="obj => ubo_residential = Object.keys(obj)"
              />
            </div>
          </div>
          <div class="form-group select">
            <label for="country_of_incorporation">Country of incorporation</label>
            <div class="select-wrapper">
              <SelectSimple
                :list="options.countries"
                value_key="id"
                name_key="name"
                placeholder="Select Country"
                :chosen_value="country_of_incorporation"
                @selected="item => country_of_incorporation = item.id"
              />
            </div>
          </div>
          <div class="form-group select">
            <label for="industry">Field of activity</label>
            <div class="select-wrapper">
              <SelectSimple
                :list="options.industries"
                value_key="id"
                name_key="name"
                placeholder="Select activity"
                :chosen_value="industry"
                @selected="item => industry = item.id"
              />
            </div>
          </div>
          <div class="form-group" v-if="isShowOtherIndustryField">
            <label for="industry">Other activity</label>
            <input
              name="other_industry"
              id="other_industry"
              v-model="other_industry"
              placeholder="Other industry"
            />
          </div>
          <div class="form-group select">
            <label for="country_of_incorporation">Type of License</label>
            <div class="select-wrapper">
              <SelectSimple
                :list="options.licenseTypes"
                value_key="id"
                name_key="name"
                placeholder="Select Type"
                :chosen_value="license_type_id"
                @selected="item => license_type_id = item.id"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="company_name">Company Name</label>
            <input
              name="company_name"
              id="company_name"
              v-model="company_name"
              placeholder="e.g. Digigtal Flat [Bo]"
            />
          </div>
          <div class="form-group">
            <label for="company_address">Company Address</label>
            <input
              name="company_address"
              id="company_address"
              v-model="company_address"
              placeholder="e.g. London SW1A 1AA"
            />
          </div>
          <div class="form-group select">
            <label for="director_residence">Director Residence</label>
            <div class="select-wrapper">
              <SelectSimple
                :list="options.countries"
                value_key="id"
                name_key="name"
                placeholder="Select Country"
                :chosen_value="director_residence"
                @selected="item => director_residence = item.id"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="phone_number">Phone Number</label>
            <input
              name="phone_number"
              id="phone_number"
              v-model="phone_number"
              placeholder="e.g. Ecommerce"
            />
          </div>
          <div class="form-group">
            <label for="email">Company Email</label>
            <input
              name="email"
              id="email"
              v-model="email"
              placeholder="company@email.com"
            />
          </div>
          <div class="form-group">
            <label for="website_link">Website Link</label>
            <input
              name="website_link"
              id="website_link"
              v-model="website_link"
              placeholder="e.g. London SW1A 1AA"
            />
          </div>
          <div class="form-group">
            <label for="monthly_turnover">Monthly Turnover</label>
            <input
              name="monthly_turnover"
              id="monthly_turnover"
              v-model="monthly_turnover"
              placeholder="Select Monthly Turnover"
            />
          </div>
          <div class="form-group">
            <label for="compliance">Compliance</label>
            <input
              name="compliance"
              type="number"
              id="compliance"
              v-model="compliance"
              placeholder="Select compliance"
            />
          </div>
          <div class="form-group select">
            <label for="geo_clients">Geo of Clients</label>
            <div class="select-wrapper">
              <SelectMultiple
                v-if="options.countries"
                :list="options.countries"
                value_key="id"
                name_key="name"
                placeholder="Geo"
                :chosen_values="geo_clients"
                @selected="obj => geo_clients = Object.keys(obj)"
              />
            </div>
          </div>
          <div id="risks" class="form-group">
            <label>Risk level</label>
            <div class="risks-items">
              <div class="form-check risk-check" v-for="(risk, index) in risks" :key="index">
                <label :for="'risk_' + index">{{ risk.name }}</label>
                <input v-model="risk_id" :value="risk.id" class="form-check-input" type="radio" :id="'risk_' + index">
              </div>
            </div>
          </div>
          <div class="form-group select">
            <label for="hidden_bank_offers">Hidden Bank Account Offers</label>
            <div class="select-wrapper">
              <SelectMultiple
                id="hidden_bank_offers"
                v-if="options.bankAccountOffers"
                :list="options.bankAccountOffers"
                value_key="id"
                name_key="name"
                placeholder="Hidden Bank Account Offers"
                :key="hidden_bank_offers_key"
                :chosen_values="hidden_bank_offers"
                @selected="obj => hidden_bank_offers = Object.keys(obj)"
              />
            </div>
          </div>
          <div class="form-group select">
            <label for="hidden_acquiring_offers">Hidden Acquiring Account Offers</label>
            <div class="select-wrapper">
              <SelectMultiple
                id="hidden_acquiring_offers"
                v-if="options.acquiringAccountOffers"
                :list="options.acquiringAccountOffers"
                value_key="id"
                name_key="name"
                placeholder="Hidden Acquiring Account Offers"
                :key="hidden_acquiring_offers_key"
                :chosen_values="hidden_acquiring_offers"
                @selected="obj => hidden_acquiring_offers = Object.keys(obj)"
              />
            </div>
          </div>
          <div class="form-group select">
            <label for="hidden_crypto_offers">Hidden Crypto Account Offers</label>
            <div class="select-wrapper">
              <SelectMultiple
                id="hidden_crypto_offers"
                v-if="options.cryptoAccountOffers"
                :list="options.cryptoAccountOffers"
                value_key="id"
                name_key="name"
                placeholder="Hidden Crypto Account Offers"
                :key="hidden_crypto_offers_key"
                :chosen_values="hidden_crypto_offers"
                @selected="obj => hidden_crypto_offers = Object.keys(obj)"
              />
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="modal__footer">
      <div v-if="confirmDelete" class="confirm-delete">
        <h3>Are you sure you want to delete the company?</h3>
        <div class="options">
          <div class="btn btn__save" @click="deleteCompany">Yes, delete company</div>
          <div class="btn btn__delete" @click="confirmDelete = false">NO</div>
        </div>
      </div>
      <div v-else class="options">
        <div class="btn btn__save" @click="submit">Save data</div>
        <div class="btn btn__delete" @click="confirmDelete = true">Delete company</div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectSimple from '@/components/common/Select.vue'
import SelectMultiple from '@/components/common/SelectMultiple.vue'

import Close from './../img/Close.vue'

export default {
  components: {
    SelectSimple,
    SelectMultiple,
    Close,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    type_bank: {
      default: 1,
    },
    type_acquiring: {
      default: 2
    },
    type_crypto: {
      default: 3
    },
  },
  data() {
    return {
      ubo_residential: [],
      country_of_incorporation: null,
      industry: null,
      other_industry: '',
      company_name: null,
      company_address: null,
      director_residence: null,
      phone_number: null,
      email: null,
      compliance: null,
      website_link: null,
      monthly_turnover: null,
      geo_clients: [],
      confirmDelete: false,
      license_type_id: null,
      risk_id: null,
      risks: [],
      hidden_bank_offers: [],
      hidden_acquiring_offers: [],
      hidden_crypto_offers: [],
      hidden_bank_offers_key: 0,
      hidden_acquiring_offers_key: 0,
      hidden_crypto_offers_key: 0,
      isShowOtherIndustryField: false,
    };
  },
  async created() {
    await this.setData()
    await this.getRisks()
  },
  methods: {
    async getRisks() {
      await axios.get('admin/data/risks').then(response => {
        response.data.sort((a, b) => a.order < b.order ? -1 : (a.order > b.order ? 1 : 0))
        
        this.risks = response.data.map(risk => ({
          id: risk.id,
          name: risk.name,
          code: risk.code,
        }));
      }).catch(error => {
        console.log(error);
      })
    },
    async setData() {
      if (!this.options.company) return

      this.ubo_residential = this.options.company.business_settings.ubo_residentials_ids || []
      this.country_of_incorporation = this.options.company.incorporation_country_id
      this.industry = this.options.company.industry_id
      this.company_name = this.options.company.name
      this.company_address = this.options.company.address
      this.director_residence = this.options.company.director_residence_country_id
      this.phone_number = this.options.company.phone
      this.email = this.options.company.email
      this.website_link = this.options.company.website
      this.monthly_turnover = this.options.company.business_settings.monthly_turnover
      this.geo_clients = this.options.company.clients_geo_countries_ids
      this.license_type_id = this.options.company.license_type_id
      this.compliance = this.options.company.compliance
      this.risk_id = this.options.company.risk_id
      this.other_industry = this.options.company.other_industry
      
      this.options.company.hidden_offers.forEach(el => {
        if(el.type == this.type_bank){
          this.hidden_bank_offers.push(el.id)
        }
        
        if(el.type == this.type_acquiring){
          this.hidden_acquiring_offers.push(el.id)
        }

        if(el.type == this.type_crypto){
          this.hidden_crypto_offers.push(el.id)
        }
      })

      //console.log(this.hidden_bank_offers);

      this.hidden_bank_offers_key++
      this.hidden_acquiring_offers_key++
      this.hidden_crypto_offers_key++
    },
    submit() {
      const data = {
        ubo_residential: this.ubo_residential,
        country_of_incorporation: this.country_of_incorporation,
        industry: this.industry,
        company_name: this.company_name,
        company_address: this.company_address,
        director_residence: this.director_residence,
        phone_number: this.phone_number,
        email: this.email,
        website_link: this.website_link,
        monthly_turnover: this.monthly_turnover,
        geo_clients: this.geo_clients,
        license_type_id: this.license_type_id,
        compliance: this.compliance,
        risk_id: this.risk_id,
        other_industry: this.other_industry,
        hidden_offers: [...this.hidden_bank_offers, ...this.hidden_acquiring_offers, ...this.hidden_crypto_offers]
      }

      axios.post(`admin/customers/company/${this.options.company.id}/update`, data)
        .then(({ data }) => {
          this.close()
          if (data.success) {
            this.options.fetchData()
            this.$noty.success('Company data successfully updated')
          } else {
            this.$noty.error('Company data was not updated')
          }
        })
        .catch(error => console.log(error))
    },
    async deleteCompany() {
      axios.delete(`admin/customers/company/${this.options.company.id}/delete`)
        .then(({ data }) => {
          this.close()
          if (data.success) {
            this.options.fetchData()
            this.$noty.success('Company successfully deleted')
          } else {
            this.$noty.error('Company was not deleted')
          }
        })
        .catch(error => console.log(error))
    },
    close() {
      this.$emit("close");
    },
  },
  watch: {
    'industry': {
        handler(newVal, oldVal) {
          let newIndustry = this.options.industries.find(industry => industry.id === newVal)
          
          if(newIndustry && newIndustry.name) {
            if(newIndustry.name.toLowerCase() == 'other'){
              this.isShowOtherIndustryField = true
            }
            else{
              this.isShowOtherIndustryField = false
              this.other_industry= ''
            }

            this.bankCompilance.fields[3].id++
          }
        },
        deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__body {
    height: 68vh;
    padding-right: 2.5rem;
    overflow: hidden;

    .os-host {
      height: 100%;
      width: 100%;
    }
  }

  .form {
    width: 100%;
    padding-right: 1rem;

    &-group {
      --select_height: 46px;

      label {
        font-weight: 700;
        font-size: 20px;
        line-height: 2;
      }

      input,
      select {
        min-width: 100%;
        max-width: 100%;
        height: var(--select_height);
        padding: 12px 25px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 24px;
        outline: none;
      }

      select {
        appearance: none;
        cursor: pointer;
      }
    }
  }

  .select {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: calc(var(--select_height) / 2);
      right: 1.5rem;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      pointer-events: none;
    }
  }

  &__footer {
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    &__delete {
      background: var(--c_error);
    }
  }
}
#risks {
  #risks>label {
    margin-bottom: 10px;
  }

  .form-check-input[type=radio] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
  }

  .risks-items{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .risk-check{
    min-width: 120px;
    &>label{
      display: block;
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    margin: 0;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #0071e3;
    border-color: #0071e3;
  }
}
</style>
