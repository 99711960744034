<template>
  <svg viewBox="0 0 12 10" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.852 0.925711C11.6551 0.73035 11.3354 0.73035 11.1385 0.925711L3.99981 8.01696L0.861103 4.92274C0.664246 4.72737 0.344499 4.72737 0.147643 4.92274C-0.0492142 5.1181 -0.0492142 5.43436 0.147643 5.62922L3.64455 9.07616C3.83941 9.26903 4.16365 9.26903 4.35851 9.07616L11.8519 1.63219C12.0493 1.43733 12.0493 1.12057 11.852 0.925711C11.6551 0.73035 12.0493 1.12057 11.852 0.925711Z" :fill="fill"/>
  </svg>
</template>
<script>
export default {
   name: 'Check',
   props: {
      fill: {
         type: String,
         default: 'green'
      }
   }
}
</script>