<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img" />
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Applied Offer
      </div>
    </div>
    <div class="modal__body">
      <div class="box box_selectedAcc">
        <div class="header">
        </div>
        <div class="content">
          <div class="block">
            <div class="hdr">
              <!-- <Bank2 class="img" /> -->
              <div class="ttl">Bank account</div>
            </div>
            <Table @cancel="cancelBankOffer" @apply="applyBankOffer" :key="bankAccKey" :options="bankAcc" />
          </div>
          <div class="block block_mt">
            <div class="hdr">
              <!-- <CreditCards class="img" /> -->
              <div class="ttl">Acquiring</div>
            </div>
            <Table @cancel="cancelBankOffer" @apply="applyBankOffer" :key="merchantAccKey" :options="merchantAcc" />
          </div>
          <div class="block block_mt">
            <div class="hdr">
              <!-- <CreditCards class="img" /> -->
              <div class="ttl">E-Wallets</div>
            </div>
            <Table @cancel="cancelBankOffer" @apply="applyBankOffer" :key="digitalAccKey" :options="digitalAcc" />
          </div>
          <div class="block block_mt">
            <div class="hdr">
              <!-- <CreditCards class="img" /> -->
              <div class="ttl">Legal Service</div>
            </div>
            <Table @apply="cancelLegal" :key="legalKey" :options="legalService" />
          </div>
          <div class="block block_mt" ref="bosBlock">
            <div class="hdr">
              <div class="ttl">Buy & Sell NET License</div>
            </div>
            <Table :options="businesOffers" :key="businesOffersKey" @apply="cancelBusinesOffer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import Info from './../elem/Info.vue'
import Compliance from './../elem/Compliance.vue'
import Table from './../elem/Table.vue'
import Bank2 from './../img/Bank2.vue'
import CreditCards from './../img/CreditCards.vue'

export default {
  components: {
    Close,
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      bankOffersData: [],
      bankAccKey: 0,
      merchantOffersData: [],
      digitalOffersData: [],
      merchantAccKey: 0,
      digitalAccKey: 0,
      legalData: [],
      legalKey: 0,
      bankAcc: {
        colsWidth: ['3%', '10%', '10%', '10%', '11%', '12%', '15%', '10%', '12%', '10%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Name of financial institution', info: null },
          { ttl: 'Type of Account', info: null },
          { ttl: 'Open/Integration fee', info: null },
          { ttl: 'Incoming fee', info: null },
          { ttl: 'Outgoing fee', info: null },
          { ttl: 'Onboarding time', info: null },
          { ttl: 'Status', info: null },
          { ttl: 'Request', info: null },
          { ttl: '', info: null },
        ],
        body: [],
      },
      merchantAcc: {
        colsWidth: ['3%', '10%', '10%', '10%', '11%', '12%', '15%', '10%', '12%', '10%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Type of License', info: null },
          { ttl: 'Type of Zone', info: null },
          { ttl: 'Open/Integration fee', info: null },
          { ttl: 'Incoming fee', info: null },
          { ttl: 'Outgoing fee', info: null },
          { ttl: 'Onboarding time', info: null },
          { ttl: 'Status', info: null },
          { ttl: 'Request', info: null },
          { ttl: '', info: null },
        ],
        body: [],
      },
      digitalAcc: {
        colsWidth: ['3%', '10%', '10%', '10%', '11%', '12%', '15%', '10%', '12%', '10%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Type of License', info: null },
          { ttl: 'Type of Zone', info: null },
          { ttl: 'Open/Integration fee', info: null },
          { ttl: 'Incoming fee', info: null },
          { ttl: 'Outgoing fee', info: null },
          { ttl: 'Onboarding time', info: null },
          { ttl: 'Status', info: null },
          { ttl: 'Request', info: null },
          { ttl: '', info: null },
        ],
        body: [],
      },
      legalService: {
        colsWidth: ['3%', '13%', '12%', '12%', '12%', '27%', '12%', '5%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Name of Legal Co.', info: null },
          { ttl: 'Country', info: null },
          { ttl: 'Type of Services', info: null },
          { ttl: 'Price', info: null },
          { ttl: 'Description of Benefit', info: null },
          { ttl: 'Service time deadline', info: null },
          { ttl: '', info: null },
        ],
        body: [],
      },
      businesOffersKey: 1,
      businesOffers: {
        colsWidth: ['4%', '17%', '17%', '16%', '34%', '12%'],
        header: [
          { ttl: '#' },
          { ttl: 'Type of Business' },
          { ttl: 'Type of License' },
          { ttl: 'Country' },
          { ttl: 'Description' },
          { ttl: 'Request' }
        ],
        body: []
      }
    };
  },
  created() {
    this.getBankOffers()
    this.getMerchantOffers()
    this.getDigitalOffers()
    this.getAppliedLegalServices()
    this.getAppliedBusinesOffers()
  },
  methods: {
    getBankOffers() {
      axios.get('admin/all-offers/applied/type/bank/' + this.$route.params.id)
        .then(response => {
          this.bankOffersData = response.data
          this.bankAcc.body = []
          this.bankOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.offer.time_left) ? el.offer.time_left.days_number : ''
            const timeLeftHoursNumber = (el.offer.time_left) ? el.offer.time_left.hours_number : '';
            let statusText = '';

            if (el.status === 0) {
              statusText = 'Applied'
            } else if (el.status === 1) {
              statusText = 'Confirmed'
            } else if (el.status === 2) {
              statusText = 'Rejected'
            }

            this.bankAcc.body.push([
              el.offer.serial_number.toString(),
              el.offer.financial_institution_name,
              el.offer.account_type, this.number_format(el.offer.open_integration_fee, 2, '.', ' ') + '€',
              el.offer.incoming_fee,
              el.offer.outgoing_fee,
              { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber },
              statusText,
              el.status !== 1 ? { type: 'apply-btn', html: 'Confirm', applyType: 'bank-offer', id: el.id } : '',
              el.status !== 2 ? { type: 'cancel', applyType: 'bank-offer', id: el.id } : ''
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantOffers() {
      axios.get('admin/all-offers/applied/type/merchant/' + this.$route.params.id)
        .then(response => {
          this.merchantOffersData = response.data
          this.merchantAcc.body = []
          this.merchantOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.offer.time_left) ? el.offer.time_left.days_number : ''
            const timeLeftHoursNumber = (el.offer.time_left) ? el.offer.time_left.hours_number : ''
            let statusText = '';

            if (el.status === 0) {
              statusText = 'Applied'
            } else if (el.status === 1) {
              statusText = 'Confirmed'
            } else if (el.status === 2) {
              statusText = 'Rejected'
            }

            this.merchantAcc.body.push([
              el.offer.serial_number.toString(),
              el.offer.financial_institution_name,
              el.offer.country_code,
              this.number_format(el.offer.open_integration_fee, 2, '.', ' ') + '€',
              el.offer.incoming_fee,
              el.offer.outgoing_fee,
              { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber },
              statusText,
              el.status !== 1 ? { type: 'apply-btn', html: 'Confirm', applyType: 'merchant-offer', id: el.id } : '',
              el.status !== 2 ? { type: 'cancel', applyType: 'merchant-offer', id: el.id } : ''
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getDigitalOffers() {
      axios.get('admin/all-offers/applied/type/digital/' + this.$route.params.id)
        .then(response => {
          this.digitalOffersData = response.data
          this.digitalAcc.body = []
          this.digitalOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.offer.time_left) ? el.offer.time_left.days_number : ''
            const timeLeftHoursNumber = (el.offer.time_left) ? el.offer.time_left.hours_number : ''
            let statusText = '';

            if (el.status === 0) {
              statusText = 'Applied'
            } else if (el.status === 1) {
              statusText = 'Confirmed'
            } else if (el.status === 2) {
              statusText = 'Rejected'
            }

            this.digitalAcc.body.push([
              el.offer.serial_number.toString(),
              el.offer.financial_institution_name,
              el.offer.country_code,
              this.number_format(el.offer.open_integration_fee, 2, '.', ' ') + '€', el.offer.incoming_fee, el.offer.outgoing_fee,
              { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber },
              statusText,
              el.status !== 1 ? { type: 'apply-btn', html: 'Confirm', applyType: 'digital-offer', id: el.id } : '',
              el.status !== 2 ? { type: 'cancel', applyType: 'digital-offer', id: el.id } : ''
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedLegalServices() {
      const that = this
      axios.get('admin/legal-atlas/applied/' + this.$route.params.id)
        .then(function (response) {
          that.legalService.body = []
          that.legalData = response.data
          that.legalData.forEach(el => {
            that.legalService.body.push([
              el.serial_number.toString(), el.legal_name, el.country ? el.country.code : '', el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
              el.benefit_description, { type: 'deadline_legal', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedBusinesOffers() {
      axios.get('admin/business-offers/applied/' + this.$route.params.id)
        .then(({ data }) => {
          this.businesOffers.body = data.data
          setTimeout(() => {
            var element = this.$refs.bosBlock
            element.scrollIntoView({ behavior: 'smooth', inline: 'start' })
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    apply(payload) {
      const that = this
      axios.post('admin/offers/cancel/' + payload.id + '/' + this.$route.params.id)
        .then((response) => {
          const res = response.data
          if (payload.applyType == 'bank-offer') {
            that.bankAcc.body = []
            that.bankOffersData = that.bankOffersData.filter(offer => offer.id !== payload.id)
            that.bankOffersData.forEach(el => {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.bankAcc.body.push([
                el.serial_number.toString(), el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber }, { type: 'cancel', applyType: 'bank-offer', id: el.id }
              ])
            })
            that.bankAccKey++
          } else if (payload.applyType == 'merchant-offer') {
            that.merchantAcc.body = []
            that.merchantOffersData = that.merchantOffersData.filter(offer => offer.id !== payload.id)
            that.merchantOffersData.forEach(el => {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.merchantAcc.body.push([
                el.serial_number.toString(), el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber }, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
              ])
            })
            that.merchantAccKey++
          }

        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    applyBankOffer(payload) {
      axios.post('admin/all-offers/confirm/user-offer/' + payload.id)
        .then(response => {
          if (response.data.success) {
            this.getBankOffers();
            this.getMerchantOffers()
            this.getDigitalOffers()
          }
        })
        .catch((errors) => console.dir(errors))
    },
    cancelBankOffer(payload){
      axios.post('admin/all-offers/cancel/user-offer/' + payload.id)
        .then(response => {
          if (response.data.success) {
            this.getBankOffers();
            this.getMerchantOffers()
            this.getDigitalOffers()
          }
        })
        .catch((errors) => console.dir(errors))
    },
    cancelLegal(payload) {
      const that = this
      axios.post('admin/legal-atlas/cancel/' + payload.id + '/' + this.$route.params.id)
        .then((response) => {
          const res = response.data
          if (res.canceled) {
            that.legalService.body = []
            that.legalData = that.legalData.filter(legal => legal.id !== payload.id)
            that.legalData.forEach(el => {
              that.legalService.body.push([
                el.serial_number.toString(), el.legal_name, el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
                el.benefit_description, { type: 'deadline', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
              ])
            })
            that.legalAccKey++
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    async cancelBusinesOffer(payload) {
      await axios.post('admin/business-offers/cancel/' + payload.id + '/' + this.$route.params.id)
        .then(({ data }) => {
          if (data.canceled) {
            this.businesOffers.body = this.businesOffers.body.filter(el => +el[0] !== +payload.id)
            this.businesOffersKey++
          }
        })
        .catch(errors => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    // height: 25rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    max-height: 80vh;
    display: block;
    padding-bottom: 2rem;
    overflow: auto;
  }
}

.box {
  &_selectedAcc {
    width: 100%;
  }
}

.tbl {
  color: #ffffff;
}

.block_mt {
  margin-top: 20px;
}
</style>
