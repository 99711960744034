<template>
	<overlay-scrollbars class="page page-personal" ref="os">
	    <div class="box-container">
	      <div class="transparent-box box_b-info">
	        <div class="content">
	          <div class="block">
	            <Table
	                class="requests-table"
	                :key="requestsTableKey"
	                :options="requestsTableData"
	                @change-page="getRequests"
	            />
	          </div>
	        </div>
	      </div>
	    </div>
	  </overlay-scrollbars>
</template>

<script>
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
	components: {
	    Pagination,
	    Table,
	},
	data: () => ({
	    requestsTableData: {
	      colsWidth: ['20%', '20%', '20%', '20%', '20%'],
	      header: [
	        { ttl: 'Subject', info: null },
	        { ttl: 'From', info: null },
	        { ttl: 'To', info: null },
	        { ttl: 'Date', info: null },
	        { ttl: 'Chat', info: null },
	      ],
	      body: [],
	      paginationData: null,
	    },
	    requestsTableKey: 0,
	    requestsTableDataPagination: {},
	  }),
	created () {
		this.getRequests()
	},
	methods: {
		async getRequests (page = 1) {
		  const that = this
	      await axios.get('admin/administrators/requests/' + this.$route.params.id + '?page=' + page)
	        .then(({ data }) => {
	          that.requestsTableData.body = []
	          that.requestsTableData.paginationData = data.requests
	          data.requests.data.forEach(el => {
	          	that.requestsTableData.body.push([
	          		el.subject,
	          		el.user_from.company_full_name,
	          		el.user_to.company_full_name,
	          		el.created_at_text,
	          		{ type: 'show-chat', chatId: el.chat_id},
	          	])
	          })
	        })
	    },
	}
}
</script>