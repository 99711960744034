<template>
  <div class="modal__wrapper" :class="{ 'modal__wrapper--tbl': buyPackages.length }">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Download Invoice
        <div v-if="paymentStatus && emptyText.length == 0" class="status-text">Status: {{ paymentStatus }}</div>
      </div>
    </div>
    <div class="modal__body">
      <div class="empty-text" v-if="!invoice.pdfUrl && buyPackages.length === 0">{{ emptyText }}</div>
      <Table v-if="buyPackages.length" :options="packagesTableData" :key="packagesTableKey"/>
      <object v-if="invoice.pdfUrl">
        <embed height="400px" :src="invoice.pdfUrl" />
      </object>
    </div>
    <div class="modal__footer">
      <template v-if="invoice.pdfUrl">
        <div class="btn" @click="approve">Approve</div>
        <div class="btn" @click="decline">Decline</div>
      </template>
    </div>
  </div>
</template>

<script>
import Close from '@/components/img/Close'
import Table from '@/components/elem/Table'

export default {
  components: {
    Close,
    Table
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      emptyText: '',
      paymentStatus: '',
      invoice: {
        data: null,
        pdfUrl: null,
      },
      buyPackages: [],
      packagesTableKey: 0,
    };
  },
  computed: {
    packagesTableData () {
      const data = {
        colsWidth: ['5%', '17%', '8%', '17%', '17%', '17%', '7%', '14%'],
        header: [
          { ttl: '#' },
          { ttl: 'Package Name' },
          { ttl: 'Package Price' },
          { ttl: 'Card Fullname' },
          { ttl: 'Card Country' },
          { ttl: 'Card Number' },
          { ttl: 'Card Expiration Date' },
          { ttl: 'Created At' },
        ],
        body: []
      }

      this.buyPackages.forEach(el => {
        data.body.push([
          el.id,
          el.package_name,
          `${+el.package_price} ${el.package_currency}`,
          el.card_fullname,
          el.card_country.name,
          el.card_number,
          el.card_exp_date,
          el.created_at_format,
        ])
      })

      return data
    }
  },
  created () {
    this.getPaymentStatus()
    this.getBuyPackages()
  },
  methods: {
    getPaymentStatus () {
      const that = this
      axios.get('admin/customers/invoice/' + this.$route.params.id)
        .then(function (response) {
          that.paymentStatus = (response.data.payment_status == 1) ? 'Approved' : 'Not approved'
          if (response.data.success && response.data.invoice) {
            that.invoice.data = response.data.invoice
            that.invoice.pdfUrl = process.env.VUE_APP_BACKEND_URL + '/storage/invoices/' + response.data.invoice.file

          } else {
            that.emptyText = 'There is no invoice yet'
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getBuyPackages () {
      axios.get('admin/customers/buy-packages/' + this.$route.params.id)
        .then(({ data }) => {
          this.buyPackages = data.packages
        })
        .catch(err => console.dir(err))
    },
    close() {
      this.$emit("close");
    },
    approve () {
      const that = this
      axios.post('admin/customers/approve-invoice/' + this.$route.params.id)
        .then(function (response) {
          if (response.data.success) {
            that.$noty.success('You successfully approved invoice')
            that.close()
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    decline () {
      const that = this
      axios.post('admin/customers/decline-invoice/' + this.$route.params.id)
        .then(function (response) {
          if (response.data.success) {
            that.$noty.success('You successfully declined invoice')
            that.close()
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;

    &--tbl {
      width: 100%;
      max-width: 85vw;
    }
  }

  &__body {
    flex-wrap: wrap;

    object {
      margin: .75rem .5rem;
    }
  }

  .empty-text {
    font-size: 1.1rem;
    font-weight: 400;
    padding-bottom: 3.5rem;
  }

  .status-text {
    font-size: 1.1rem;
    font-weight: 400;
    // padding-bottom: 3.5rem;
    padding-left: 0.5rem;
    padding-top: 3px;
  }
}
</style>
