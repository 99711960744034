<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">{{ isEdit ? 'Edit' : 'Create' }} Software Option</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Name</div>
        <div class="data">
          <input type="text" name="name" class="inpt" v-model="form.name">
        </div>
      </div>
      <div class="row">
        <div class="label">Slug</div>
        <div class="data">
          <input type="text" name="slug" class="inpt" v-model="form.slug" :readonly="options.form && !!options.form.slug">
        </div>
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    form: {
      name: null,
      slug: null,
      description: null,
    },
    error: null
  }),
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  created () {
    this.updateForm()
  },
  methods: {
    ...mapActions({
      createSoftwareOption: 'software_net/createOption',
      updateSoftwareOption: 'software_net/updateOption'
    }),
    updateForm () {
      if (!this.isEdit) return

      this.form.name = this.options.form.name
      this.form.slug = this.options.form.slug
    },
    async save () {
      if (this.isEdit) {
        await this.updateSoftwareOption({ id: this.options.id, form: this.form })
          .then(res => this.close())
          .catch(error => console.log(error))
      } else {
        await this.createSoftwareOption(this.form)
          .then(() => {
            for (const key in this.form) {
              if (Object.hasOwnProperty.call(this.form, key)) {
                this.form[key] = null
              }
            }
            this.close()
          })
          .catch(error => console.log(error))
      }
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 100%;
    max-height: 80vh;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    overflow-y: auto;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__desc {
        display: block;

        .label {
          padding-bottom: .25rem;
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}
</style>
