<template>
  <div @click="openChat" class="msg-wrapper">
    <div class="btn btn-msg">
      <div class="btn btn-notif btn_mark">
        <div v-if="messagesCount && messagesCount !== 0" class="puls-btn number">{{ messagesCount }}</div>
        <div>
          <div class="chat-img">
            <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.9138 0C14.4923 0 15.0709 0 15.6437 0C16.1412 0.0578561 16.633 0.104141 17.1306 0.173568C20.3242 0.624846 23.1765 1.83404 25.5833 4.00943C27.4694 5.7104 28.7539 7.78165 29.3035 10.2752C29.4192 10.7902 29.4771 11.3167 29.5581 11.8374C29.5581 12.3581 29.5581 12.8788 29.5581 13.3937C29.5407 13.4747 29.5176 13.5557 29.5002 13.6425C29.3729 14.3194 29.2919 15.0137 29.101 15.6732C28.3315 18.3346 26.7347 20.4232 24.5246 22.0432C21.1168 24.5426 17.2636 25.4914 13.0864 25.0748C12.0508 24.9707 11.102 25.0575 10.1242 25.3468C8.267 25.9022 6.39246 26.3997 4.52371 26.932C4.23443 27.013 3.95672 27.0419 3.6848 26.9031C3.11202 26.6138 2.95003 25.8964 3.3608 25.3873C4.07822 24.5078 4.81299 23.64 5.53619 22.7664C5.59405 22.697 5.64612 22.6217 5.68083 22.5755C5.07913 22.0895 4.47743 21.6555 3.93936 21.1522C3.39552 20.6431 2.86903 20.0934 2.41775 19.5033C0.572139 17.0965 -0.266775 14.3657 0.0745765 11.3282C0.352286 8.86934 1.3937 6.74024 3.05995 4.92934C5.57669 2.18696 8.75299 0.71163 12.3979 0.173568C12.9013 0.0983554 13.4104 0.0578561 13.9138 0ZM6.92474 24.2012C7.09252 24.1607 7.19666 24.1434 7.29502 24.1144C8.60835 23.7441 9.92169 23.3739 11.235 23.0152C11.4664 22.9515 11.7326 22.9226 11.9698 22.9631C14.2493 23.3565 16.5115 23.3044 18.7447 22.6796C21.626 21.8754 24.0906 20.4116 25.8379 17.9296C27.6257 15.3955 28.0885 12.6126 27.076 9.65619C26.1619 6.9948 24.3105 5.09712 21.8921 3.778C18.2645 1.80511 14.4229 1.46376 10.4713 2.60931C7.72315 3.40194 5.43205 4.90041 3.73108 7.2378C1.45734 10.362 1.3937 14.5392 3.57487 17.7271C4.62207 19.2603 5.9759 20.4406 7.57273 21.3605C8.3075 21.7828 8.40586 22.4019 7.86779 23.0614C7.56694 23.4317 7.26609 23.7904 6.92474 24.2012Z"
                fill="white" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: 'notifications',
  components: {
    CircleLoad
  },
  computed: {
    ...mapGetters({
      newNotificationsCount: 'notifications/unreadCount'
    }),
    ...mapState({
      messagesCount: state => state.messagesCount,
    }),
  },
  data() {
    return {
      menu: false,
      isLoaded: false,
      viewList: [],
      viewedList: [],
      viewTimeout: null,
      loading: false,
      notifications: [],
      dropdown: false
    }
  },
  methods: {
    gotoAllNotifications() {
      this.$store.dispatch('notifications/gotoAllNotifications')
    },
    getUnreadNotifications() {
      if (this.loading) {
        return false
      }
      this.loading = true
      return axios.get('notifications-unread', { params: { limit: 10 } })
        .then(response => {
          this.$store.dispatch('notifications/clearNotifications')
          this.notifications = response.data
        })
        .catch(error => {
          console.error('error get unread notifications', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openChat() {
      if (this.$route.name != 'Chat') {
        this.$router.push({ name: "Chat" })
      } else {
        this.$router.go()
      }
    },
    dropdownClose() {
      this.dropdown = false
    },
    textNotification(notification) {
      if (notification.notification.data.type === 'new-individual-offer') {
        return 'You have received new individual offer'
      }

      if (notification.notification.data.type === 'update-profile') {
        const updatedFields = notification.notification.data.updated_field_labels.join(', ')
        return 'Your profile was updated: ' + updatedFields
      }

      if (notification.notification.data.type === 'update-business-information') {
        const updatedFields = notification.notification.data.updated_field_labels.join(', ')
        return 'Your business information was updated: ' + updatedFields
      }

      if (notification.notification.data.type === 'update-request') {
        return 'You request was updated'
      }

      return ''
    },
    viewIndividualOffer(notification) {
      this.$store.dispatch('notifications/newIndividualOffer', notification)
    },
    viewUpdateProfile(notification) {
      this.$store.dispatch('notifications/updateProfile', notification)
    },
    viewUpdateRequest(notification) {
      this.$store.dispatch('notifications/updateRequest', notification)
    }
  },
  watch: {
    dropdown(isOpenedMenu) {
      if (isOpenedMenu === true) {
        this.getUnreadNotifications()
      } else {
        this.notifications = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn,
a.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


  .img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.number {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  width: 1rem;
  height: 1rem;
  background-color: #0071e3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.puls-btn {
  animation: btnPulse-2 2.5s infinite alternate ease-in-out;
}

@keyframes btnPulse-2 {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.05);
    background: #21b184;
  }

  50% {
    transform: scale(1);
    background: #8ad4bd;
  }
}

.chat-img {
  display: flex;
  justify-content: center;
}

.msg-wrapper {
  cursor: pointer;
  margin: 0 15px;
  height: 60px;
  width: 60px;
  border-radius: 15px;
  // background-color: #121212;
  border: 1px solid rgba(255, 255, 255, .2);
  background: radial-gradient(98.96% 98.96% at 32.03% 9.37%, rgba(0, 0, 0, 0) 44.47%, #0071e3 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
linear-gradient(234.94deg, #121212 1.74%, rgba(252, 252, 252, 0) 105.87%);

}

.msg-text {
  text-align: center;
}

.chat-img img {
  width: 30px;
  height: auto;
}

.btn-msg {
  width: 100%;
  height: 100%;
}</style>
