<template>
	<overlay-scrollbars class="page page-offer shapes-bg" ref="os">
		<div class="section-title-wrap">
			<h2 class="page-details__title section-title">
				<span class="bc-first">Notifications</span>
			</h2>
		</div>

		<div class="search-input-wrapper">
			<Search class="icon" />
			<input v-model="nameOrEmail" type="text" placeholder="Enter name or email">
		</div>

		<div class="notifications">
			<div class="notifications-bg">
				<NotificationsBgBell class="notifications-bg__img" />
			</div>
			<div class="notifications-wrapper">
				<div class="notification" v-for="notification in notifications.data">
					<div class="left">
						<div class="notification__avatar" v-if="notification.notification.author">
							<img v-if="notification.notification.author.logo" :src="notification.notification.author.logo">
							<User v-else />
						</div>
						<div class="notification__user-name" v-if="notification.notification.author">
							{{ notification.notification.author.name }}</div>
					</div>
					<div class="right">
						<div class="notification__message">
							{{ notification.text }}
						</div>
					</div>
				</div>
			</div>


			<pagination class="pagination" v-if="notifications.pagination" :data="notifications.pagination"
				@pagination-change-page="getNotifications">
				<span slot="prev-nav">&lt;</span>
				<span slot="next-nav">&gt;</span>
			</pagination>
		</div>

	</overlay-scrollbars>
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationText from '@/mixins/notifications_text.js'
import Search from '@/components/img/Search.vue'
import NotificationsBgBell from '@/components/img/NotificationsBgBell.vue'
import User from '@/components/img/User.vue'

export default {
	mixins: [
		NotificationText
	],
	components: {
		Search,
		NotificationsBgBell,
		User
	},
	created() {
		this.getNotifications()
	},
	data: () => ({
		tableKey: 0,
		nameOrEmail: '',
		filteredUsers: [],
		notifications: {
			data: [],
			pagination: null,
		},
		searchDelayTimer: null
	}),
	mounted() {
		// this.$store.dispatch('notifications/clearGotoAllNotifications')
	},
	computed: {
		...mapGetters({
			gotoAllNotifications: 'notifications/gotoAllNotifications'
		})
	},
	watch: {
		gotoAllNotifications(val) {
			if (val) {
				this.getNotifications()
			}
		},
		nameOrEmail(nameOrEmail) {
			clearTimeout(this.searchDelayTimer);
			const that = this
			this.searchDelayTimer = setTimeout(function () {
				that.notifications.data = []
				that.getNotifications(1)
			}, 300);
		}
	},
	methods: {
		getNotifications(page = 1) {
			let url = 'admin/notifications?page=' + page
			if (this.nameOrEmail) {
				url = url + '&user=' + this.nameOrEmail
			}

			axios.get(url)
				.then(response => {
					this.notifications.pagination = response.data
					this.notifications.data = []

					response.data.data.forEach(el => {
						const text = this.textNotification(el)

						if (text && el.author) {
							this.notifications.data.push({ type: 'notification', text: text, notificationType: el.notification.data.type, notification: el })
						}
					})
				})
				.catch(errors => {
					console.dir(errors)
				})

			this.$store.dispatch('notifications/clearGotoAllNotifications')
		},
	}

}
</script>

<style lang="scss" scoped>
.search-input-wrapper {
	background-color: #121212;
	border-radius: 46px;
	padding: 12px 20px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	input {
		width: 100%;
		border: none;
		background: transparent;
		color: white;
	}

	.icon {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
}

.notifications {
	width: 100%;
	min-height: 819px;
	position: relative;
	background: #121212;
	padding: 15px 30px;

	&-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	.notification {
		position: relative;
		background-color: #fff;
		border-radius: 30px;
		padding: 16px 30px;
		margin-bottom: 15px;
		color: var(--c_black);
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left {
			display: flex;
			align-items: center;
		}

		&__avatar {
			background-color: #121212;
			border-radius: 50%;
			width: 48px;
			height: 48px;
			margin-right: 15px;
			overflow: hidden;
		}

		&__user-name {
			font-weight: bold;
			font-size: 20px;
		}

		&__message {
			background: linear-gradient(267deg,#0071e3 0%,#03b5aa 100%);
			border-radius: 50px;
			padding: 15px 30px;
			color: #fff;
			font-weight: bold;
		}
	}
}

</style>