<template>
  <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Buy & </span> Sell NET License
      </h2>
    </div>

    <!-- <div class="box box__feedback">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Feedback</div>
          </div>
          <Table
            :options="getTableData"
            @more-modal="openMoreModal"
            @change-page="getFeedback"
            @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
    <div class="box box__feedback">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Applied Busines Offers</div>
          </div>
          <Table
            :options="appliedBosTableData"
            @show-user-bos-offers="showUserBusinessOffers"
          />
        </div>
      </div>
    </div> -->

    <div class="box box_bg-currencies box_feedback">
      <div class="box-header header-blur">
        <div class="box-title">Feedback</div>
      </div>
      <div class="box-body">
        <div class="transparent-table">
          <div class="transparent-table__headers">
            <div class="transparent-table__header">ID</div>
            <div class="transparent-table__header">Email</div>
            <div class="transparent-table__header">Messengers</div>
            <div class="transparent-table__header">Created At</div>
            <div class="transparent-table__header">More</div>
          </div>
          <DataTable :value="this.feedbackTableData.body">
            <Column field="id" header="ID"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="messengers" header="Messengers"></Column>
            <Column field="created_at" header="Created At"></Column>
            <Column field="" header="More"></Column>
          </DataTable>
        </div>
      </div>
    </div>
    <div class="box box_bg-currencies box_feedback">
      <div class="box-header header-blur">
        <div class="box-title">Applied Business Offers</div>
      </div>
      <div class="box-body">
        <div class="transparent-table">
          <div class="transparent-table__headers">
            <div class="transparent-table__header">#</div>
            <div class="transparent-table__header">User</div>
            <div class="transparent-table__header">Type of Business</div>
            <div class="transparent-table__header">Type of License</div>
          </div>
          <DataTable :value="this.appliedBosTableData.body">
            <Column field="id" header="ID"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="messengers" header="Messengers"></Column>
            <Column field="created_at" header="Created At"></Column>
            <Column field="" header="More"></Column>
          </DataTable>
        </div>
      </div>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Offers</div>
        <div class="box-btns">
          <div class="btn-gradient" @click="openCreateOfferModal">Create</div>
          <div class="btn-provider" @click="showProvidersOffers = !showProvidersOffers">
            <span class="btn-provider__arrow">◀</span>
            <span v-if="showProvidersOffers">Provider</span>
            <span v-else>User</span>
            <span class="btn-provider__arrow">▶</span>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="offersTableData.body">
            <Column field="id" header="#"></Column>
            <Column field="business_type" header="Business Type"></Column>
            <Column field="license_type" header="License Type"></Column>
            <Column field="country" header="Country"></Column>
            <Column field="description" header="Description"></Column>
            <Column field="price" header="Price"></Column>
            <Column field="from" header="From"></Column>
            <Column field="created_at" header="Created At"></Column>
            <Column v-if="showProvidersOffers" field="provider" header="Provider">
              <!-- TODO: show provider info in table -->
              <template #body="{ data, field }">
                <div>
                  {{ data[field] }}
                </div>
              </template>
            </Column>
            <Column v-else field="" header=""></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editOffers(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteOffers(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="offersTableData.paginationData" :data="offersTableData.paginationData"
            @pagination-change-page="getOffers">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
  name: 'BuyOrSellBusiness',
  components: {
    EditIcon,
    TableRemoveItemIcon
  },
  data: () => ({
    feedbackTableData: {
      body: [],
      paginationData: null,
      businesOffersKey: 1,
      businesOffers: {
        colsWidth: ['4%', '17%', '17%', '16%', '34%', '12%'],
        header: [
          { ttl: '#' },
          { ttl: 'Type of Business' },
          { ttl: 'Type of License' },
          { ttl: 'Country' },
          { ttl: 'Description' },
          { ttl: 'Request' }
        ],
      },
    },
    showProvidersOffers: false
  }),
  computed: {
    ...mapState({
      offersTableData: state => state.bos_business.offersTableData,
      appliedBosTableData: state => state.bos_business.appliedBosTableData,
    }),
    getTableData() {
      let data = {
        colsWidth: ['6%', '37%', '37%', '15%', '5%'],
        header: [
          { ttl: 'ID' },
          { ttl: 'Email' },
          { ttl: 'Messengers' },
          { ttl: 'Created At' },
          { ttl: 'More' }
        ],
        body: [],
        paginationData: this.feedbackTableData.paginationData
      }

      if (this.feedbackTableData.body.length > 0) {
        let bodyArr = []
        this.feedbackTableData.body.forEach(el => {
          bodyArr.push([
            el[0],
            el[1],
            el[3],
            el[6],
            { type: 'more' }
          ])
        })
        data.body = bodyArr
      }
      return data
    }
  },
  created() {
    this.setProvidersOffers(false)
    this.getFeedback()
    this.getOffers()
    this.getAppliedBosData()
  },
  methods: {
    ...mapActions({
      getOffers: 'bos_business/getOffers',
      getAppliedBosData: 'bos_business/getAppliedBosData'
    }),
    ...mapMutations({
      setProvidersOffers: 'bos_business/setProvidersOffers',
      setOffersTableHeader: 'bos_business/setOffersTableHeader',
      setOffersTableColWidth: 'bos_business/setOffersTableColWidth',
    }),
    async getFeedback(page = 1) {
      await axios.get('admin/business-feedback?page=' + page)
        .then(({ data }) => {
          this.feedbackTableData.paginationData = data
          this.feedbackTableData.body = data.data
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    openMoreModal(id) {
      let data = this.feedbackTableData.body.find(el => +el[0] === id).slice()

      this.$store.commit('setModal', {
        template: 'neo-bank-more',
        options: {
          names: ['ID', 'Email', 'Person', 'Messengers', 'Social', 'Price', 'Created At'],
          values: data
        }
      })
    },
    openCreateOfferModal() {
      this.$store.commit('setModal', {
        template: 'create-business-offer'
      })
    },
    editOffers(obj) {
      this.$store.commit('setModal', {
        template: 'create-business-offer',
        options: {
          id: obj.id,
          form: {
            business_type: obj.business_type,
            license_type: obj.license_type,
            country_name: obj.country,
            description: obj.description,
            price: obj.price
          }
        }
      })
    },
    deleteOffers(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'business-offers',
          type: 'business offer',
          url: 'admin/business-offers/' + obj.id,
          successfullMessage: 'You successfully deleted business offer',
        }
      })
    },
    toElTop(el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    showUserBusinessOffers(payload) {
      this.$router.push({ name: "CustomerDetail", params: { id: payload.id, viewAppliedOffers: true, 'scroll': 'bos' } })
    },
    sortBy(data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort

      switch (data.obj) {
        case 'offersTableData':
          this.getOffers()
          break;
      }
    },
  },
  watch: {
    showProvidersOffers: function (showProvidersOffers) {
      /*this.setOffersTableHeader(h)
      if (showProvidersOffers) {
        const colsWidth = ['4%', '14%', '14%', '14%', '24%', '11%', '10%', '10%']
        this.setOffersTableColWidth(colsWidth)
        const header = this.offersTableData.header
        header.splice(7, 0, { ttl: 'Provider Id', info: null, provider: true})
        header.splice(8, 0, { ttl: 'Provider', info: null, provider: true})
        this.setOffersTableHeader(header)
      } else {
        const colsWidth = ['4%', '14%', '14%', '14%', '24%', '11%', '10%', '10%']
        this.setOffersTableColWidth(colsWidth)
      }*/
      this.setProvidersOffers(showProvidersOffers)
      this.getOffers()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-provider {
  margin-left: 20px;
}

.transparent-table__headers {
	.transparent-table__header {
		width: 20%;
	}
}

.box_feedback{
  .box-body{
    padding: 25px; 
  }
}
</style>
