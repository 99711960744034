<template>
    <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
        <div class="section-title-wrap">
            <h2 class="page-details__title section-title">
                <span class="bc-first">Paid Requests</span>
            </h2>
        </div>

        <div class="box">
            <div class="box-body">
                <div class="main-table offers-table">
                    <DataTable :value="paidRequestsTableData.body">
                        <Column field="package_type" header="Package Type" sortable></Column>
                        <Column field="name" header="Package Name" sortable></Column>
                        <Column field="user.full_name" header="User" sortable></Column>
                        <Column field="wallet" header="Wallet" sortable></Column>
                        <Column>
                            <template #body="{ data }">
                                <div class="btns" v-if="!data.status">
                                    <div class="btn-gradient btn-confirm" @click="confirm(data)">
                                        Confirm
                                    </div>
                                    <div class="btn-decline" @click="deleteItem(data)">
                                        Decline
                                    </div>
                                </div>
                                <div v-else>
                                    Confirmed
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                    <pagination v-if="paidRequestsTableData.paginationData" :data="paidRequestsTableData.paginationData"
                        @pagination-change-page="getPaidRequests">
                        <span slot="prev-nav">&lt;</span>
                        <span slot="next-nav">&gt;</span>
                    </pagination>
                </div>
            </div>
        </div>
    </overlay-scrollbars>
</template>
<script>

export default {
    components: {
    },
    data() {
        return {
            paidRequestsTableData: {
                header: [
                    { ttl: 'Package Type', info: null },
                    { ttl: 'Package Name', info: null },
                    { ttl: 'User Name', info: null },
                    { ttl: 'Wallet', info: null },
                ],
                body: [],
                paginationData: null,
            },
        }
    },
    created() {
        this.getPaidRequests();
    },
    methods: {
        getPaidRequests(page = 1) {
            axios.get('admin/paid-request?page=' + page)
                .then(res => {
                    this.paidRequestsTableData.body = [];
                    this.paidRequestsTableData.paginationData = res.data;

                    res.data.data.forEach(item => {
                        const bodyItem = {
                            id: item.id,
                            package_type: item.package.type.name,
                            name: item.package.name,
                            user: { type: 'user-details', user_id: item.user.id, full_name: item.user.full_name },
                            wallet: item.wallet.name,
                            status: item.status
                        };

                        this.paidRequestsTableData.body.push(bodyItem);
                    })
                })
                .catch(err => {
                    console.dir(err)
                });
        },
        setStatus(id, status) {
            axios.post('admin/paid-request/' + id + '/set-status', { status: status })
                .then(({ data }) => {
                    if (data.error) {
                        alert(data.error);
                        return false;
                    }

                    if (data.success) {
                        this.getPaidRequests();
                    }
                })
                .catch(err => console.dir(err))
        },
        confirm(obj) {
            this.setStatus(obj.id, 1);
        },
        // decline(obj) {
        //     this.setStatus(obj.id, 2);
        // },
        deleteItem(obj) {
            this.$store.commit('setModal', {
                template: 'delete',
                options: {
                    id: obj.id,
                    actionType: 'delete',
                    table: 'paid-requests',
                    type: 'Paid request',
                    url: 'admin/paid-request/' + obj.id,
                    successfullMessage: 'You successfully deleted paid request',
                }
            });
        }
    },
    watch: {
        '$store.state.deleteItemFromTableData': function (data) {
            console.log(data)
            this.getPaidRequests();
        }
    }
}
</script>
<style lang="scss" scoped>
.btns {
    display: flex;
}

.btn-decline {
    margin-left: 20px;
}

.btn-confirm, .btn-decline{
    padding: 15px;
}
</style>