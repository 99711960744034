<template>
	 <div class="form-group">
        <label v-if="showLabel">Provider</label>
	    <div class="inpt-block">
			<input type="text" class="form-control" placeholder="Enter provider email" v-model="email">
			<select @change="selectProvider($event)" class="form-control" style="width: 100%;" :disabled="providers.length == 0" v-model="providerId">
	          <option :value="null">Select provider</option>
	          <option v-for="provider in providers" :key="provider.id" :value="provider.id">{{ provider.email }}</option>
	        </select>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		providerId: {
			default: null
		},
		showLabel: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		email: '',
		providers: [],
		selectedProvider: null,
	}),
	methods: {
		async getProviders () {
			this.selectedProvider = null
			const that = this
			await axios.get('admin/legal-provider/providers-by-email/' + this.email)
		        .then(function ({ data }) {
		          that.providers = data.providers
		          if (that.providers[0]) {
		          	that.selectedProvider = that.providers[0]
		          	that.$emit('selectProvider', {
				        id: that.providers[0].id,
				    })
		          }
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
		},
		selectProvider (event) {
			this.$emit('selectProvider', {
		        id: event.target.value,
		    })
		},
		async getProvider () {
			const that = this
			await axios.get('admin/legal-provider/show-provider-for-select/' + this.providerId)
		        .then(function ({ data }) {
		          that.providers = []
		          if (data.provider) {
		          	that.selectedProvider = data.provider
		          	that.providers.push(data.provider)
		          	that.$emit('selectProvider', {
				        id: data.provider.id,
				    })
				    that.providerId = data.provider.id
		          }
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
		}
	},
	watch: {
		email: function (email) {
			if (email.length > 1) {
				this.getProviders()
			}
		},
		providerId: function (providerId) {
			if (providerId) {
				this.getProvider()
			}
		}
	}
}
</script>

<style scoped>
.modal__body .form-group .inpt-block {
    flex-direction: column;
}
</style>