<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        {{ isEdit ? 'Edit' : 'Create' }} Industry
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group">
          <label>Name</label>
          <input v-model="name" type="name" class="form-control">
          <span v-show="formErrors.name" class="invalid-feedback">{{ formErrors.name }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      name: '',
      formErrors: {
          name: '',
      },
    };
  },
  created () {
    if (this.options.actionType == 'edit' ) {
      this.getIndustry(this.options.id)
    }
    this.isEdit = this.options && this.options.actionType !== 'create'
  },
  methods: {
    getIndustry () {
      const that = this
      axios.get('admin/industries/' + this.options.id).then(function (response) { 
        that.name = response.data.data.name
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    isEdit () {
      return this.options && this.options.actionType !== 'create'
    },
    clearErrors() {
      this.formErrors.name = ''
    },
    async submit() {
      const that = this
      this.clearErrors()
      let axiosObj = null
      if (this.options.actionType == 'edit' ) {
        axiosObj = axios.put(this.options.url, {
          name: this.name,
        })
      }  else {
        axiosObj = axios.post(this.options.url, {
          name: this.name,
        })
      }
      
      if (axiosObj) {
        axiosObj.then(function (response) { 
          if (response.data.success) {
            that.close()
            that.$noty.success(that.options.successfullMessage)
            that.$store.dispatch('editItemFromTable', {
              id: that.options.id,
              table: that.options.table,
              actionType: that.options.actionType
            })
            that.name = ''
          }
          that.clearErrors()
          that.close()  
        })
        .catch(err => { 
            if (err.response.status == 422) {
              const errors = err.response.data.errors
              if (errors.name) {
                this.formErrors.name = errors.name[0]
              }
            } else {
              console.log(err)
            }
          });
      }
      
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  input {
    color: #333333;
  }

  .form {
    width: 30rem;
  }
}
</style>
