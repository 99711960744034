<template>
  <div class="tmblr" :class="[{ 'tmblr-active': active }, customClass]">
    <div
      class="btn btn-tmblr"
      :class="active ? 'btn-tmblr-r' : 'btn-tmblr-l'"
      @click="$emit('changeState')"
    >
      <Close v-show="!active" class="img img_close" />
      <Check v-show="active" class="img img_check" />
    </div>
    <div class="txt">{{ text }}</div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import Check from './../img/Check.vue'

export default {
  components: {
    Close,
    Check,
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    customClass: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
.tmblr {
  display: flex;
  align-items: center;

  .txt {
    color: rgba(255, 255, 255, 0.5);
  }

  .btn {
    &-tmblr {
      position: relative;
      width: 2rem;
      height: .5rem;
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 2rem;
      margin: auto .75rem auto .25rem;

      &-l::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-25%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }

      &-r::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(25%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }

      ::v-deep svg.img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;

        &_close {
          left: 0;
          width: 8px;
          height: 8px;

          path {
            fill:#a1a1a1;
          }
        }

        &_check {
          right: -1px;

          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  &-active {
    .txt {
      color: #0071e3;
    }

    .btn-tmblr-r::before {
      background-color:  #0071e3;
    }
  }
}
.tmblr.dark{
  .txt {
    color: #000;
  }
  .btn {
    &-tmblr {
      background-color: #000;
  
      &-l::before {
        background-color: #000;
      }
  
      &-r::before {
        background-color: #000;
      }
  
      ::v-deep svg.img {
        &_close {
          path {
            fill:#fff;
          }
        }
  
        &_check {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  &.tmblr-active {
    .txt {
      color: #0071e3;
    }
  
    .btn-tmblr-r::before {
      background-color:  #0071e3;
    }
  }
}
</style>