<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        <span v-if="options.actionType == 'create'">Add Payment Atlas</span>
        <span v-else>Edit Payment Atlas</span>
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group">
          <label>Name of Bank</label>
          <input v-model="form.bank_name" type="text" class="form-control">
          <span v-show="formErrors.bank_name" class="invalid-feedback">{{ formErrors.bank_name }}</span>
        </div>
        <div class="form-group">
          <label>
            <input type="checkbox" v-model="form.is_legal_company">
            Provided by Legal company
          </label>
        </div>
        <div class="form-group">
          <label>Country of Bank</label>
          <div class="inpt-block">
            <select class="form-control" style="width: 100%;" v-model="form.country_id">
              <option value="null">Select country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
          </div>
          <span v-show="formErrors.country_id" class="invalid-feedback">{{ formErrors.country_id }}</span>
        </div>
        <div class="form-group">
          <label>Type of Account</label>
          <input v-model="form.account_type" type="text" class="form-control">
          <span v-show="formErrors.account_type" class="invalid-feedback">{{ formErrors.account_type }}</span>
        </div>
        <div class="form-group">
          <label>Transaction History</label>
          <div class="inpt-block">
            <input v-model="form.transaction_history" type="text" class="form-control">
          </div>
          <span v-show="formErrors.transaction_history" class="invalid-feedback">{{ formErrors.transaction_history }}</span>
        </div>
        <div class="form-group half">
          <label>Balance</label>
          <div class="inpt-block">
            <input v-model="form.balance" type="text" class="form-control">
            <!-- <div class="currency-symbol">€</div> -->
          </div>
          <span v-show="formErrors.balance" class="invalid-feedback">{{ formErrors.balance }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  created () {
    this.getCountries()
    if (this.options.actionType == 'edit' ) {
      this.getPaymentAtlas(this.options.id)
    }
  },
  data() {
    return {
      message: '',
      error: '',
      countries: [],
      form: {
        bank_name: '',
        is_legal_company: false,
        country_id: '',
        account_type: '',
        transaction_history: '',
        balance: '',
      },
      formErrors: {
        bank_name: '',
        country_id: '',
        account_type: '',
        transaction_history: '',
        balance: '',
      }
    };
  },
  methods: {
    ...mapActions({
      updatePaymentAtlasesTable: 'payment_atlas/getPaymentAtlases'
    }),
    getCountries() {
      axios.get('admin/data/countries')
        .then(({ data }) => {
          this.countries = data
        })
        .catch((error) => console.log(error))
    },
    clearErrors() {
      for (let key in this.form) {
        this.formErrors[key] = ''
      }
    },
    getPaymentAtlas () {
      axios.get('admin/payment-atlas/' + this.options.id)
        .then(({ data }) => {
            this.form = data
        })
        .catch((error) => console.log(error))
    },
    async submit() {
      this.clearErrors()
      axios.post(this.options.url, this.form)
        .then(({ data }) => {
          if (data.errors) {
            const errors = data.errors
            for (let key in errors) {
                this.formErrors[key] = errors[key][0]
            }
          }
          if (data.success) {
            this.updatePaymentAtlasesTable(1)
            this.close()
            this.$noty.success(this.options.successfullMessage)
          }
        })
        .catch((error) => console.log(error))
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
}
</style>
