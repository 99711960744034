export default ({
  namespaced: true,
  state: () => ({
    administratorsTableData: {
      colsWidth: ['9%', '9%', '9%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%'],
      header: [
        { ttl: 'ID', info: null },
        { ttl: 'Date Join', info: null },
        { ttl: 'Administrator Name', info: null },
        { ttl: 'Requests', info: null },
        { ttl: 'Rating', info: null },
        { ttl: 'KYB Rate', info: null },
        { ttl: 'Type cust.', info: null },
        { ttl: 'Location', info: null },
        { ttl: 'Last Request', info: null },
        { ttl: 'Balance', info: null },
        { ttl: 'Discount code', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
    },
  }),
  actions: {
    async getAdministrators ({ commit }, payload) {
      let pageStr = payload && payload.page ? payload.page : (typeof payload == 'number') ? payload : 1;  
      let name = payload && payload.name ? payload.name : '' 
      await axios.get('admin/administrators?page=' + pageStr + '&name=' + name)
        .then(({ data }) => {
          commit('setAdministrators', { data: data });
        })
    },
  },
  mutations: {
    setAdministrators (state, payload) {
      state.administratorsTableData.paginationData = payload.data
      state.administratorsTableData.body = []
      payload.data.data.forEach(el => {
        const isNewHtml = (el.payment_settings && el.payment_settings.payment_request_settings_changed) ? '<span class="badge  new-request-badge">New</span>' : ''
        const requestHtml = (el.request_changed_date) ? el.request_changed_date + isNewHtml : ''
        let balanceItem = (el.balance.trim().length !== 0 && el.balance) ? that.number_format(el.balance, 2, '.', ' ') : ''
        if (el.balance_currency) {
          balanceItem = balanceItem + ' ' + el.balance_currency.code;
        }
        let name = el.full_name
        const kybRate = (parseInt(el.is_kyb_rate_manual) == 1) ? el.kyb_rate_manual : el.kyb_rate 
        if (!name && typeof el.company_full_name !== 'undefined') {
          name = el.company_full_name
        }
        state.administratorsTableData.body.push([
          el.serial_number,
          el.created_at_text,
          name,
          { type: 'chat-request', count: el.request_count, userId: el.id },
          { type: 'rating', rating: el.rating, rating_color: el.rating_color },
          kybRate,
          el.customer_type_name,
          el.location,
          { type: 'request-change', html: requestHtml },
          balanceItem,
          el.discount_code,
          { type: 'edit-delete-actions', id: el.id }
        ])
      })
    },
  }
})
