<template>
	<overlay-scrollbars class="page page-offer shapes-bg" ref="os">
		<div class="section-title-wrap">
			<h2 class="page-details__title section-title">
				<span class="bc-first">My Settings</span>
			</h2>
		</div>

		<div class="box-white">
			<div class="form__group input-group">
				<label for="login">Login</label>
				<InputText id="login" type="text" v-model="login" />
				<div class="invalid-feedback">{{ loginError }}</div>
				<div @click.prevent="submit" class="btn-gradient">Save</div>
			</div>
		</div>
		<div class="section-header">
			Business Information
		</div>
		<div class="box-white">
			<div class="form__group input-group">
				<label for="">Password</label>
				<Password v-model="password" toggleMask />
				<span v-show="passwordError" class="invalid-feedback">{{ passwordError }}</span>

				<label for="">Repeat Password</label>
				<Password :feedback="false" v-model="newPassword" toggleMask />
				<span v-show="newPasswordError" class="invalid-feedback">{{ newPasswordError }}</span>

				<div @click.prevent="changePassword" class="btn-gradient">Change password</div>
			</div>
		</div>

		<div class="box box_cards box-bg-shapes">
			<div class="box-body">
				<div class="box-card" ref="member_login">
					<div class="box-card__img-wrapper">
						<Message class="box-card__img" />
					</div>
					<router-link :to="{ name: 'AllChats' }" class="box-card__btn btn-gradient">Show all chats</router-link>
				</div>
				<div class="box-card" ref="member_login">
					<div class="box-card__img-wrapper">
						<Edit class="box-card__img" />
					</div>
					<router-link :to="{ name: 'Meta' }" class="box-card__btn btn-gradient">Edit meta</router-link>
				</div>
				<div class="box-card" ref="member_login" v-if="$store.state.user.is_super_admin">
					<div class="box-card__img-wrapper">
						<Edit class="box-card__img" />
					</div>
					<router-link :to="{ name: 'Industries' }" class="box-card__btn btn-gradient">Edit
						industries</router-link>
				</div>
			</div>
		</div>

		<div class="box-white__wrapper">
			<div class="box-white">
				<div class="toggle-wrapper">
					<Toggle :modelValue="customer2Fa" @update:modelValue="toggleCustomer2Fa" />
					<span>Enable customer two factor on login</span>
				</div>
				<div class="sep"></div>
				<div class="toggle-wrapper">
					<Toggle :modelValue="provider2Fa" @update:modelValue="toggleProvider2Fa" />
					<span>Enable provider two factor on login</span>
				</div>
			</div>
			<div class="box-white">
				<div class="form__group input-group">
					<label for="login">User personal manager</label>
					<model-select class="p-inputtext model-select" :options="userEmails" v-model="personalManagerEmail"
										placeholder="Enter personal manager email">
					</model-select>
					<div v-if="personalManagerEmailError" class="invalid-feedback">{{
						personalManagerEmailError }}</div>
					<div @click.prevent="submit" class="btn-gradient">Save</div>
				</div>
			</div>
		</div>

		<!-- <div class="box-container">
			<div class="transparent-box box_b-info">
				<div class="content">
					<div class="box">
						<div class="header"></div>
						<div class="content">
							<div class="hdr">
								<div class="ttl">User personal manager</div>
								<div class="pm-input">
									<model-select class="form-control" :options="userEmails" v-model="personalManagerEmail"
										placeholder="Enter personal manager email">
									</model-select>
									<div v-if="personalManagerEmailError" class="invalid-feedback pm-invalid-feedback">{{
										personalManagerEmailError }}</div>
								</div>
								<div @click="saveDefaultPersonalManager" class="pm-input btn personal-managers-sbmt-btn">
									Save</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</overlay-scrollbars>
</template>

<script type="text/javascript">
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import Message from '@/components/img/Message.vue'
import Edit from '@/components/img/Edit.vue'

export default {
	mounted() {
		this.login = this.$store.state?.user?.member_login
	},
	components: {
		ModelSelect,
		Message,
		Edit
	},
	data: () => ({
		login: '',
		loginError: '',
		personalManagerEmail: {
			value: '',
			text: ''
		},
		personalManagerEmailError: '',
		userEmails: [],
		watchPersonalManagerEmails: false,
		customer2Fa: false,
		provider2Fa: false,
		password: '',
		passwordError: '',
		newPasswordError: '',
		newPassword: '',
	}),
	created() {
		this.getUsers()
		this.getSettings()
		this.getDefaultManager()
	},
	methods: {
		getDefaultManager() {
			axios.get('chat/settings-default-manager')
				.then(response => {
					if (response.data) {
						this.personalManagerEmail.value = response.data.id
						this.personalManagerEmail.text = response.data.email
					}
				})
				.catch(function (error) {
					console.log(error);
				})
		},
		getUsers() {
			axios.get('admin/all-users-emails')
				.then(response => {
					this.userEmails = response.data
				})
				.catch(function (error) {
					console.log(error);
				})
		},
		getSettings() {
			axios.get('admin/settings')
				.then(({ data }) => {
					data.forEach(setting => {
						if (setting.code == 'customer_two_factor_on_login') {
							this.customer2Fa = +setting.value ? true : false
						} else if (setting.code == 'provider_two_factor_on_login') {
							this.provider2Fa = +setting.value ? true : false
						}
					})
				})
				.catch(function (error) {
					console.log(error);
				})
		},
		toggleCustomer2Fa() {
			this.customer2Fa = !this.customer2Fa
			axios.post('admin/settings/change-by-code', {
				code: 'customer_two_factor_on_login',
				value: this.customer2Fa
			})
				.then(function ({ data }) {
					console.log(data)
				})
				.catch(function (error) {
					console.log(error);
				})
		},
		toggleProvider2Fa() {
			this.provider2Fa = !this.provider2Fa
			axios.post('admin/settings/change-by-code', {
				code: 'provider_two_factor_on_login',
				value: this.provider2Fa
			})
				.then(function ({ data }) {
					console.log(data)
				})
				.catch(function (error) {
					console.log(error);
				})
		},
		resetErrors() {
			this.personalManagerEmailError = ''
		},
		validatePersonalManagerForm() {
			if (!this.personalManagerEmail.value) {
				this.personalManagerEmailError = 'Please, enter personal manager email'
				return
			}
			this.resetErrors()
		},
		saveDefaultPersonalManager() {
			this.watchPersonalManagerEmails = true
			this.validatePersonalManagerForm()
			if (this.personalManagerEmailError.length > 0) {
				return
			}
			this.sendPersonalManagerRequest()
		},
		sendPersonalManagerRequest() {
			const that = this
			axios.post('chat/default-personal-manager', {
				personal_manager_id: this.personalManagerEmail.value,
			})
				.then(function (response) {
					that.watchPersonalManagerEmails = false
					that.resetErrors()
					that.$noty.success('You have successfully saved personal manager')
				})
				.catch(function (error) {
					console.log(error);
				})
		},
		toElTop(el) {
			this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
		},
		submit() {
			axios.post('admin/admin/update-login', {
				login: this.login
			})
				.then(response => {
					if (response.data.error) {
						for (let key in response.data.errors) {
							if (key === 'login' && response.data.errors[key][0]) {
								this.loginError = response.data.errors[key][0]
							}
						}
						return
					}
					this.loginError = ''
					this.$store.dispatch('setUser')
					this.$noty.success('You have successfully changed login')
				})
				.catch(function (error) {
					console.log(error);
				})
		},
		validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		clearErrors() {
			this.passwordError = ''
			this.newPasswordError = ''
		},
		changePassword() {
			this.clearErrors();

			if (this.password.length === 0) {
				this.passwordError = 'Please enter password'
				return
			} else if (this.password.length < 6) {
				this.passwordError = 'Password must have at least 6 characters'
				return
			} else if (this.password !== this.newPassword) {
				this.newPasswordError = 'Please repeat password'
				return
			}

			axios.post('admin/admin/password', {
				password: this.password
			})
				.then(response => {
					this.clearErrors()
					if (response.data.success) {
						this.$noty.success('Password was successfully changed')
					} else {
						this.$noty.error('Password was not changed')
					}
				})
				.catch((errors) => {
					console.dir(errors)
				})
		}
	},
	watch: {
		'personalManagerEmail.value': function (userEmailValue) {
			if (this.watchPersonalManagerEmails) {
				this.validatePersonalManagerForm()
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.model-select{
	border-radius: 30px !important;
}
.box_cards {
	padding: 30px;

	.box-body {
		display: grid;
		gap: 25px;
		grid-template-columns: repeat(3, 1fr);

		.box-card {
			padding: 20px;

			&__btn {
				margin-top: 20px;
				width: 100%;
				text-decoration: none;
				text-align: center;
			}
		}
	}
}

.box-white__wrapper {
	margin-top: 40px;
	display: flex;
	gap: 20px;

	.box-white {
		width: 50%;

		.sep {
			width: 100%;
			height: 1px;
			background-color: #BEBEBE;
			margin: 23px 0;
		}

		.toggle-wrapper {
			display: flex;
			align-items: center;

			span {
				margin-left: 20px;
				font-weight: bold;
			}
		}
	}
}
</style>