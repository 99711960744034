<template>
  <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Compliance Service</span>
      </h2>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">
          Additional Services Requests
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="getServicesRequestsTableData.body">
            <Column field="id" header="#"></Column>
            <Column field="user_id" header="User Id" sortable></Column>
            <Column field="service_name" header="Service Name" sortable></Column>
            <Column field="price" header="Price" sortable></Column>
            <Column field="created_at" header="Created At" sortable></Column>
          </DataTable>
          <pagination v-if="getServicesRequestsTableData.paginationData"
            :data="getServicesRequestsTableData.paginationData" @pagination-change-page="getServicesRequests">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>


    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">
          Software Requests
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="getRequestsTableData.body">
            <Column field="id" header="#"></Column>
            <Column field="user_id" header="User Id" sortable></Column>
            <Column field="software_offer" header="Software Offer" sortable></Column>
            <Column field="options" header="Options" sortable></Column>
            <Column field="created_at" header="Created At" sortable></Column>
            <Column>
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="openRequestMoreModal(data)">
                    Show more
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="getRequestsTableData.paginationData" :data="getRequestsTableData.paginationData"
            @pagination-change-page="getRequests">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">
          Software Offers
        </div>
        <div class="btn-gradient" @click="openCreateOfferModal">Create</div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="offersTableData.body">
            <Column field="id" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column>
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editOffers(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteOffers(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="offersTableData.paginationData" :data="offersTableData.paginationData"
            @pagination-change-page="getOffers">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">
          Software Options
        </div>
        <div class="btn-gradient" @click="openCreateOptionModal">Create</div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="optionsTableData.body">
            <Column field="id" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column>
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editOptions(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteOptions(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">
          Additional Services
        </div>

        <div class="box-btns">
          <div class="btn-gradient" @click="openCreateAddServiceModal">Create</div>
          <div class="btn-provider" @click="toggleAddServices">
            <span class="btn-provider__arrow">◀</span>
            <span v-if="showProvidersAddServices">Provider</span>
            <span v-else>User</span>
            <span class="btn-provider__arrow">▶</span>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="addServicesTableData.body">
            <Column field="id" header="#"></Column>
            <Column field="name" header="Service name" sortable></Column>
            <Column field="country" header="Country" sortable></Column>
            <Column field="service_type" header="Service Type" sortable></Column>
            <Column field="price" header="Price" sortable></Column>
            <Column field="service_time" header="Service Time" sortable></Column>
            <Column v-if="showProvidersAddServices" field="provider" header="Provider">
              <template #body="{ data, field }">
                <router-link v-if="data[field]" :to="data[field].link"> {{ data[field].html }} </router-link>
              </template>
            </Column>
            <Column v-else field="" header=""></Column>
            <Column>
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editAddService(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteAddService(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Table from '@/components/elem/Table'
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
  name: 'SoftwareNet',
  components: {
    Table,
    EditIcon,
    TableRemoveItemIcon
  },
  data: () => ({
    requestsTableData: {
      body: [],
      paginationData: null
    },
    servicesRequestsTableData: {
      body: [],
      paginationData: null
    },
    showProvidersAddServices: false
  }),
  computed: {
    ...mapState({
      offersTableData: state => state.software_net.offersTableData,
      optionsTableData: state => state.software_net.optionsTableData,
      addServicesTableData: state => state.software_net.addServicesTableData,
    }),
    getRequestsTableData() {
      let data = {
        header: [
          { ttl: 'ID' },
          { ttl: 'User ID' },
          { ttl: 'Software Offer' },
          { ttl: 'Options' },
          // { ttl: 'Payment Methods' },
          { ttl: 'Created At' },
          // { ttl: 'More' }
        ],
        body: [],
        paginationData: this.requestsTableData.paginationData
      }

      if (this.requestsTableData.body.length > 0) {
        this.requestsTableData.body.forEach(el => {
          let optionsList = ''

          if (this.optionsTableData.body) {
            el.options.forEach((opt, key, array) => {
              if (+el.user_id === +opt.user_id) {
                const option = this.optionsTableData.body.find(el => +el[0] === +opt.software_option_id)
                optionsList += option[1]
                if (key !== array.length - 1) {
                  optionsList += ', '
                }
              }
            })
          }

          let request = {
            id: el.id,
            user_id: el.user_id,
            software_offer: el.software_offer.name,
            options: optionsList,
            created_at: el.created_at,
            // { type: 'more' }
          }

          data.body.push(request)
        })
      }

      return data;
    },
    getServicesRequestsTableData() {
      let data = {
        colsWidth: ['6%', '10%', '24%', '40%', '15%'],
        header: [
          { ttl: 'ID' },
          { ttl: 'User ID' },
          { ttl: 'Services Name' },
          { ttl: 'Price' },
          { ttl: 'Created At' }
        ],
        body: [],
        paginationData: this.servicesRequestsTableData.paginationData
      }

      this.servicesRequestsTableData.body.forEach(el => {
        data.body.push({
          id: el.id,
          user_id: el.user.id,
          service_name: el.service.service_name,
          price: el.service.price || '',
          create_at: el.created_at
        })
      })

      return data;
    }
  },
  created() {
    this.getRequests()
    this.getServicesRequests()
    this.getOffers()
    this.getOptions()
    this.getAddServices()
  },
  methods: {
    ...mapActions({
      getOffers: 'software_net/getOffers',
      getOptions: 'software_net/getOptions',
      getAddServices: 'software_net/getAddServices',
    }),
    toggleAddServices() {
      this.showProvidersAddServices = !this.showProvidersAddServices
      this.getAddServices({
        page: 1,
        providers: this.showProvidersAddServices
      })
    },
    async getRequests(page = 1) {
      await axios.get('admin/software-offers/requests?page=' + page)
        .then(({ data }) => {
          this.requestsTableData.paginationData = data
          this.requestsTableData.body = data.data
        })
        .catch(errors => console.dir(errors))
    },
    async getServicesRequests(page = 1) {
      await axios.get('admin/software-add-services/requests?page=' + page)
        .then(({ data }) => {
          this.servicesRequestsTableData.paginationData = data
          this.servicesRequestsTableData.body = data.data
        })
        .catch(errors => console.dir(errors))
    },
    openRequestMoreModal(id) {
      let data = this.feedbackTableData.body.find(el => +el[0] === id).slice()
      console.log(data)

      // this.$store.commit('setModal', {
      //   template: 'neo-bank-more',
      //   options: {
      //     names: ['ID', 'Email', 'Person', 'Messengers', 'Social', 'Price', 'Created At'],
      //     values: data
      //   }
      // })
    },
    openCreateOfferModal() {
      this.$store.commit('setModal', {
        template: 'create-software-offer'
      })
    },
    editOffers(obj) {
      this.$store.commit('setModal', {
        template: 'create-software-offer',
        options: {
          id: obj.id,
          sOptions: obj.options,
          form: {
            name: obj.name,
            slug: obj.slug,
            price: obj.price,
            currency_id: obj.currency_id,
            description: obj.description
          }
        }
      })
    },
    deleteOffers(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'software-offers',
          type: 'software offer',
          url: '',
          successfullMessage: 'You successfully deleted software offer',
        }
      })
    },
    openCreateOptionModal() {
      this.$store.commit('setModal', {
        template: 'create-software-option'
      })
    },
    editOptions(obj) {
      const item = this.optionsTableData.paginationData.data.find(el => +el[0] === obj.id)

      this.$store.commit('setModal', {
        template: 'create-software-option',
        options: {
          id: obj.id,
          form: {
            name: item[1],
            slug: item[2]
          }
        }
      })
    },
    deleteOptions(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'software-options',
          type: 'software option',
          url: '',
          successfullMessage: 'You successfully deleted software option',
        }
      })
    },
    openCreateAddServiceModal() {
      this.$store.commit('setModal', {
        template: 'create-software-add-service'
      })
    },
    editAddService(obj) {
      const item = this.addServicesTableData.paginationData.data.find(el => +el.id === obj.id)

      this.$store.commit('setModal', {
        template: 'create-software-add-service',
        options: {
          id: obj.id,
          form: {
            service_name: item.service_name,
            country_id: item.country ? item.country.id : null,
            service_type: item.service_type,
            price: item.price,
            service_time: item.service_time,
            is_legal_company: item.is_legal_company,
            provider_id: item.provider_id,
          }
        }
      })
    },
    deleteAddService(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'software-add-services',
          type: 'software additional service',
          url: '',
          successfullMessage: 'You successfully deleted software additional service',
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-provider {
  margin-left: 20px;
}
</style>