var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('overlay-scrollbars',{staticClass:"page page-offer shapes-bg"},[_c('div',{staticClass:"section-title-wrap"},[_c('h2',{staticClass:"page-details__title section-title"},[_c('span',{staticClass:"bc-first"},[_vm._v("Companies")])])]),_c('div',{staticClass:"box box-bg-shapes"},[_c('div',{staticClass:"box-header"},[_c('div',{staticClass:"pills"},[_vm._l((_vm.pills.data),function(item,index){return _c('div',{key:index,staticClass:"pill",class:{ 'pill_active': _vm.pills.active == item.id },on:{"click":function($event){return _vm.changePill(item.id)}}},[_vm._v(_vm._s(item.text)+" ")])}),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"dark-input",attrs:{"type":"text","placeholder":"Type to search"},domProps:{"value":(_vm.search)},on:{"change":_vm.getCompanies,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCompanies.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])],2)]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"main-table offers-table"},[_c('DataTable',{attrs:{"value":_vm.companies.body}},[_c('Column',{attrs:{"field":"id","header":"ID"}}),_c('Column',{attrs:{"field":"company_name","header":"Company Name","sortable":""}}),_c('Column',{attrs:{"field":"user","header":"Name","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',{staticClass:"user-details-link",on:{"click":function($event){return _vm.gotoUserDetails(data[field].id)}}},[_vm._v(" "+_vm._s(data[field].full_name)+" ")])]}}])}),_c('Column',{scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"col-dark"},[_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.openEditModal(data.id)}}},[_c('EditIcon',{attrs:{"fill":"#fff"}})],1),_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.openDocumentsModal(data.id)}}},[_c('RequestIcon')],1)])]}}])})],1),(_vm.companies.paginationData)?_c('pagination',{attrs:{"data":_vm.companies.paginationData},on:{"pagination-change-page":_vm.getCompanies}},[_c('span',{attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_vm._v("<")]),_c('span',{attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(">")])]):_vm._e()],1)])]),_c('div',{staticClass:"box box-bg-shapes"},[_c('div',{staticClass:"box-header"},[_c('div',{staticClass:"box-title"},[_vm._v(" Company Incorporation Services ")])]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"main-table offers-table"},[_c('DataTable',{attrs:{"value":_vm.incorporationServiceRequests.body}},[_c('Column',{attrs:{"field":"service_name","header":"Service name","sortable":""}}),_c('Column',{attrs:{"field":"price","header":"Price","sortable":""}}),_c('Column',{attrs:{"field":"user","header":"Customer","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',{staticClass:"user-details-link",on:{"click":function($event){return _vm.gotoUserDetails(data[field].id)}}},[_vm._v(" "+_vm._s(data[field].full_name)+" ")])]}}])}),_c('Column',{scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"col-dark"},[_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.deleteIncorporationServiceRequest(data.id)}}},[_c('TableRemoveItemIcon')],1)])]}}])})],1),(_vm.incorporationServiceRequests.paginationData)?_c('pagination',{attrs:{"data":_vm.incorporationServiceRequests.paginationData},on:{"pagination-change-page":_vm.getIncorporationServiceRequests}},[_c('span',{attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_vm._v("<")]),_c('span',{attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(">")])]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }