export default ({
  namespaced: true,
  state: () => ({
    tariffPackages: {
      // colsWidth: ['5%', '15%', '10%', '15%', '40%', '15%'],
      colsWidth: ['5%', '15%', '10%', '55%', '10%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name' },
        { ttl: 'Price' },
        // { ttl: 'Risk' },
        { ttl: 'Description' },
        { ttl: '' }
      ],
      body: [],
      paginationData: null,
    }
  }),
  actions: {
    async getTariffPackages ({ commit }, payload = { page: 1 }) {
      await axios.get('admin/accounts/tariff-packages?page=' + payload.page)
        .then(({ data }) => {
          commit('setTariffPackages', { data: data });
        })
        .catch(err => console.dir(err))
    },
    // async createOffer ({ dispatch }, payload) {
    //   await axios.post('admin/business-offers', payload)
    //     .then(res => {
    //       dispatch('getOffers', 1)
    //     })
    // },
    async updateTariffPackage ({ dispatch }, payload) {
      await axios.post(`admin/accounts/tariff-packages/update`, payload, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          dispatch('getTariffPackages')
        })
    },
  },
  mutations: {
    setTariffPackages (state, payload) {
      state.tariffPackages.paginationData = payload.data
      state.tariffPackages.body = []
      payload.data.data.forEach(el => {
        state.tariffPackages.body.push([
          el.id,
          el.name,
          el.price && el.price.length ? `${+el.price[0].value} ${el.price[0].currency.code}` : '--',
          el.description,
          { type: 'edit-delete-actions', id: el.id}
        ])
      })
    },
  }
})
