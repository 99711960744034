<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img" />
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">{{ isEdit ? 'Edit' : 'Create' }} Wallet</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Name</div>
        <div class="data">
          <input type="text" class="inpt" v-model="form.name">
        </div>
      </div>
      <div class="row">
        <div class="label">Currency value</div>
        <div class="data">
          <input type="text" class="inpt" v-model="form.currency_value">
        </div>
      </div>
      <div class="row">
        <div class="label">Address</div>
        <div class="data">
          <input type="text" class="inpt" v-model="form.address">
        </div>
      </div>

      <div class="row">
        <div class="label">Icon</div>
        <div class="data">
          <input type="file" class="dn" ref="icon" accept="image/*" @change="handleIconChange">
          <div v-if="form.icon" class="fileBox">
            <div class="filename">{{ form.icon.name }}</div>
            <div class="btn btn__remove" @click="removeIconFile">&#10006;</div>
          </div>
          <div v-else class="fileBox">
            <div @click="$refs.icon.click()" class="btn btn__fileUpload">Upload file</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">QR-Code</div>
        <div class="data">
          <input type="file" class="dn" ref="qr_code" accept="image/*" @change="handleQrChange">
          <div v-if="form.qr_code" class="fileBox">
            <div class="filename">{{ form.qr_code.name }}</div>
            <div class="btn btn__remove" @click="removeQrFile">&#10006;</div>
          </div>
          <div v-else class="fileBox">
            <div @click="$refs.qr_code.click()" class="btn btn__fileUpload">Upload file</div>
          </div>
        </div>
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>
  
<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    allCurrencies: null,
    form: {
      name: null,
      currency_value: null,
      icon: null,
      address: null,
      qr_code: null
    },
    error: null
  }),
  computed: {
    isEdit() {
      return this.options.isEdit
    }
  },
  created() {
    this.updateForm()
  },
  methods: {
    updateForm() {
      if (!this.isEdit) return;

      axios.get('data/wallets/' + this.options.id)
        .then(res => {
          this.form.address = res.data.address
          this.form.name = res.data.name
          this.form.currency_value = res.data.currency_value
        })
        .catch(err => console.dir(err))
    },
    handleIconChange(ev) {
      if (ev.target.files.length) {
        this.form.icon = ev.target.files[0]
      }
    },
    removeIconFile() {
      this.$refs.icon.value = null
      this.form.icon = null
    },
    handleQrChange(ev) {
      if (ev.target.files.length) {
        this.form.qr_code = ev.target.files[0]
      }
    },
    removeQrFile() {
      this.$refs.qr_code.value = null
      this.form.qr_code = null
    },
    async save() {
      const formData = new FormData()

      formData.append('currency_value', this.form.currency_value)
      formData.append('name', this.form.name)
      formData.append('address', this.form.address)
      formData.append('icon', this.form.icon)
      formData.append('qr_code', this.form.qr_code)

      if (this.isEdit) {
        await axios.post(this.options.url, formData)
          .then(res => {
            if (res.data.success) {
              this.$noty.success(this.options.successfullMessage)
              this.$store.dispatch('editItemFromTable', {
                id: this.options.id,
                table: this.options.table,
                actionType: this.options.actionType
              })
            }

            this.close();
          })
          .catch(error => console.dir(error));

      } else {
        await axios.post('admin/accounts/wallets', formData)
          .then(res => {
            this.options.fetchData();
            this.close();
          })
          .catch(error => console.log(error))
      }
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>
  
<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }

        .fileBox {
          display: flex;
          align-items: center;

          .filename {
            padding-right: .25rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .item {
            display: flex;
            align-items: center;
            margin-bottom: .25rem;

            .inputBlock {
              display: inline-flex;
              width: 15rem;
              height: 2.75rem;
              padding: .25rem;
              border: 1px solid #0071e3;
              border-radius: 6px;

              input {
                flex-grow: 1;
                min-width: 1rem;
                height: 100%;
                text-align: center;
                border: none;

                &:focus {
                  outline: none;
                }
              }

              select {
                height: 100%;
                margin-left: .25rem;
                padding: .25rem .5rem;
                color: #fff;
                background-color: #000;
                border-radius: .25rem;
              }
            }
          }
        }
      }

      .fileBox {
        .btn {
          display: inline-flex;
          line-height: 1;
          color: #fff;
          border-radius: .25rem;

          &__fileUpload {
            padding: 0.75rem 1rem 10px;
            text-transform: uppercase;
            background-color: var(--c_green);
          }
        }
      }
    }

    .btn {
      display: inline-flex;
      line-height: 1;
      color: #fff;
      border-radius: .25rem;

      &__remove {
        padding: .25rem .5rem;
        margin-left: .5rem;
        font-size: 1.25rem;
        background-color: var(--c_error);
      }

      &__newPrice {
        padding: .25rem .75rem;
        margin-top: .25rem;
        font-size: 2rem;
        background-color: var(--c_green);
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}
</style>
  