<template>
  <div class="auth-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {
	name: 'AuthLayout',
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  // background-image: url('/img/hero-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>