<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Important Links
      </div>
    </div>
    <div class="modal__body">
      <overlay-scrollbars>
        <div class="form">
          <div class="form-group">
            <div class="additional-items_wrapper">
              <div v-for="(link, index) in important_links" :key="index" class="additional-item">
                <div class="additional-item__header">
                  <label :for="'link_' + index" @click="goToUrl(link.link)" :class="{'link': link.link}">
                    {{ link.name ? link.name : 'Link № ' + (index + 1) }}
                  </label>
                  <div class="delete-btn" @click="deleteImportantLink(index)">
                    <Close class="img"/>
                  </div>
                </div>
                <div class="additional-item__body">
                  <div class="additional-item__col col-50">
                    <input type="text" name="name" v-model="link.name" placeholder="Enter a name">
                  </div>
                  <div class="additional-item__col col-50">
                    <input type="text" name="name" v-model="link.link" placeholder="Enter a link">
                  </div>
                </div>
              </div>
              <div class="additional-items__add-wrap">
                <button class="btn" id="contact_links_btn" @click="addImportantLink()">
                  Add important link
                </button>
              </div>
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="modal__footer">
      <div class="options">
        <div class="btn btn__save" @click="saveAll">Save data</div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import draggable from 'vuedraggable'
import BlueFile from '../img/BlueFile.vue'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    draggable,
    Close,
    BlueFile,
    BlueDownload,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      important_links: [],
      important_links_to_delete: [],
    };
  },
  async created () {
    await this.getImportantLinks()
  },
  computed: {
    isEdit () {
      return this.options.id && this.options.actionType == 'edit'
    }
  },
  methods: {
    addImportantLink(){
      this.important_links.push({
        name: null,
        link: null,
      })
    },
    async deleteImportantLink(index){
      if(this.important_links[index].hasOwnProperty('id')){
        let that = this

        await axios.delete('admin/important-links/' + this.important_links[index].id).then(function (response) {
          if(response.data.success){
            that.$noty.success('Important link was successfully deleted.')
          }
        }).catch(function (error) {
          console.log(error);
        })
      }

      this.important_links.splice(index, 1)
    },
    async getImportantLinks(){
      const that = this

      await axios.get('admin/important-links')
      .then(function (response) {
        that.important_links = response.data
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async saveAll(){
      for(let index = 0; index < this.important_links.length; index++) {
        await this.save(this.important_links[index], index);
      }

      await this.getImportantLinks()
    },
    async save(link, index){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/important-links'

      if (link.hasOwnProperty('id')) {
        url += '/' + link.id
      }

      await axios.post(url, link).then((response) => {
        this.$noty.success('Important link was successfully updated.')
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    },
    goToUrl(url){
      if(url){
        window.open(url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link{
  color: #0071e3;
  text-decoration: underline;
}
.modal {
  &__body {
    height: 68vh;
    padding-right: 2.5rem;
    overflow: hidden;

    .os-host {
      height: 100%;
      width: 100%;
    }
  }

  .form {
    width: 100%;
    padding-right: 1rem;

    &-group {
      --select_height: 46px;

      label {
        font-weight: 700;
        font-size: 20px;
        line-height: 2;
      }

      input,
      select,
      textarea {
        min-width: 100%;
        max-width: 100%;
        height: var(--select_height);
        padding: 12px 25px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 24px;
        outline: none;
      }
      textarea{
        height: 120px;
      }

      select {
        appearance: none;
        cursor: pointer;
        color: #333 !important;
      }
      .btn{
        color: #ffffff;
        font-size: 13px;
        padding: .25rem .75rem;
        background: var(--gr_new);
        border-radius: 6px;
        border: none;
      }
      .file{
        .btn{
          padding: 0;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  .select {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: calc(var(--select_height) / 2);
      right: 1.5rem;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      pointer-events: none;
    }
  }

  &__footer {
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    &__delete {
      background: var(--c_error);
    }
  }
}
.box_addFiles {
  width: 30%;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  display: flex;
  flex-direction: column;
  .ttl {
    padding-bottom: .25rem;
  }
  .separ {
    width: 3rem;
    height: .25rem;
    background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 14px;
    margin: .25rem 0;
  }
  .content {
    padding: 1rem;
    flex-grow: 1;
    border: 1px dashed #0071e3;
    box-sizing: border-box;
    filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .choose {
      text-align: center;
      .btn {
        padding: 0.5rem;
        background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
        border-radius: 6px;
        color: #fff;
        font-size: 13px;
      }
      .desc {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
    &_many {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.75rem 6px;
      .draggable {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
      }
      .file {
        position: relative;
        text-align: center;
        width: 4.5rem;
        max-width: 4.5rem;
        svg.img {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;
          fill: #0071e3;
          path{
            fill: #0071e3;
          }
        }
        .btn {
          &_del {
            position: absolute;
            top: 0;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: #B03636;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 40%;
              height: 40%;
            }
          }
          &_download {
            position: absolute;
            top: 1.7rem;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 90%;
              height: 90%;
            }
          }
        }
        .desc {
          font-size: 10px;
          color: #333333;
          word-break: break-word;
        }
      }
      .add {
        text-align: center;
        width: 2.5rem;
        max-width: 2.5rem;
        .btn {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
          display: flex;
          align-items: center;
          justify-content: center;
          svg.img {
            width: 45%;
            height: 45%;
            transform: rotate(45deg);
          }
        }
        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#risks {
  #risks>label {
    margin-bottom: 10px;
  }

  .form-check-input[type=radio] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
  }

  .risks-items{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .risk-check{
    min-width: 120px;
    &>label{
      display: block;
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    margin: 0;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #0071e3;
    border-color: #0071e3;
  }
}
</style>
