<template>
    <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
        <div class="section-title-wrap">
            <h2 class="page-details__title section-title">
                <span class="bc-first">Bank Suggestions</span>
            </h2>
        </div>

        <div class="box box-bg-shapes">
            <div class="box-header">
                <div class="box-title">
                    Bank sugestions
                </div>
            </div>
            <div class="box-body">
                <div class="main-table offers-table">
                    <DataTable :value="sugestionsTable.body">
                        <Column field="id" header="#"></Column>
                        <Column field="name" header="Name" sortable></Column>
                        <Column field="price" header="Price" sortable></Column>
                        <Column>
                            <template #body="{ data }">
                                <div class="col-dark">
                                    <div class="table-col-icon" @click="editSugestion(data)">
                                        <EditIcon fill="#fff" />
                                    </div>
                                    <div class="table-col-icon" @click="deleteSugestion(data)">
                                        <TableRemoveItemIcon style="width: 30px; height: 30px" />
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                    <pagination v-if="sugestionsTable.paginationData" :data="sugestionsTable.paginationData"
                        @pagination-change-page="getSugestions">
                        <span slot="prev-nav">&lt;</span>
                        <span slot="next-nav">&gt;</span>
                    </pagination>
                </div>
            </div>
        </div>

        <div class="box box-bg-shapes">
            <div class="box-header">
                <div class="box-title">
                    Requests
                </div>
            </div>
            <div class="box-body">
                <div class="main-table offers-table">
                    <DataTable :value="sugestionRequestsTable.body">                          
                        <Column field="id" header="#"></Column>
                        <Column field="name" header="Name" sortable></Column>
                        <Column field="price" header="Price" sortable></Column>
                        <Column field="summary" header="Summary" sortable></Column>                        
                        <Column field="status" header="Status" sortable></Column>
                        <Column field="customer.full_name" header="Customer" sortable></Column>
                        <Column>
                            <template #body="{ data }">
                                <div class="btns" v-if="data.status == 'pending'">
                                    <div class="btn-gradient btn-confirm" @click="changeStatus(data)">
                                        Confirm
                                    </div>
                                    <div class="btn-decline" @click="deleteSugestionRequest(data)">
                                        Decline
                                    </div>
                                </div>
                                <div v-else>
                                    Confirmed
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                    <pagination v-if="sugestionRequestsTable.paginationData" :data="sugestionRequestsTable.paginationData"
                        @pagination-change-page="getSugestions">
                        <span slot="prev-nav">&lt;</span>
                        <span slot="next-nav">&gt;</span>
                    </pagination>
                </div>
            </div>
        </div>
    </overlay-scrollbars>
</template>
  

<script>
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
    components: {
        EditIcon,
        TableRemoveItemIcon
    },
    data: () => ({
        sugestionsTable: {
            body: [],
            paginationData: null
        },
        sugestionRequestsTable: {
            body: [],
            paginationData: null
        }
    }),
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getSugestions();
            this.getSugestionRequests();
        },
        getSugestions() {
            axios.get('admin/sugestions')
                .then(res => {
                    this.sugestionsTable.body = [];
                    this.sugestionsTable.paginationData = res.data;

                    res.data.data.forEach(item => {
                        const obj = {
                            id: item.id,
                            name: item.name,
                            price: item.price.toString(),
                        }

                        this.sugestionsTable.body.push(obj);
                    })
                })
                .catch(err => console.dir(err))
        },
        getSugestionRequests() {
            axios.get('admin/sugestion-requests')
                .then(res => {
                    this.sugestionRequestsTable.body = [];
                    this.sugestionRequestsTable.paginationData = res.data;

                    console.log(res);

                    res.data.data.forEach(item => {
                        const obj = {
                            id: item.id,
                            name: item.bank_sugestion.name,
                            price: '$' + item.bank_sugestion.price,
                            summary: item.bank_services_summary,
                            status: item.status,
                            customer: {
                                id: item.customer.id,
                                full_name: item.customer.name
                            }
                        }

                        this.sugestionRequestsTable.body.push(obj)
                    })
                })
                .catch(err => console.dir(err));
        },
        createSugestion() {
            this.$store.commit('setModal', {
                template: 'create-sugestion',
                options: {
                    id: null,
                    actionType: 'create',
                    table: 'sugestions',
                    objectType: 'sugestion',
                    url: 'admin/sugestions',
                    successfullMessage: 'You successfully added sugestion',
                    fetchData: this.getSugestions,
                }
            })
        },
        editSugestion(obj) {
            this.$store.commit('setModal', {
                template: 'create-sugestion',
                options: {
                    id: obj.id,
                    isEdit: true,
                    actionType: 'edit',
                    table: 'sugestions',
                    objectType: 'sugestion',
                    url: 'admin/sugestions/' + obj.id,
                    successfullMessage: 'You successfully updated sugestion',
                }
            })
        },
        deleteSugestion(obj) {
            this.$store.commit('setModal', {
                template: 'delete',
                options: {
                    id: obj.id,
                    actionType: 'delete',
                    table: 'sugestions',
                    type: 'sugestion',
                    url: 'admin/sugestions/' + obj.id,
                    successfullMessage: 'You successfully deleted sugestion',
                    fetchData: this.getSugestions,
                }
            })
        },
        deleteSugestionRequest(obj) {
            console.log(obj)
            this.$store.commit('setModal', {
                template: 'delete',
                options: {
                    id: obj.id,
                    actionType: 'delete',
                    table: 'sugestion_requests',
                    type: 'sugestion request',
                    url: 'admin/sugestion-request/' + obj.id,
                    successfullMessage: 'You successfully deleted sugestion request',
                    fetchData: this.getSugestionRequests,
                }
            })
        },
        changeStatus(obj) {
            axios.put('admin/sugestion-update-status/' + obj.id)
                .then(res => {
                    if (res.data.success) {
                        this.getSugestionRequests();
                    }
                })
                .catch(err => console.dir(err));
        },
        updateCurrentPage() {
            this.getSugestions()
        },
    },
    watch: {
        '$store.state.deleteItemFromTableData': function (data) {
            this.updateCurrentPage()
        },
        '$store.state.editItemFromTableData': function (data) {
            this.updateCurrentPage()
        },
    }
}
</script>
  
<style lang="scss" scoped>
.btns {
    display: flex;
}

.btn-decline {
    margin-left: 20px;
}

.btn-confirm, .btn-decline{
    padding: 15px;
}
</style>