<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        All data
      </div>
    </div>
    <div class="modal__body">
      <div
        v-for="(value, key) in options.values" :key="key"
        class="row"
      >
        <div class="col col__name">{{ options.names[key] }}</div>
        <div class="col col__value">{{ value }}</div>
      </div>
    </div>
    <div class="modal__footer"></div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      padding: .25rem 0;
      margin: .25rem 0 0;
      border-bottom: 1px solid #989898;

      .col {
        padding: 0 .25rem;
        max-height: 10rem;
        word-break: break-word;
        overflow-y: auto;

        &__name {
          min-width: 20%;
          font-weight: bold;
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}
</style>
