<template>
   <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.445312 26.9278V23.2816H24.5525V26.9278H0.445312ZM2.91629 20.0874V16.0796L14.1563 4.83967L18.1641 8.84748L6.92411 20.0874H2.91629ZM4.72433 18.2794H6.08036L15.5725 8.78721L14.2165 7.43118L4.72433 16.9234V18.2794ZM19.49 7.52159L15.4821 3.51377L18.0134 0.982523C18.2344 0.721362 18.4855 0.58576 18.7667 0.575715C19.048 0.56567 19.3292 0.701273 19.6105 0.982523L21.9609 3.33297C22.2221 3.59413 22.3527 3.87036 22.3527 4.16165C22.3527 4.45295 22.2422 4.72918 22.0212 4.99034L19.49 7.52159Z" :fill="fill"/>
   </svg>
</template>
<script>
export default {
   name: 'EditIcon',
   props: {
      fill: {
         type: String,
         default: 'white'
      }
   }
}
</script>