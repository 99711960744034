import { render, staticRenderFns } from "./PaidRequests.vue?vue&type=template&id=0dd03204&scoped=true&"
import script from "./PaidRequests.vue?vue&type=script&lang=js&"
export * from "./PaidRequests.vue?vue&type=script&lang=js&"
import style0 from "./PaidRequests.vue?vue&type=style&index=0&id=0dd03204&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd03204",
  null
  
)

export default component.exports