<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        <span v-if="!is_show_new_work_flow">
          Work flows
        </span>
        <span v-if="is_show_new_work_flow">
          Create New work flow
        </span>
      </div>
    </div>
    <div class="modal__body">
      <overlay-scrollbars>
        <div class="form">
          <div class="form-group" v-if="is_show_new_work_flow">
            <label class="font-size-m">
              New Work Flow name
            </label>
            <input type="text" name="new_work_flow_name" v-model="new_work_flow.name" placeholder="Enter a name"/>
            <label class="font-size-m">
              New Work flow steps
            </label>
            <draggable tag="ul" :list="new_work_flow.steps" class="additional-items_wrapper list-group" handle=".handle">
              <li
                class="additional-item list-group-item"
                v-for="(step, idx) in new_work_flow.steps"
                :key="step.sequence"
                :style="{ 'border-color': step.color }"
              >
                <div class="additional-item__header">
                  <div class="step-drag handle">
                    <AlignJustify class="img" />
                  </div>
                  <div class="additional-item__header-input__wrap">
                    <label class="font-size-s" :for="'new_work_flow_step_' + idx">
                      Step name № {{ idx + 1 }}
                    </label>
                    <input name="new_work_flow_step_name" :id="'new_work_flow_step_' + idx" type="text" v-model="step.name" />
                  </div>
                  <div class="delete-btn" @click="removeStep(step, idx, new_work_flow)">
                    <Close class="img" />
                  </div>
                </div>
                <div class="additional-item__body">
                  <div class="additional-item__col col-50">
                    <label class="font-size-s">
                      Step description
                    </label>
                    <textarea name="new_work_flow_step_description" v-model="step.description"></textarea>
                  </div>
                  <div class="additional-item__col col-50">
                    <div>
                      <label class="font-size-s">
                        Color
                      </label>
                      <input name="new_work_flow_step_color" :id="'new_work_flow_step_color_' + idx" type="color" v-model="step.color" />
                    </div>
                    <div>
                      <label class="font-size-s">
                        Manager
                      </label>
                      <div>
                        <select name="manager_name" class="inpt" v-model="step.manager_name">
                          <option :value="null">Select manager</option>
                          <option 
                            v-for="(manager, i) in managers" 
                            :value="manager.name"
                            :key="i"
                          >
                            {{ manager.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </draggable>
            <div class="additional-items__add-wrap">
              <button class="btn" id="new_work_flow_add_step" @click="addStep(new_work_flow)">
                Add step
              </button>
            </div>
          </div>
          <div class="form-group" v-for="(flow, index) in work_flows" :key="index" v-if="!is_show_new_work_flow">
            <div class="form-group__header">
              <h4 class="form-group__header-title">
                {{ flow.name }}
              </h4>
              <div class="form-group__header-btns">
                <span class="delete-btn"  @click="deleteWorkFlow(index)">
                  <Close class="img" />
                </span>
                <span class="toggle-btn" @click="openWorkFlow(index)">
                  <span v-if="opened_flow == index">
                    -
                  </span>
                  <span v-else>
                    +
                  </span>
                </span>
              </div>
            </div>
            <div class="form-group mt-2" v-if="opened_flow == index">
              <label>
                Work flow name
              </label>
              <input type="text" name="flow_name" v-model="flow.name" placeholder="Enter a name">
              <draggable tag="ul" :list="flow.steps" class="additional-items_wrapper mt-2 list-group" handle=".handle">
                <li
                  class="additional-item list-group-item"
                  v-for="(step, idx) in flow.steps"
                  :key="step.sequence"
                  :style="{ 'border-color': step.color }"
                >
                  <div class="additional-item__header">
                    <div class="step-drag handle">
                      <AlignJustify class="img" />
                    </div>
                    <div class="additional-item__header-input__wrap">
                      <label class="font-size-s">
                        Step name № {{ idx + 1 }}
                      </label>
                      <input type="text" v-model="step.name" />
                    </div>
                    <div class="delete-btn"  @click="removeStep(step, idx, flow)">
                      <Close class="img" />
                    </div>
                  </div>
                  <div class="additional-item__body">
                    <div class="additional-item__col col-50">
                      <label class="font-size-s">
                        Step description
                      </label>
                      <textarea name="new_work_flow_step_description" v-model="step.description"></textarea>
                    </div>
                    <div class="additional-item__col col-50">
                      <div>
                        <label class="font-size-s">
                          Color
                        </label>
                        <input name="new_work_flow_step_color" :id="'new_work_flow_step_color_' + idx" type="text" v-model="step.color" />
                      </div>
                      <div>
                        <label class="font-size-s">
                          Manager
                        </label>
                        <div>
                          <select name="manager_name" class="inpt" v-model="step.manager_name">
                            <option :value="null">Select manager</option>
                            <option 
                              v-for="(manager, i) in managers" 
                              :value="manager.name"
                              :key="i"
                            >
                              {{ manager.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </draggable>
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="modal__footer">
      <div class="options">
        <div class="btn">
          <span v-if="opened_flow != null" @click="saveWorkFlow(null)">
            Save work flow
          </span>
          <span v-else-if="opened_flow == null && !is_show_new_work_flow" @click="addWorkFlow">
            Add work flow
          </span>
          <span v-else-if="opened_flow == null && is_show_new_work_flow" @click="saveWorkFlow(new_work_flow)">
            Save new work flow
          </span>
        </div>
        <span class="back-btn" v-if="!opened_flow && is_show_new_work_flow" @click="is_show_new_work_flow = false">
          Show all work flows
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import draggable from 'vuedraggable'
import AlignJustify from '../img/AlignJustify.vue'
import BlueFile from '../img/BlueFile.vue'
import BlueDownload from '../img/BlueDownload.vue'
import Pen from '@/components/img/Pen.vue'

export default {
  components: {
    draggable,
    Close,
    BlueFile,
    BlueDownload,
    AlignJustify,
    Pen
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      managers: [],
      work_flows: [],
      is_show_new_work_flow: false,
      new_work_flow: {
        name: '',
        steps: [],
      },
      opened_flow: null,
    };
  },
  async created () {
    await this.getWorkFlows()
    await this.getManagers()
  },
  computed: {
    isEdit () {
      return this.options.id && this.options.actionType == 'edit'
    }
  },
  methods: {
    openWorkFlow(index){
      if(this.opened_flow == index){
        this.opened_flow = null
      }
      else{
        this.opened_flow = index
      }
    },
    addWorkFlow(){
      this.is_show_new_work_flow = true
    },
    addStep(flow){
      let sequence = flow.steps.length + 1

      flow.steps.push({
        name: '',
        description: '',
        manager_name: '',
        color: '',
        sequence: sequence,
        upload: {
          documents: [{name: null}]
        }
      })
    },
    async removeStep(step, index, flow){
      if(step.hasOwnProperty('id')){
        await axios.delete('admin/steps/' + step.id)
        .then(function (response) {
          if(response.data.success){
            that.$noty.success('Step was successfully deleted.')
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }

      flow.steps.splice(index, 1)
    },
    async deleteWorkFlow(index){
      if(this.work_flows[index].hasOwnProperty('id')){
        let that = this
        
        await axios.delete('admin/work-flows/' + this.work_flows[index].id)
        .then(function (response) {
          if(response.data.success){
            that.$noty.success('Work flow was successfully deleted.')

            that.work_flows.splice(index, 1)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    async getWorkFlows(){
      const that = this

      await axios.get('admin/work-flows')
      .then(function (response) {
        that.work_flows = response.data
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async getManagers () {
      const that = this
      await axios.get('admin/data/managers')
        .then(function (response) {
          that.managers = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
	  },
    async saveWorkFlow(flow = null){
      if(!flow){
        flow = this.work_flows[this.opened_flow]
        console.log(flow);
      }

      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/work-flows'

      if (flow.hasOwnProperty('id')) {
        url += '/' + flow.id
      }

      let that = this

      await axios.post(url, flow)
      .then((response) => {
        that.$noty.success('Work flow was successfully processed.')

        that.opened_flow = null
        that.is_show_new_work_flow = false
        that.new_work_flow = {
          name: '',
          steps: [],
        }

        that.getWorkFlows()
        that.getManagers()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item{
  background: #fff;
}
.mt-2{
  margin-top: 16px;
}
.additional-item{
  &__header{
    gap: 12px;
    align-items: center;
    .step-drag{
      width: 1.5rem;
      height: 27px;
      margin-top: 22px;
      svg{
        fill: #0071e3;
      }
    }
    &-input{
      &__wrap{
        flex-grow: 1;
      }
    }
    .delete-btn{
      margin-top: 22px;
    }
  }
  &__body{
  }
}
.form-group{
  &__header{
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-btns{
      display: flex;
      flex-direction: row;
      gap: 6px;
    }
    .delete-btn, .toggle-btn{
      width: 1.5rem;
      height: 1.5rem;
      background-color: #B03636;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
    }
    .toggle-btn{
      background: #0071e3;
      color: #fff;
    }
  }
}
.list-group-item{
  padding: 12px;
  border: 1px dashed #666;
  border-radius: 24px;
}
.font-size{
  &-xs{
    font-size: 12px !important;
  }
  &-s{
    font-size: 14px !important;
  }
  &-m{
    font-size: 16px !important;
  }
}
.modal {
  &__body {
    height: 68vh;
    padding-right: 2.5rem;
    overflow: hidden;

    .os-host {
      height: 100%;
      width: 100%;
    }
  }

  .form {
    width: 100%;
    padding-right: 1rem;

    &-group {
      --select_height: 46px;

      label {
        font-weight: 700;
        font-size: 20px;
        line-height: 2;
      }

      input,
      select,
      textarea {
        min-width: 100%;
        max-width: 100%;
        height: var(--select_height);
        padding: 12px 25px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 24px;
        outline: none;
      }
      textarea{
        height: 120px;
      }

      select {
        appearance: none;
        cursor: pointer;
        color: #333 !important;
      }
      .btn{
        color: #ffffff;
        font-size: 13px;
        padding: .25rem .75rem;
        background: var(--gr_new);
        border-radius: 6px;
        border: none;
      }
      .file{
        .btn{
          padding: 0;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  .select {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: calc(var(--select_height) / 2);
      right: 1.5rem;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      pointer-events: none;
    }
  }

  &__footer {
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    &__delete {
      background: var(--c_error);
    }
  }
}
.box_addFiles {
  width: 30%;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  display: flex;
  flex-direction: column;
  .ttl {
    padding-bottom: .25rem;
  }
  .separ {
    width: 3rem;
    height: .25rem;
    background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 14px;
    margin: .25rem 0;
  }
  .content {
    padding: 1rem;
    flex-grow: 1;
    border: 1px dashed #0071e3;
    box-sizing: border-box;
    filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .choose {
      text-align: center;
      .btn {
        padding: 0.5rem;
        background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
        border-radius: 6px;
        color: #fff;
        font-size: 13px;
      }
      .desc {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
    &_many {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.75rem 6px;
      .draggable {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
      }
      .file {
        position: relative;
        text-align: center;
        width: 4.5rem;
        max-width: 4.5rem;
        svg.img {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;
          fill: #0071e3;
          path{
            fill: #0071e3;
          }
        }
        .btn {
          &_del {
            position: absolute;
            top: 0;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: #B03636;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 40%;
              height: 40%;
            }
          }
          &_download {
            position: absolute;
            top: 1.7rem;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 90%;
              height: 90%;
            }
          }
        }
        .desc {
          font-size: 10px;
          color: #333333;
          word-break: break-word;
        }
      }
      .add {
        text-align: center;
        width: 2.5rem;
        max-width: 2.5rem;
        .btn {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
          display: flex;
          align-items: center;
          justify-content: center;
          svg.img {
            width: 45%;
            height: 45%;
            transform: rotate(45deg);
          }
        }
        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#risks {
  #risks>label {
    margin-bottom: 10px;
  }

  .form-check-input[type=radio] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
  }

  .risks-items{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .risk-check{
    min-width: 120px;
    &>label{
      display: block;
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    margin: 0;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #0071e3;
    border-color: #0071e3;
  }
}
</style>
