<template>
  <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Legal net</span>
      </h2>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Legal Net</div>
        <div>
          <select name="country" class="search-input"
            v-model="search_country" 
            @change="getLegalServices">
            <option :value="null">Select country</option>
            <option 
              v-for="(country, i) in countries" 
              :value="country.name"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
        <div>
          <select name="service_type" class="search-input"
            v-model="search_service_type" 
            @change="getLegalServices">
            <option :value="null">Select service type</option>
            <option 
              v-for="(service, i) in Object.keys(services)" 
              :value="service"
            >
              {{ service }}
            </option>
          </select>
        </div>
        <div>
          <select name="service_name" class="search-input"
            v-model="search_service_name" 
            @change="getLegalServices">
            <option :value="null">Select service name</option>
            <option 
              v-for="(item, i) in services[search_service_type]" 
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <div class="box-btns">
          <div @click="createLegalService" class="btn-gradient">Create</div>
          <div class="btn-provider" @click="showProvidersServices = !showProvidersServices">
            <span class="btn-provider__arrow">◀</span>
            <span v-if="showProvidersServices">Provider</span>
            <span v-else>User</span>
            <span class="btn-provider__arrow">▶</span>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="legalAtlasTableData.body">
            <Column field="country" header="Country"></Column>
            <Column field="services_type" header="Services Type"></Column>
            <Column field="service_name" header="Services name"></Column>
            <Column field="date_of_service" header="Date of service"></Column>
            <Column field="legal_name" header="Name of Legal Co."></Column>
            <Column field="is_validated" header="Is validated">
              <template #body="{data, field}">
                <div>
                  <Tmblr 
                    :active="data.is_validated" 
                    customClass="dark"
                    @changeState="toggleProperty(data.id, 'is_validated')" />
                </div>
              </template>
            </Column>
            <Column field="provider_price" header="Provider Price"></Column>
            <Column field="public_price" header="Public Price"></Column>
            <Column field="service_time" header="Service Time"></Column>
            <Column field="description" header="Description of Benefit"></Column>
            <Column field="web_site" header="Web Site"></Column>
            <Column v-if="showProvidersServices" field="provider" header="Provider">
              <template #body="{ data, field }">
                <div>
                  {{ data[field].member_login }}
                </div>
              </template>
            </Column>
            <Column v-else field="" header=""></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editLegalAtlas(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div v-if="$store.state.user.is_super_admin">
                    <Tmblr 
                      :active="data.is_manager_can" 
                      customClass="dark"
                      @changeState="toggleProperty(data.id, 'is_manager_can')" />
                  </div>
                  <div class="table-col-icon" @click="deleteLegalAtlas(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="legalAtlasTableData.paginationData" :data="legalAtlasTableData.paginationData"
            @pagination-change-page="getLegalServices">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Packages</div>
        <div class="box-btns">
          <div @click="createPackage" class="btn-gradient">Create</div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="packagesTable.body">
            <Column field="package_type" header="Package Type"></Column>
            <Column field="name" header="Package Name"></Column>
            <Column field="price" header="Price"></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editPackage(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deletePackage(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="packagesTable.paginationData" :data="packagesTable.paginationData"
            @pagination-change-page="getPackages">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';
import Tmblr from '@/components/elem/Tmblr';

export default {
  components: {
    EditIcon,
    TableRemoveItemIcon,
    Tmblr
  },
  data: () => ({
    legalAtlasTableData: {
      body: [],
      paginationData: null,
    },
    packagesTable: {
      body: [],
    },
    search_country: '',
    search_service_name: '',
    search_service_type: '',
    countries: [],
    services: {
      'License incorporation': [
        'Crypto',
        'Money service/remittee',
        'Small Payment Institution',
        'Payment Institution',
        'Small EMI',
        'EMI',
        'Bank',
        'VC',
        'Fund',
        'Asset management',
        'Broker'
      ],
      'Out-staffing': [
        'Nominal',
        'Director',
        'AML Officer',
        'UBO n.',
        'All Staff'
      ],
      'Accounting & Audit': [
        'Accounting',
        'Audit',
        'Reporting',
        'Statement',
        'All'
      ],
      'Other legal services': [
        'Legal opinion',
        'Legal business Support',
        'Legal structuring',
        'Legal SOF',
        'Legal Represent',
        'All Legal'
      ],
      'Escrow services': [
        'Escrow License',
        'Escrow License & Insurance',
        'Escrow Bank'
      ]
    },
    showProvidersServices: false,
  }),
  created() {
    this.getLegalServices()
    this.getPackages();
    this.getCountries()
  },
  methods: {
    async getCountries () {
      await axios.get('admin/data/countries')
      .then(({data}) => {
        this.countries = data
      })
      .catch((error) => {
        console.log(error);
      })
    },
    toggleProperty(id, property){
      if(id){
        let urlPart = '';
        
        if(property == 'is_validated'){
          urlPart = 'toggle-validated';
        }
        
        if(property == 'is_manager_can'){
          urlPart = 'toggle-can';
        }
        
        let that = this
        
        axios.post('admin/legal-atlas/' + id + '/' + urlPart)
        .then(function (response) {
          that.updateCurrentPage();
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    getLegalServices(page = 1) {
      const basePath = 'admin/legal-atlas?page=' + page
      const sortParams = '&providers=' + this.showProvidersServices + '&admin=' + true;
      
      let filterParams = ''
      
      filterParams += this.search_country ? '&country=' + this.search_country : ''
      
      filterParams += this.search_service_type ? '&services_type=' + this.search_service_type : ''
      
      filterParams += this.search_service_name && this.search_service_type ? '&service_name=' + this.search_service_name : ''

      axios.get(basePath + filterParams + sortParams)
        .then(response => {
          this.legalAtlasTableData.paginationData = response.data
          this.legalAtlasTableData.body = []

          response.data.data.forEach(el => {
            let legalAtlas = {
              id: el.id,
              legal_name: el.legal_name,
              country: el.country ? el.country.name + (el.offer_countries ? ' | ' + el.offer_countries : '') : (el.offer_countries ? ' | ' + el.offer_countries : ''),
              services_type: el.services_type,
              date_of_service: this.getFormattedDate(el.date_of_service),
              provider_price: el.price,
              public_price: el.public_price,
              description: el.benefit_description,
              service_time: el.service_time,
              web_site: el.web_site,
              is_validated: el.is_validated,
              is_manager_can: el.is_manager_can,
              service_name: el.service_name
            }

            if (this.showProvidersServices) {
              legalAtlas.provider = {
                id: el.provider.id,
                member_login: (el.provider.member_login) ? el.provider.member_login : el.provider.email
              }
            }

            this.legalAtlasTableData.body.push(legalAtlas);
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getFormattedDate(value){
      if(value){
        const date = new Date(value);
        
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        
        return formattedDate;
      }
    },
    getPackages() {
      axios.get('admin/packages')
        .then(res => {
          const packages = res.data;
          this.packagesTable.body = [];

          packages.forEach(packageItem => {
            const packageObj = {
              id: packageItem.id,
              package_type: packageItem.type.name,
              name: packageItem.name,
              price: packageItem.price.toString(),
            }

            this.packagesTable.body.push(packageObj);
          })
        })
        .catch(err => console.dir(err))
    },
    createPackage() {
      this.$store.commit('setModal', {
        template: 'create-package',
        options: {
          id: null,
          actionType: 'create',
          table: 'packages',
          objectType: 'package',
          url: 'admin/accounts/new-package',
          successfullMessage: 'You successfully added package',
          fetchData: this.getPackages,
        }
      })
    },
    editLegalAtlas(obj) {
      this.$store.commit('setModal', {
        template: 'legal-atlas',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'legal-atlases',
          objectType: 'legal atlas',
          url: 'admin/legal-atlas/update/' + obj.id,
          successfullMessage: 'You successfully updated legal atlas',
        }
      })
    },
    editPackage(obj) {
      this.$store.commit('setModal', {
        template: 'create-package',
        options: {
          id: obj.id,
          isEdit: true,
          actionType: 'edit',
          table: 'packages',
          objectType: 'package',
          url: 'admin/accounts/packages/' + obj.id,
          successfullMessage: 'You successfully updated package',
        }
      })
    },
    createLegalService() {
      this.$store.commit('setModal', {
        template: 'legal-atlas',
        options: {
          id: null,
          actionType: 'create',
          table: 'legal-atlases',
          objectType: 'legal atlas',
          url: 'admin/legal-atlas',
          successfullMessage: 'You successfully created legal atlas',
        }
      })
    },
    deleteLegalAtlas(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'legal-atlases',
          type: 'legal atlas',
          url: 'admin/legal-atlas/' + obj.id,
          successfullMessage: 'You successfully deleted legal atlas',
        }
      })
    },
    deletePackage(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'packages',
          type: 'package',
          url: 'admin/accounts/packages/' + obj.id,
          successfullMessage: 'You successfully deleted package',
          fetchData: this.getPackages,
        }
      })
    },
    updateCurrentPage() {
      this.getLegalServices()
      this.getPackages()
    },
    // sortBy(data) {
    //   this[data.obj].sort.loading = true
    //   this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
    //     ? 'desc' : 'asc'
    //   this[data.obj].sort.name = data.sort
    //   this.getLegalServices(1)
    // },
  },
  watch: {
    '$store.state.deleteItemFromTableData': function (data) {
      this.updateCurrentPage()
    },
    '$store.state.editItemFromTableData': function (data) {
      this.updateCurrentPage()
    },
    showProvidersServices: function (val) {
      this.updateCurrentPage();
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-provider {
  margin-left: 20px;
}
.search-input{
  padding: 15px;
  border-radius: 30px;
  background-color: var(--c_black);
  color: #fff;
  border: 1px solid #666;
  width: 200px;
  height: 56px;
}
</style>