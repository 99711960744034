<template>
  <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Neo Bank</span>
      </h2>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">
          Bank sugestions
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="getTableData.body">
            <Column field="id" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="linkedin" header="LinkedIn"></Column>
            <Column field="email" header="Email" sortable></Column>
            <Column field="website" header="Web site" sortable></Column>
            <Column field="messengers" header="Messengers" sortable></Column>
            <Column field="fin_services" header="Financial Services" sortable></Column>
            <Column field="description" header="Description" sortable></Column>
            <Column field="created_at" header="Created At" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="openMoreModal(data)">
                    More
                  </div>
                  <div class="table-col-icon" @click="openRatingModal(data)">
                    Rating
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <!-- <pagination v-if="sugestionsTable.paginationData" :data="sugestionsTable.paginationData"
            @pagination-change-page="getSugestions">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination> -->
        </div>
      </div>
    </div>

    <!-- <Table :options="getTableData" @more-modal="openMoreModal" @rating-modal="openRatingModal" @scroll-top="toElTop" /> -->
  </overlay-scrollbars>
</template>

<script>
import Table from '@/components/elem/Table'

export default {
  name: 'NeoBank',
  components: {
    Table
  },
  data: () => ({
    feedbackTableData: {
      colsWidth: ['3%', '12%', '12%', '12%', '12%', '12%', '12%', '15%', '10%'],
      header: [
        { ttl: 'ID' },
        { ttl: 'Name' },
        { ttl: 'LinkedIn' },
        { ttl: 'Email' },
        { ttl: 'Web site' },
        { ttl: 'Messengers' },
        { ttl: 'Financial services' },
        { ttl: 'Description' },
        { ttl: 'Created At' }
      ],
      body: [],
    },
  }),
  computed: {
    getTableData() {
      let data = {
        colsWidth: ['6%', '37%', '37%', '10%', '5%', '5%'],
        header: [
          { ttl: 'ID' },
          { ttl: 'Name' },
          { ttl: 'Email' },
          { ttl: 'Created At' },
          { ttl: 'Mark' },
          { ttl: 'More' }
        ],
        body: [],
        paginationData: null
      }

      if (this.feedbackTableData.body.length > 0) {
        let bodyArr = []
        this.feedbackTableData.body.forEach(el => {
          bodyArr.push([
            el[0],
            el[1],
            el[3],
            el[8],
            { type: 'rating_btn', rating: el[9], rating_color: el[10] },
            { type: 'more' }
          ])
        })
        data.body = bodyArr
      }
      return data
    }
  },
  created() {
    this.getFeedback()
  },
  methods: {
    async getFeedback() {
      await axios.get('admin/feedback')
        .then(({ data }) => {
          this.feedbackTableData.body = data.data
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    openMoreModal(id) {
      let data = this.feedbackTableData.body.find(el => +el[0] === id).slice()
      data.splice(9, 2)

      this.$store.commit('setModal', {
        template: 'neo-bank-more',
        options: {
          names: ['ID', 'Name', 'LinkedIn', 'Email', 'Web site', 'Messengers', 'Financial services', 'Description', 'Created At'],
          values: data
        }
      })
    },
    openRatingModal(id) {
      let data = this.feedbackTableData.body.find(el => +el[0] === id)
      this.$store.commit('setModal', {
        template: 'neo-bank-rating',
        options: {
          id: id,
          rating: data[9],
          rating_color: data[10],
          feedbackTableData: this.feedbackTableData
        }
      })
    },
    toElTop(el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  ::v-deep .tbl {

    &_header,
    &_body {

      .col:nth-child(2),
      .col:nth-child(3) {
        justify-content: flex-start;

        span {
          text-align: left;
        }
      }
    }
  }
}
</style>
