export default ({
  namespaced: true,
  state: () => ({
    offersTableData: {
      objName: 'offersTableData',
      colsWidth: ['4%', '12%', '12%', '14%', '20%', '11%', '11%', '18%', '10%'],
      header: [
        { ttl: 'ID' },
        { ttl: 'Type of Business' },
        { ttl: 'Type of License' },
        { ttl: 'Country' },
        { ttl: 'Description' },
        { ttl: 'Price', sort: 'price' },
        { ttl: 'From' },
        { ttl: 'Created At' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      },
      rawData: []
    },
    appliedBosTableData: {
      colsWidth: ['4%', '17%', '17%', '16%', '34%', '12%'],
      header: [
        { ttl: '#' },
        { ttl: 'User' },
        { ttl: 'Type of Business' },
        { ttl: 'Type of License' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null
    },
    providersOffers: false
  }),
  actions: {
    async getOffers ({ state, commit }, payload = { page: 1 }) {
      const sortParams = '&providers=' + state.providersOffers + '&sort=' + state.offersTableData.sort.name + '&order=' + state.offersTableData.sort.order
      await axios.get('admin/business-offers?page='+ payload.page + sortParams)
        .then(({ data }) => {
          commit('setOffersTableData', { data: data });
        })
    },
    async createOffer ({ dispatch }, payload) {
      await axios.post('admin/business-offers', payload)
        .then(res => {
          dispatch('getOffers')
        })
    },
    async updateOffer ({ dispatch }, payload) {
      await axios.post(`admin/business-offers/update/${payload.id}`, payload.form)
        .then(res => {
          dispatch('getOffers')
        })
    },
    async getAppliedBosData ({ commit }, payload = { page: 1 }) {
      await axios.get('admin/business-offers/applied?page='+ payload.page)
        .then(({ data }) => {
          commit('setAppliedBosTableData', { data: data });
        })
    },
  },
  mutations: {
    setOffersTableData (state, payload) {
      state.offersTableData.paginationData = payload.data;
      state.offersTableData.body = [];

      payload.data.data.forEach(el => {
        if (el.price.price) {
          el.price = el.price.currency ? `${+el.price.price} ${el.price.currency.code}` : `${+el.price.price} EUR`
        }

        state.offersTableData.body.push(el);
      })

      if (state.offersTableData.sort.name) state.offersTableData.sort.loading = false
    },
    setAppliedBosTableData (state, payload) {
      state.appliedBosTableData.paginationData = payload.data;
      console.log(payload.data)
      const tableData =  payload.data.data.map(function(businessOffer) {
        return [
          businessOffer.id, businessOffer.user.full_name, businessOffer.businessOffer.business_type, 
          businessOffer.businessOffer.license_type, {
            type: businessOffer.action_btn.type,
            id: businessOffer.action_btn.id,
            html: 'Show',
          }
        ];
      });
      state.appliedBosTableData.body = tableData;
    },
    setProvidersOffers (state, status) {
      state.providersOffers = status
    },
    setOffersTableHeader (state, header) {
      state.offersTableData.header = header
    },
    setOffersTableColWidth (state, colWidth) {
      console.log(colWidth)
      state.offersTableData.colWidth = colWidth
    }
  }
})
