<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Change password
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group">
          <label>Password</label>
          <input v-model="password" type="password" class="form-control">
          <span v-show="passwordError" class="invalid-feedback">{{ passwordError }}</span>
        </div>
        <div class="form-group">
          <label>Repeat password</label>
          <input v-model="repeatPassword" type="password" class="form-control">
          <span v-show="repeatPasswordError" class="invalid-feedback">{{ repeatPasswordError }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      password: '',
      repeatPassword: '',
      passwordError: '',
      repeatPasswordError: ''
    };
  },
  methods: {
    clearErrors() {
      this.passwordError = ''
      this.repeatPasswordError = ''
    },
    async submit() {
      const that = this
      this.clearErrors()
      if (this.password.length === 0) {
        this.passwordError = 'Please enter password'
        return
      } else if (this.password.length < 6) {
        this.passwordError = 'Password must have at least 6 characters'
        return
      } else if (this.password !== this.repeatPassword) {
        this.repeatPasswordError = 'Please repeat password'
        return
      }
      console.log(this.options)
      axios.post('admin/customers/password/' + this.options.customerId, {
        password: this.password
      })
      .then(function (response) { 
        that.clearErrors()
        that.close()
        if (response.data.success) {
          that.$noty.success('Password was successfully changed')
        } else {
          that.$noty.error('Password was not changed')
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
}
</style>
