import './assets/css/main.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cookies from 'js-cookie'
import Echo from 'laravel-echo'
import Toggle from '@/components/common/Toggle.vue'
import SelectSimple from '@/components/common/Select.vue'

import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import AutoComplete from 'primevue/autocomplete';
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

window.io = require('socket.io-client')

require('./plugins');

window.axios = require('axios')
window.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL

import { mixins } from './mixins';
Vue.mixin(mixins);

Vue.config.productionTip = false

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('Toggle', Toggle);
Vue.component('SelectSimple', SelectSimple);

Vue.component('DataTable', DataTable);
Vue.component('Dropdown', Dropdown);
Vue.component('Column', Column);
Vue.component('Calendar', Calendar);
Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('Textarea', Textarea);
Vue.component('MultiSelect', MultiSelect);
Vue.component('AutoComplete', AutoComplete);
Vue.component('InputMask', InputMask);
Vue.component('Password', Password);
Vue.component('Toast', Toast);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(PrimeVue);

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.state.token) {
      const token = cookies.get('x-access-token')
      if (token) {
  	    store.dispatch('setTokenFromCookie', { token })
  	  } else {
  	    return next({name:'Login'});
  	  }
    }
    next();
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
