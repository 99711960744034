<template>
  <overlay-scrollbars class="page page-offer shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Accounts</span>
      </h2>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Bank account</div>
        <div @click="createBankAccount" class="btn-gradient">Create</div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="bankAcc.body">
            <Column field="serial" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="country_code" header="Country Code" sortable></Column>
            <Column field="account_type" header="Type of Account" sortable></Column>
            <Column field="oif" header="Open/Integration fee" sortable></Column>
            <Column field="incoming_fee" header="Incoming fee" sortable></Column>
            <Column field="outgoing_fee" header="Outgoing fee" sortable></Column>
            <Column field="onboarding" header="Onboarding time" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editBankAccount(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteBankAccount(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="bankAcc.paginationData" :data="bankAcc.paginationData"
            @pagination-change-page="getBankAccounts">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Merchant account</div>
        <div @click="createMerchantAccount" class="btn-gradient">Create</div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="merchantAcc.body">
            <Column field="serial" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="country_code" header="Country Code" sortable></Column>
            <Column field="account_type" header="Type of Account" sortable></Column>
            <Column field="oif" header="Open/Integration fee" sortable></Column>
            <Column field="incoming_fee" header="Incoming fee" sortable></Column>
            <Column field="outgoing_fee" header="Outgoing fee" sortable></Column>
            <Column field="onboarding" header="Onboarding time" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editMerchantAccount(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteMerchantAcccount(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="merchantAcc.paginationData" :data="merchantAcc.paginationData"
            @pagination-change-page="getMerchantAccounts">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Wallets</div>
        <div @click="createWallet" class="btn-gradient">Create</div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="wallets.body">
            <Column field="name" header="Name" sortable></Column>
            <Column field="address" header="Wallet Address" sortable></Column>
            <Column field="currency" header="Currency value" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editWallet(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteWallet(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <!-- <div class="block">
          <div class="hdr">
            <Briefcase class="img" />
            <div class="ttl">Packages</div>
            <div @click="createPackage" class="btn create-account-btn">Create</div> 
          </div>
          <Table class="tariffPackages" :options="tariffPackages" :key="tariffPackagesKey" @editItem="editTariffPackage"
            @change-page="getTariffPackages" />
        </div>  -->
  </overlay-scrollbars>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
  components: {
    EditIcon,
    TableRemoveItemIcon
  },
  data: () => ({
    bankAcc: {
      body: [],
      paginationData: null,
    },
    merchantAcc: {
      body: [],
      paginationData: null,
    },
    wallets: {
      body: [],
    },
  }),
  computed: {
    // ...mapState({
    //   tariffPackages: state => state.tariff_packages.tariffPackages
    // }),
  },
  created() {
    this.$store.dispatch('setUser')
    this.getBankAccounts()
    this.getMerchantAccounts()
    // this.getTariffPackages()
    this.getWallets();
  },
  methods: {
    // ...mapActions({
    //   getTariffPackages: 'tariff_packages/getTariffPackages'
    // }),
    getWallets() {
      axios.get('data/wallets')
        .then(res => {
          const wallets = res.data;
          this.wallets.body = [];

          wallets.forEach(wallet => {
            const walletArr = {
              id: wallet.id,
              name: wallet.name,
              address: wallet.address,
              currency: wallet.currency_value,
              // {
              //   type: 'edit-delete-actions',
              //   id: wallet.id
              // }
            }

            this.wallets.body.push(walletArr);
          })
        })
        .catch(err => console.dir(err))
    },
    toBankAccountsTop() {
      console.log('toBankAccountsTop')
    },
    toMerchantAccountsTop() {
      console.log('toMerchantAccountsTop')
    },
    getBankAccounts(page = 1) {
      axios.get('admin/accounts/bank?page=' + page)
        .then(response => {
          this.bankAcc.paginationData = response.data
          this.bankAcc.body = []
          this.bankAcc.paginationData.data.forEach(el => {
            const countryCode = (el.country) ? el.country.code : ''
            this.bankAcc.body.push({
              id: el.id,
              serial: el.serial_number,
              name: el.bank_name,
              country_code: countryCode,
              account_type: el.account_type,
              oif: this.number_format(el.open_integration_fee, 2, '.', ' ') + '€',
              incoming_fee: el.incoming_fee.toString(),
              outgoing_fee: el.outgoing_fee.toString(),
              onboarding: el.onboarding_time_text,
              // { type: 'edit-delete-actions', id: el.id }
            })
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantAccounts(page = 1) {
      axios.get('admin/accounts/merchant?page=' + page)
        .then(response => {
          this.merchantAcc.paginationData = response.data
          this.merchantAcc.body = []
          this.merchantAcc.paginationData.data.forEach(el => {
            console.log(el)

            const countryCode = (el.country) ? el.country.code : ''
            this.merchantAcc.body.push({
              id: el.id,
              serial: el.serial_number,
              name: el.bank_name,
              country_code: countryCode,
              account_type: el.account_type,
              oif: this.number_format(el.open_integration_fee, 2, '.', ' ') + '€',
              incoming_fee: el.incoming_fee.toString(),
              outgoing_fee: el.outgoing_fee.toString(),
              onboarding: el.onboarding_time_text,
              // { type: 'edit-delete-actions', id: el.id }
            })
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    // getTariffPackages (page = 1) {
    //   axios.get('admin/accounts/tariff-packages?page=' + page)
    //     .then(({ data }) => {
    //       this.tariffPackages.paginationData = data
    //       data.data.forEach(el => {
    //         this.tariffPackages.body.push([
    //           el.id,
    //           el.name,
    //           el.price && el.price.length ? `${+el.price[0].value} ${el.price[0].currency.code}` : '--',
    //           el.description,
    //           { type: 'edit-delete-actions', id: el.id}
    //         ])
    //       })
    //     })
    //     .catch(err => console.dir(err))
    // },
    createBankAccount() {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: null,
          actionType: 'create',
          table: 'bank-account',
          objectType: 'bank account',
          url: 'admin/accounts',
          successfullMessage: 'You successfully created bank account',
        }
      })
    },
    createMerchantAccount() {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: null,
          actionType: 'create',
          table: 'merchant-account',
          objectType: 'merchant account',
          url: 'admin/accounts',
          successfullMessage: 'You successfully created merchant account',
        }
      })
    },
    editBankAccount(obj) {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'bank-account',
          objectType: 'bank account',
          url: 'admin/accounts/update/' + obj.id,
          successfullMessage: 'You successfully updated bank account',
        }
      })
    },
    deleteBankAccount(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'bank-account',
          type: 'account',
          url: 'admin/accounts/' + obj.id,
          successfullMessage: 'You successfully deleted account',
        }
      })
    },
    editMerchantAccount(obj) {
      this.$store.commit('setModal', {
        template: 'account',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'merchant-account',
          objectType: 'merchant account',
          url: 'admin/accounts/update/' + obj.id,
          successfullMessage: 'You successfully updated merchant account',
        }
      })
    },
    deleteMerchantAcccount(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'merchant-account',
          type: 'merchant',
          url: 'admin/accounts/' + obj.id,
          successfullMessage: 'You successfully deleted account',
        }
      })
    },
    // editTariffPackage(obj) {
    //   const item = this.tariffPackages.paginationData.data.find(el => +el.id === +obj.id)

    //   const invoice_file = item.invoice_file_path
    //     ? { name: item.invoice_file_path.substring(item.invoice_file_path.lastIndexOf('/') + 1) }
    //     : null

    //   this.$store.commit('setModal', {
    //     template: 'create-tariff-packages',
    //     options: {
    //       id: obj.id,
    //       form: {
    //         name: item.name,
    //         price: item.price,
    //         description: item.description,
    //         invoice_file: invoice_file
    //       }
    //     }
    //   })
    // },
    updateCurrentPage() {
      this.getBankAccounts();
      this.getMerchantAccounts();
      this.getWallets();

      // if (tableName == 'packages') {
      //   this.getPackages();
      // }
    },
    createWallet() {
      this.$store.commit('setModal', {
        template: 'create-wallet',
        options: {
          id: null,
          actionType: 'create',
          table: 'wallets',
          objectType: 'wallet',
          url: 'admin/accounts/wallets',
          successfullMessage: 'You successfully added wallet',
          fetchData: this.getWallets,
        }
      })
    },
    editWallet(obj) {
      this.$store.commit('setModal', {
        template: 'create-wallet',
        options: {
          id: obj.id,
          isEdit: true,
          actionType: 'edit',
          table: 'wallets',
          objectType: 'wallets',
          url: 'admin/accounts/wallets/' + obj.id,
          successfullMessage: 'You successfully updated wallet',
        }
      })
    },
    deleteWallet(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'wallets',
          type: 'wallet',
          url: 'admin/accounts/wallets/' + obj.id,
          successfullMessage: 'You successfully deleted wallet',
          fetchData: this.getWallets,
        }
      })
    },
  },
  watch: {
    '$store.state.deleteItemFromTableData': function (data) {
      this.updateCurrentPage()
    },
    '$store.state.editItemFromTableData': function (data) {
      this.updateCurrentPage()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>