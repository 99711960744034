<template>
  <overlay-scrollbars class="page page-offer shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Companies</span>
      </h2>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="pills">
          <div 
            v-for="(item, index) in pills.data" 
            :key="index" 
            class="pill"
            :class="{ 'pill_active': pills.active == item.id }" 
            @click="changePill(item.id)">{{ item.text }}
          </div>
          <div>
            <input 
              class="dark-input" 
              type="text" 
              placeholder="Type to search" 
              v-model="search"
              @change="getCompanies"
              @keyup.enter="getCompanies">
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="companies.body">
            <Column field="id" header="ID"></Column>
            <Column field="company_name" header="Company Name" sortable></Column>
            <Column field="user" header="Name" sortable>
              <template #body="{ data, field }">
                <div class="user-details-link" @click="gotoUserDetails(data[field].id)">
                  {{ data[field].full_name }}
                </div>
              </template>
            </Column>
            <Column>
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="openEditModal(data.id)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="openDocumentsModal(data.id)">
                    <RequestIcon />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="companies.paginationData" :data="companies.paginationData"
            @pagination-change-page="getCompanies">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="box-title">
          Company Incorporation Services
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="incorporationServiceRequests.body">
            <Column field="service_name" header="Service name" sortable></Column>
            <Column field="price" header="Price" sortable></Column>
            <Column field="user" header="Customer" sortable>
              <template #body="{ data, field }">
                <div class="user-details-link" @click="gotoUserDetails(data[field].id)">
                  {{ data[field].full_name }}
                </div>
              </template>
            </Column>
            <Column>
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="deleteIncorporationServiceRequest(data.id)">
                    <TableRemoveItemIcon />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="incorporationServiceRequests.paginationData" :data="incorporationServiceRequests.paginationData"
            @pagination-change-page="getIncorporationServiceRequests">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EditIcon from "@/components/icons/EditIcon.vue";
import RequestIcon from '@/components/img/RequestIcon.vue';
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
  name: 'Companies',
  components: {
    EditIcon,
    RequestIcon,
    TableRemoveItemIcon
  },
  data: () => ({
    pills: {
      data: [
        { id: 1, text: 'Standard companies' },
        { id: 2, text: 'Incorporation Companies' },
      ],
      active: 1,
    },
    companies: {
      body: [],
      paginationData: null,
    },
    incorporationServiceRequests: {
      body: [],
      paginationData: null,
    },
    bankAccountOffers: [],
    acquiringAccountOffers: [],
    cryptoAccountOffers: [],
    search: '',
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
      industries: state => state.industries,
      licenseTypes: state => state.licenseTypes,
    }),
  },
  created() {
    if(this.$route.params.id){
      this.search = this.$route.params.id
    }

    this.getCompanies();
    this.getIncorporationServiceRequests();
    this.setCountries();
    this.setIndustries();
    this.setLicenseTypes();
    this.getBankOffers()
    this.getAcquiringOffers()
    this.getCryptoOffers()
  },
  methods: {
    ...mapActions({
      setCountries: 'setCountries',
      setIndustries: 'setIndustries',
      setLicenseTypes: 'setLicenseTypes',
    }),
    changePill(id) {
      if(this.pills.active != id){
        this.pills.active = id;
        this.getCompanies();
      }
    },
    gotoUserDetails(id) {
      this.$router.push({ name: "CustomerDetail", params: { id: id } })
    },
    getCompanies(page = 1) {
      axios.get(`admin/user-companies?type_id=${this.pills.active}&search=${this.search}&page=${page}&order=desc`)
        .then(({ data }) => {
          if (!data) return false

          this.companies.body = []
          this.companies.paginationData = data
          this.companies.paginationData.data.forEach(el => {
            this.companies.body.push({
              id: el.id.toString(),
              company_name: el.name,
              user: { full_name: el.user?.full_name, id: el.user?.id }
            });
          })
        })
        .catch((errors) => console.dir(errors))
    },
    getIncorporationServiceRequests(page = 1){
      axios.get('admin/user-company/incorporation-service-requests?page=' + page)
        .then(({ data }) => {
          if (!data) return false

          this.incorporationServiceRequests.body = []
          this.incorporationServiceRequests.paginationData = data
          this.incorporationServiceRequests.paginationData.data.forEach(el => {
            this.incorporationServiceRequests.body.push({
              id: el.id,
              service_name: el.service_name,
              price: el.price,
              user: { full_name: el.user?.full_name, id: el.user?.id }
            });
          })
        })
        .catch((errors) => console.dir(errors))
    },
    getBankOffers(page = 1) {
      const basePath = 'admin/offers/bank-accounts?all=true'
    
      axios.get(basePath).then(res => {
        this.bankAccountOffers = []
        
        res.data.forEach(el => {
          this.bankAccountOffers.push({
            id: el.id,
            name: 'Offer ID - ' + el.id + ' | ' + el.financial_institution_name,
          })
        })
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    getAcquiringOffers(page = 1) {
      const basePath = 'admin/offers/merchant-accounts?all=true'

      axios.get(basePath).then(res => {
        this.acquiringAccountOffers = []
        
        res.data.forEach(el => {
          this.acquiringAccountOffers.push({
            id: el.id,
            name: 'Offer ID - ' + el.id + ' | ' + el.financial_institution_name,
          })
        })
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    getCryptoOffers(page = 1) {
      const basePath = 'admin/offers/e-wallets?all=true'

      axios.get(basePath).then(res => {
        this.cryptoAccountOffers = []
        
        res.data.forEach(el => {
          this.cryptoAccountOffers.push({
            id: el.id,
            name: 'Offer ID - ' + el.id + ' | ' + el.financial_institution_name,
          })
        })
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    openEditModal(id) {
      const data = this.companies.paginationData.data.find(el => el.id === +id);

      this.$store.commit('setModal', {
        template: 'edit-user-company',
        options: {
          company: data,
          countries: this.countries,
          industries: this.industries,
          licenseTypes: this.licenseTypes,
          fetchData: this.getCompanies,
          bankAccountOffers: this.bankAccountOffers,
          acquiringAccountOffers: this.acquiringAccountOffers,
          cryptoAccountOffers: this.cryptoAccountOffers,
        }
      })
    },
    openDocumentsModal(id) {
      const data = this.companies.paginationData.data.find(el => el.id === +id);

      this.$store.commit('setModal', {
        template: 'edit-user-company-documents',
        options: {
          company: data,
          fetchData: this.getCompanies,
        }
      })
    },
    deleteIncorporationServiceRequest(id){
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: id,
          actionType: 'delete',
          table: 'incorporation-service-requests',
          type: 'offer',
          url: 'admin/user-company/incorporation-service-request/' + id,
          successfullMessage: 'You successfully deleted request',
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.box-container {
  padding-bottom: 1.25rem;
}

.table-col-icon {
  margin-left: 20px;
}
</style>