<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">{{ isEdit ? 'Edit' : 'Create' }} Software Offer</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Name</div>
        <div class="data">
          <input type="text" name="name" class="inpt" v-model="form.name">
        </div>
      </div>
      <div class="row">
        <div class="label">Slug</div>
        <div class="data">
          <input type="text" name="slug" class="inpt" v-model="form.slug" :readonly="options.form && !!options.form.slug">
        </div>
      </div>
      <div class="row row__price">
        <div class="label">Price</div>
        <div class="data">
          <input type="text" name="price" class="inpt" v-model="form.price">
          <select v-if="currencies" v-model="form.currency_id">
            <option
              v-for="c in currencies" :key="c.id"
              :value="c.id"
              v-text="c.code"
            />
          </select>
        </div>
      </div>
      <div class="row row__options">
        <div class="label">Options</div>
        <div class="data" :key="sOptionsKey">
          <div v-for="(option, index) in sOptions" :key="index" class="option">
            <label class="option__checkbox">
              <input type="checkbox" name="options" v-model="form.options[option.id].is_select">
              {{ option.name }}
            </label>
            <label class="option__order">
              <span>Position:</span>
              <input type="number" step="1" v-model="form.options[option.id].order">
            </label>
          </div>
        </div>
      </div>
      <div class="row row__desc">
        <div class="label">Description</div>
        <quill-editor
          :options="editorOption"
          v-model="form.description"
        />
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    sOptionsKey: 0,
    sOptions: [],
    allCurrencies: null,
    form: {
      name: null,
      slug: null,
      price: null,
      currency_id: null,
      options: {},
      description: null,
    },
    editorOption: {
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{ 'color': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ]
      }
    },
    error: null
  }),
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    },
    currencies () {
      if (!this.allCurrencies) return []

      return this.allCurrencies.filter(el => el.code === 'EUR' || el.code === 'USD')
    }
  },
  created () {
    this.getCurrencies()
    this.getSoftwareOptions()
  },
  methods: {
    ...mapActions({
      createSoftwareOffer: 'software_net/createOffer',
      updateSoftwareOffer: 'software_net/updateOffer'
    }),
    getSelectedOptions () {
      axios.get('admin/software-offers/options/' + this.options.id)
        .then(({ data }) => {
          data.options.forEach(el => {
            this.form.options[el.software_option_id].is_select = el.is_select
            this.form.options[el.software_option_id].order = el.order
          })
          this.sOptionsKey++
        })
    },
    getSoftwareOptions () {
      axios.get('admin/software-offers/all-options')
        .then(({ data }) => {
          this.sOptions = data.options
          this.sOptions.forEach(el => {
            this.form.options[el.id] = { is_select: false, order: 99 }
          })
          this.updateForm()
        })
        .catch(err => console.log(err))
    },
    getCurrencies () {
      axios.get('admin/data/currencies')
        .then(({ data }) => {
          this.allCurrencies = data
        })
        .catch(err => console.dir(err))
    },
    updateForm () {
      if (!this.isEdit) return
      this.getSelectedOptions()
      this.form.name = this.options.form.name
      this.form.slug = this.options.form.slug
      this.form.price = this.options.form.price || 0
      this.form.currency_id = this.options.form.currency_id || 1
      this.form.description = this.options.form.description
    },
    async save () {
      if (this.isEdit) {
        await this.updateSoftwareOffer({ id: this.options.id, form: this.form })
          .then(res => this.close())
          .catch(error => console.log(error))
      } else {
        await this.createSoftwareOffer(this.form)
          .then(() => {
            for (const key in this.form) {
              if (Object.hasOwnProperty.call(this.form, key)) {
                this.form[key] = null
              }
            }
            this.close()
          })
          .catch(error => console.log(error))
      }
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 100%;
    max-height: 80vh;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    overflow-y: auto;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }

      &__options {
        .data {
          .option {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__order {
              span {
                padding-right: .75rem;
              }

              input {
                max-width: 3.5rem;
                text-align: center;
              }
            }
          }

          label {
            display: block;
          }
        }
      }

      &__desc {
        display: block;

        .label {
          padding-bottom: .25rem;
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}

::v-deep .quill-editor {
  .ql-container {
    min-height: 15rem;
    max-height: 30vh;
    overflow-y: auto;
  }
}
</style>
