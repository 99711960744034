<template>
	<div class="box-container" ref="legal_atlases_tbl">
      <div class="transparent-box box_b-info" style="width: 100%;">
        <div class="content" >
          <div class="block">
            <div class="hdr">
              <div class="ttl-wrapper">
                <div class="ttl title">Legal Atlases</div>
              </div>
            </div>
            <Table
              class="legal-atlases"
              :options="legalAtlasTableData" :key="legalAtlasTableKey"
              @change-page="getLegalServices"
              @sort="sortBy"
              @editItem="editLegalAtlas"
              @deleteItem="deleteLegalAtlas"
            />
          </div>
        </div>
      </div>
	</div>
</template>

<script>
import Table from '@/components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
	components: {
		Table,
		Pagination,
	},
	data () {
		return {
			initRequest: false
		}
	},
	props: {
	    provider: {
	      type: Object,
	      required: true
	    }
	  },
	data () {
		return {
			legalAtlasTableKey: 0,
			legalAtlasTableData: {
		      objName: 'legalAtlasTableData',
		      colsWidth: ['12%', '12%', '12%', '10%', '14%', '10%', '8%'],
		      header: [
		        { ttl: 'Name of Legal Co.', info: null, sort: 'legal_name' },
		        { ttl: 'Country'},
		        { ttl: 'Type of Services', info: null, sort: 'services_type' },
		        { ttl: 'Price', info: null, sort: 'price' },
		        { ttl: 'Description of Benefit', info: null },
		        { ttl: 'Service time', info: null, sort: 'service_time' },
		        { ttl: '', info: null },
		      ],
		      body: [],
		      paginationData: null,
		      sort: {
		        name: '',
		        order: 'asc',
		        loading: false
		      }
		    }
		}
	}, 
	methods: {
	    getLegalServices (page = 1) {
	      const that = this
	      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'admin/legal-atlas?page=' + page + '&provider_id=' + this.provider.id + '&per_page=5'
	      const sortParams = '&sort=' + this.legalAtlasTableData.sort.name + '&order=' + this.legalAtlasTableData.sort.order
	      axios.get(basePath + sortParams)
	        .then(function (response) {
	          that.legalAtlasTableData.paginationData = response.data
	          that.legalAtlasTableData.body = []
	          response.data.data.forEach(el => {
	            that.legalAtlasTableData.body.push([
	              el.legal_name,
	              el.country ? el.country.name : '',
	              el.services_type,
	              el.price,
	              el.benefit_description,
	              el.service_time,
	              { type: 'edit-delete-actions', id: el.id },
	            ])
	          })
	          if (that.legalAtlasTableData.sort.name) that.legalAtlasTableData.sort.loading = false
	        })
	        .catch((errors) => {
	          console.dir(errors)
	        })
	    }, 
	    sortBy (data) {
	      this[data.obj].sort.loading = true
	      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
	        ? 'desc' : 'asc'
	      this[data.obj].sort.name = data.sort
	      this.getLegalServices(1)
	    },
	    editLegalAtlas (obj) {
	      this.$store.commit('setModal', {
	        template: 'provider-legal-atlas',
	        options: {
	          id: obj.id,
	          actionType: 'edit',
	          table: 'legal-atlases',
	          objectType: 'legal atlas',
	          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/legal-atlas/update/' + obj.id + '/' + this.provider.id,
	          successfullMessage: 'You successfully updated legal atlas',
	        }
	      })
	    },
	    deleteLegalAtlas (obj) {
	      this.$store.commit('setModal', {
	        template: 'delete',
	        options: {
	          id: obj.id,
	          actionType: 'delete',
	          table: 'legal-atlases',
	          type: 'legal atlas',
	          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/legal-atlas/' + obj.id + '/' + this.provider.id,
	          successfullMessage: 'You successfully deleted legal atlas',
	        }
	      })
	    },
	    updateCurrentPage(tableName, actionType) {
	      let currentPage = 1
	      if (actionType == 'edit' || actionType == 'delete') {
	        try {
	        currentPage = document.getElementsByClassName(tableName)[0]
	          .getElementsByClassName('pagination')[0]
	          .getElementsByClassName('active')[0]
	          .getElementsByClassName('page-link')[0]
	          .innerText.trim().replace( /\D+/g, '')
	        } catch (e) {
	           console.log(e)
	        }
	      }
	      if (tableName == 'legal-atlases') {
	        this.getLegalServices(currentPage)
	      }
	    },
	},
	created () {
		if (this.provider && !this.initRequest) {
			this.getLegalServices()
			this.initRequest = true
		}
	},
	watch: {
		provider: function(provider) {
			if (this.provider && !this.initRequest) {
				this.getLegalServices()
				this.initRequest = true
			}
		},
		'$store.state.deleteItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    },
	    '$store.state.editItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    }
	},
}
</script>

<style>
.title {
	position: relative;
    padding-bottom: 0.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.91);
    margin-bottom: 10px;
}
</style>