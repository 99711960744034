<template>
  <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Compliance Service</span>
      </h2>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="btn-gradient" @click="createComplianceService">Create</div>
        <div class="btn-provider" @click="showProvidersServices = !showProvidersServices">
          <span class="btn-provider__arrow">◀</span>
          <span v-if="showProvidersServices">Provider</span>
          <span v-else>User</span>
          <span class="btn-provider__arrow">▶</span>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="complianceServiceTableData.body">
            <!-- // { type: 'user-details-delete-actions', id: el.id, userId: el.provider.id } -->
            <Column field="id" header="#"></Column>
            <Column field="name" header="Name" sortable></Column>
            <Column field="country" header="Country" sortable></Column>
            <Column field="services_type" header="Type of Services" sortable></Column>
            <Column field="price" header="Price" sortable></Column>
            <Column field="benefit_desc" header="Benefit Description" sortable></Column>
            <Column field="service_time" header="Service Time" sortable></Column>
            <Column v-if="showProvidersServices" field="provider" header="Provider">
              <template #body="{ data, field }">
                <div>
                  {{ data[field].member_login }}
                </div>
              </template>
            </Column>
            <Column v-else field="" header=""></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="editComplianceService(data)">
                    <EditIcon fill="#fff" />
                  </div>
                  <div class="table-col-icon" @click="deleteComplianceService(data)">
                    <TableRemoveItemIcon style="width: 30px; height: 30px" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <pagination v-if="complianceServiceTableData.paginationData" :data="complianceServiceTableData.paginationData"
            @pagination-change-page="getComplianceServices">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
    <!-- 
 
      <Table
        class="legal-atlases"
        :options="complianceServiceTableData" 
        @editItem="editComplianceService"
        @deleteItem="deleteComplianceService"
        @change-page="getComplianceServices"
      />
     
    </div> -->
  </overlay-scrollbars>
</template>

<script>
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
  name: 'ComplianceService',
  components: {
    EditIcon,
    TableRemoveItemIcon
  },
  data: () => ({
    complianceServiceTableData: {
      body: [],
      paginationData: null,
    },
    showProvidersServices: false,
  }),
  created() {
    this.getComplianceServices()
  },
  methods: {
    getComplianceServices(page = 1) {
      const basePath = 'admin/compliance-service?page=' + page
      const sortParams = '&providers=' + this.showProvidersServices + '&admin=' + true
      axios.get(basePath + sortParams)
        .then(response => {
          this.complianceServiceTableData.paginationData = response.data
          this.complianceServiceTableData.body = []

          response.data.data.forEach(el => {
            let complianceService = {
              id: el.id,
              name: el.name,
              country: el.country ? el.country.name : '',
              services_type: el.services_type,
              price: el.price,
              benefit_desc: el.benefit_description,
              service_time: el.service_time,
            }

            if (this.showProvidersServices) {
              complianceService.provider = {
                id: el.provider.id,
                member_login: (el.provider.member_login) ? el.provider.member_login : el.provider.email
              }
            }

            this.complianceServiceTableData.body.push(complianceService);
          })
        })
        .catch(errors => console.dir(errors))
    },
    createComplianceService() {
      this.$store.commit('setModal', {
        template: 'compliance-service',
        options: {
          id: null,
          actionType: 'create',
          table: 'compliance-services',
          objectType: 'compliance service',
          url: 'admin/compliance-service',
          successfullMessage: 'You successfully created compliance service',
        }
      })
    },
    editComplianceService(obj) {
      this.$store.commit('setModal', {
        template: 'compliance-service',
        options: {
          id: obj.id,
          actionType: 'edit',
          table: 'compliance-services',
          objectType: 'compliance service',
          url: 'admin/compliance-service/update/' + obj.id,
          successfullMessage: 'You successfully updated compliance service',
        }
      })
    },
    deleteComplianceService(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'compliance-services',
          type: 'compliance service',
          url: 'admin/compliance-service/' + obj.id,
          successfullMessage: 'You successfully deleted compliance service',
        }
      })
    },
    toElTop(el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    updateCurrentPage() {
      this.getComplianceServices()
    },
    // sortBy(data) {
    //   this[data.obj].sort.loading = true
    //   this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
    //     ? 'desc' : 'asc'
    //   this[data.obj].sort.name = data.sort
    //   this.getComplianceServices(1)
    // },
  },
  watch: {
    '$store.state.deleteItemFromTableData': function (data) {
      this.updateCurrentPage()
    },
    '$store.state.editItemFromTableData': function (data) {
      this.updateCurrentPage()
    },
    showProvidersServices: function (val) {
      this.updateCurrentPage();
    }
  }
}
</script>

<style lang="scss" scoped></style>