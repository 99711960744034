<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Add to balance internal deal
      </div>
    </div>
    <div class="modal__body">
      <overlay-scrollbars>
        <div class="form">
          <div class="form-group">
            <label for="total_amount">Total amount</label>
            <input @change="calculateBalance" type="number" name="balance_total_amount" id="balance_total_amount" v-model="form.balance_total_amount" placeholder="Total amount"/>
          </div>
          <div class="form-group">
            <label for="total_amount">Seller amount</label>
            <input @change="calculateBalance" type="number" name="balance_seller_amount" id="balance_seller_amount" v-model="form.balance_seller_amount" placeholder="Seller amount"/>
          </div>
          <div class="form-group">
            <label for="total_amount">Agent amount</label>
            <input @change="calculateBalance" type="number" name="balance_agent_amount" id="balance_agent_amount" v-model="form.balance_agent_amount" placeholder="Agent amount"/>
          </div>
          <div class="form-group">
            <label for="total_amount">Team amount</label>
            <input @change="calculateBalance" type="number" name="balance_team_amount" id="balance_team_amount" v-model="form.balance_team_amount" placeholder="Team amount"/>
          </div>
          <div class="form-group">
            <label for="total_amount">M&A income</label>
            <input type="number" name="balance_income_amount" id="balance_income_amount" v-model="form.balance_income_amount" placeholder="M&A income"/>
          </div>
          <!--<div class="form-group">
            <label for="balance">Balance</label>
            <input type="number" name="balance" id="balance"  v-model="form.balance" placeholder="Balance"/>
          </div>-->
          <div class="form-group">
            <label for="paid_balance">Paid balance</label>
            <input type="number" name="paid_balance" id="paid_balance"  v-model="form.paid_balance" placeholder="Paid balance"/>
          </div>
          <div class="form-group select">
            <label for="country_of_incorporation">Payment type</label>
            <div class="select-wrapper">
              <select id="balance_payment_type" name="balance_payment_type" v-model="form.balance_payment_type"  :key="paymentTypesKey">
                <option :value="null">Select type</option>
                <option 
                  v-for="(type, i) in paymentTypes" :key="type.value"
                  :selected="type.value == form.balance_payment_type"
                  :value="type.value"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="deadline_at">Income Deadline at</label>
            <input type="date" name="income_deadline_at" id="income_deadline_at"  v-model="form.income_deadline_at" placeholder="Income Deadline at"/>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="modal__footer">
      <div v-if="confirmDelete" class="confirm-delete">
        <h3>Are you sure you want delete this internal deal from balance</h3>
        <div class="options">
          <div class="btn btn__save" @click="deleteLead">Yes, delete</div>
          <div class="btn btn__delete" @click="confirmDelete = false">NO</div>
        </div>
      </div>
      <div v-else class="options">
        <div class="btn btn__save" @click="submit">Save data</div>
        <div class="btn btn__delete" @click="confirmDelete = true">Delete</div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close,
  },
  props: {
    options: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      paymentTypes: [
        {
          name: 'Fiat',
          value: 'fiat'
        },
        {
          name: 'Crypto',
          value: 'crypto'
        }
      ],
      form: {
        balance_total_amount: 0,
        balance_seller_amount: 0,
        balance_agent_amount: 0,
        balance_team_amount: 0,
        balance_income_amount: 0,
        balance_payment_type: '',
        balance: 0,
        paid_balance: 0,
        income_deadline_at: '',
      },
      paymentTypesKey: 0,
      error: null,
      confirmDelete: false,
    };
  },
  async created () {
    await this.getLead()
  },
  methods: {
    calculateBalance(){
      this.form.balance_team_amount = (this.form.balance_total_amount - this.form.balance_seller_amount - this.form.balance_agent_amount) / 100 * 10
    
      this.form.balance_income_amount = this.form.balance_total_amount - this.form.balance_seller_amount - this.form.balance_agent_amount - this.form.balance_team_amount
    },
    async getLead(){
      const that = this

      await axios.get('admin/leads/' + this.options.id).then(function (response) {
        that.form = response.data
        that.form.is_balanced = true
        that.form.lead_links_to_delete = []
      }).catch(function (error) {
        console.log(error);
      })
	  },
    async deleteLead() {
      this.form.is_balanced = false;
      this.form.balance_total_amount = 0;
      this.form.balance_seller_amount = 0;
      this.form.balance_agent_amount = 0;
      this.form.balance_team_amount = 0;
      this.form.balance_income_amount = 0;
      this.form.balance_payment_type = '';
      this.form.balance = 0;
      this.form.paid_balance = 0;
      this.form.income_deadline_at = '';
      
      await this.submit()
    },
    async submit(){
      let url = 'admin/leads'
      
      url += '/' + this.options.id

      await axios.post(url, this.form).then((response) => {
        this.close()

        this.processFiles(response.data.lead.id)

        this.$store.dispatch('editItemFromTable', {
          id: this.options.id,
          table: this.options.table,
          actionType: this.options.actionType,
        })
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__body {
    height: 68vh;
    padding-right: 2.5rem;
    overflow: hidden;

    .os-host {
      height: 100%;
      width: 100%;
    }
  }

  .form {
    width: 100%;
    padding-right: 1rem;

    &-group {
      --select_height: 46px;

      label {
        font-weight: 700;
        font-size: 20px;
        line-height: 2;
      }

      input,
      select,
      textarea {
        min-width: 100%;
        max-width: 100%;
        height: var(--select_height);
        padding: 12px 25px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 24px;
        outline: none;
      }
      textarea{
        height: 120px;
      }

      select {
        appearance: none;
        cursor: pointer;
        color: #333 !important;
      }
      .btn{
        color: #ffffff;
        font-size: 13px;
        padding: .25rem .75rem;
        background: var(--gr_new);
        border-radius: 6px;
        border: none;
      }
      .file{
        .btn{
          padding: 0;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  .select {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: calc(var(--select_height) / 2);
      right: 1.5rem;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      pointer-events: none;
    }
  }

  &__footer {
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    &__delete {
      background: var(--c_error);
    }
  }
}
.box_addFiles {
  width: 30%;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  display: flex;
  flex-direction: column;
  .ttl {
    padding-bottom: .25rem;
  }
  .separ {
    width: 3rem;
    height: .25rem;
    background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 14px;
    margin: .25rem 0;
  }
  .content {
    padding: 1rem;
    flex-grow: 1;
    border: 1px dashed #0071e3;
    box-sizing: border-box;
    filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .choose {
      text-align: center;
      .btn {
        padding: 0.5rem;
        background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
        border-radius: 6px;
        color: #fff;
        font-size: 13px;
      }
      .desc {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
    &_many {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.75rem 6px;
      .draggable {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
      }
      .file {
        position: relative;
        text-align: center;
        width: 4.5rem;
        max-width: 4.5rem;
        svg.img {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;
          fill: #0071e3;
          path{
            fill: #0071e3;
          }
        }
        .btn {
          &_del {
            position: absolute;
            top: 0;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: #B03636;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 40%;
              height: 40%;
            }
          }
          &_download {
            position: absolute;
            top: 1.7rem;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 90%;
              height: 90%;
            }
          }
        }
        .desc {
          font-size: 10px;
          color: #333333;
          word-break: break-word;
        }
      }
      .add {
        text-align: center;
        width: 2.5rem;
        max-width: 2.5rem;
        .btn {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
          display: flex;
          align-items: center;
          justify-content: center;
          svg.img {
            width: 45%;
            height: 45%;
            transform: rotate(45deg);
          }
        }
        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#risks {
  #risks>label {
    margin-bottom: 10px;
  }

  .form-check-input[type=radio] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
  }

  .risks-items{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .risk-check{
    min-width: 120px;
    &>label{
      display: block;
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    margin: 0;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #0071e3;
    border-color: #0071e3;
  }
}
</style>
