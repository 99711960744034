<template>
	<div>
		<Sidebar/>
	    <div class="container">
	      <Header/>
	      <!-- <div id="nav">
	        <router-link to="/">Home</router-link> |
	        <router-link to="/about">About</router-link>
	      </div> -->
	      <router-view/>
	    </div>
	    <Modal />
    </div>
</template>

<script>
import Sidebar from './../components/Sidebar.vue'
import Header from './../components/Header.vue'
import Modal from './../components/modals/Modal.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Sidebar,
    Header,
    Modal,
  }
}
</script>