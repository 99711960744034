<template>
	<overlay-scrollbars class="page page-bosBusiness" ref="os">
		<div class="box box_legalService">
	      <div class="content">
	        <div class="block block__legalService">
	          <div class="hdr">
	            <Bank2 class="img" /><div class="ttl">Busines offers</div>
	          </div>
	          <Table
	            class="providers-business-offers"
	            :options="businesOffers"
	            @editItem="editBusinessOffer"
                @deleteItem="deleteBusinessOffer"
	            @change-page="getBusinesOffers"
	            @sort="sortBy"
	          />
	        </div>
	      </div>
	    </div>
	</overlay-scrollbars>	
</template>

<script>
import Table from '@/components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'
import Bank2 from '@/components/img/Bank2.vue'

export default {
	components: {
		Table,
		Pagination,
		Bank2
	},
	data () {
		return {
			initRequest: false
		}
	},
	props: {
	    provider: {
	      type: Object,
	      required: true
	    }
	  },
	data () {
		return {
			businesOffersKey: 1,
		    businesOffers: {
		      objName: 'businesOffers',
		      colsWidth: ['10%', '23%', '22%', '18%', '34%', '17%', '14%', '0%'],
		      header: [  
		        { ttl: '#' },
		        { ttl: 'Type of Business', sort: 'business_type' },
		        { ttl: 'Type of License', sort: 'license_type' },
		        { ttl: 'Country', sort: 'country_name' },
		        { ttl: 'Description' },
		        { ttl: 'Price' },
		        { ttl: '', info: null },
		        { ttl: '', info: null },
		      ],
		      body: [],
		      paginationData: null,
		      sort: {
		        name: '',
		        order: 'asc',
		        loading: false
		      }
		    }
		}
	}, 
	methods: {
	    async getBusinesOffers (page = 1) {
		  const basePath = process.env.VUE_APP_BACKEND_API_URL + 'business-offers?page=' + page
	      const sortParams = '&sort=' + this.businesOffers.sort.name + '&order=' + this.businesOffers.sort.order + '&provider_id=' + this.provider.id + '&per_page=5'
	      axios.get(basePath + sortParams)
	        .then(({ data }) => {
	          this.businesOffers.paginationData = data
	          for (let i = 0; i < data.data.length; i++) {
	          	data.data[i][6] = { type: 'edit-delete-actions', id: data.data[i][0] }
	          	data.data[i][7] = {type: 'hidden-id', id: data.data[i][7] }
	          }
	          this.businesOffers.body = data.data
	          if (this.businesOffers.sort.name) this.businesOffers.sort.loading = false
	        })
	        .catch((errors) => {
	          console.dir(errors)
	        })
	    },
	    sortBy (data) {
	      this[data.obj].sort.loading = true
	      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
	        ? 'desc' : 'asc'
	      this[data.obj].sort.name = data.sort
	      this.getBusinesOffers(1)
	    },
	    editBusinessOffer (obj) {
	    	const item = this.businesOffers.paginationData.data.find(el => +el[0] === obj.id)
	    	this.$store.commit('setModal', {
		        template: 'provider-business-offer',
		        options: {
		          id: obj.id,
		          actionType: 'edit',
		          table: 'providers-business-offers',
		          type: 'providers business offers',
		          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/update/' + obj.id + '/' + this.provider.id,
		          successfullMessage: 'You successfully deleted business offer',
		          form: {
		            business_type: item[1],
		            license_type: item[2],
		            country: item[3],
		            description: item[4],
		            price: item[5],
		            country_id: item[7].id,
		          } 
		        }
		      }) 
	    }, 
	    deleteBusinessOffer (obj) {
	      this.$store.commit('setModal', {
	        template: 'delete',
	        options: {
	          id: obj.id,
	          actionType: 'delete',
	          table: 'providers-business-offers',
	          type: 'providers business offers',
	          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/business-offers/delete/' + obj.id + '/' + this.provider.id,
	          successfullMessage: 'You successfully deleted business offer',
	        }
	      }) 
	    },
	    updateCurrentPage(tableName, actionType) {
	      let currentPage = 1
	      if (actionType == 'edit' || actionType == 'delete') {
	        try {
	        currentPage = document.getElementsByClassName(tableName)[0]
	          .getElementsByClassName('pagination')[0]
	          .getElementsByClassName('active')[0]
	          .getElementsByClassName('page-link')[0]
	          .innerText.trim().replace( /\D+/g, '')
	        } catch (e) {
	           console.log(e)
	        }
	      }
	      if (tableName == 'providers-business-offers') {
	        this.getBusinesOffers(currentPage)
	      }
	    },
	},
	created () {
		if (this.provider && !this.initRequest) {
			this.getBusinesOffers()
			this.initRequest = true
		}
	},
	watch: {
		provider: function(provider) {
			if (this.provider && !this.initRequest) {
				this.getBusinesOffers()
				this.initRequest = true
			}
		},
		'$store.state.deleteItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    },
	    '$store.state.editItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    }
	}
}
</script>

<style scoped>
.page-bosBusiness .box_legalService .content .block .hdr svg.img {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
}

.page-bosBusiness .box_legalService .content .block .hdr {
    display: flex;
    align-items: center;
    margin-left: 0.75rem;
    margin-bottom: 1rem;
}

.page {
    padding: 0;
}

.page-bosBusiness .box_legalService .content .block .hdr svg {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
}
</style>