<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        {{ isEdit ? 'Edit' : 'Create' }} Meta
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group">
          <label>Name</label>
          <input v-model="name" type="name" class="form-control">
          <span v-show="formErrors.name" class="invalid-feedback">{{ formErrors.name }}</span>
        </div>
        <div class="form-group">
          <label>Property</label>
          <input v-model="property" type="property" class="form-control">
          <span v-show="formErrors.property" class="invalid-feedback">{{ formErrors.property }}</span>
        </div>
        <div class="form-group">
          <label>Value</label>
          <input v-model="value" type="name" class="form-control">
          <span v-show="formErrors.value" class="invalid-feedback">{{ formErrors.value }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      name: '',
      property: '',
      value: '',
      formErrors: {
          name: '',
          property: '',
          value: '',
      },
    };
  },
  created () {
    if (this.options.actionType == 'edit' ) {
      this.getMeta(this.options.id)
    }
  },
  methods: {
    getMeta () {
      const that = this
      if (this.options.table == 'meta-providers' ) {
        axios.get('admin/meta/providers/' + this.options.id).then(function (response) { 
          that.name = response.data.name
          that.property = response.data.property
          that.value = response.data.value
        })
        .catch((errors) => {
            console.dir(errors)
          })
      } else {
        axios.get('admin/meta/users/' + this.options.id).then(function (response) { 
          that.name = response.data.name
          that.property = response.data.property
          that.value = response.data.value
        })
        .catch((errors) => {
            console.dir(errors)
          })
      }
    },
    isEdit () {
      return this.options && this.options.actionType !== 'create'
    },
    clearErrors() {
      this.formErrors.name = ''
      this.formErrors.property = ''
      this.formErrors.value = ''
    },
    async submit() {
      const that = this
      this.clearErrors()
      let axiosObj = axios.post(this.options.url, {
        name: this.name,
        property: this.property,
        value: this.value
      })
      if (this.options.actionType == 'edit' ) {
        axiosObj = axios.put(this.options.url, {
          name: this.name,
          property: this.property,
          value: this.value
        })
      } 
      
      axiosObj.then(function (response) { 
        if (response.data.success) {
          that.close()
          that.$noty.success(that.options.successfullMessage)
          that.$store.dispatch('editItemFromTable', {
            id: that.options.id,
            table: that.options.table,
            actionType: that.options.actionType
          })
          that.name = ''
          that.property = ''
          that.value = ''
        }
        that.clearErrors()
        that.close()  
      })
      .catch(err => { 
          if (err.response.status == 422) {
            const errors = err.response.data.errors
            if (errors.name) {
              this.formErrors.name = errors.name[0]
            } else if (errors.property) {
              this.formErrors.property = errors.property[0]
            } else if (errors.value) {
              this.formErrors.value = errors.value[0]
            }
            console.log(err.response.data.errors)
          } else {
            console.log(err)
          }
        });
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  input {
    color: #333333;
  }
}
</style>
