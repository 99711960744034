export default ({
  namespaced: true,
  state: () => ({
    offersTableData: {
      colsWidth: ['5%', '85%', '10%'],
      body: [],
      paginationData: null
    },
    optionsTableData: {
      colsWidth: ['5%', '85%', '10%'],
      header: [
        { ttl: 'ID' },
        { ttl: 'Name' },
        { ttl: '' },
      ],
      body: [],
      paginationData: null
    },
    addServicesTableData: {
      colsWidth: [],
      header: [],
      body: [],
      paginationData: null
    },
    adminAddServices: {
      colsWidth: ['5%', '20%', '20%', '15%', '15%', '15%', '10%'],
      header: [
        { ttl: 'ID' },
        { ttl: 'Service Name' },
        { ttl: 'Country' },
        { ttl: 'Service Type' },
        { ttl: 'Price' },
        { ttl: 'Service Time' },
        { ttl: '' },
      ],
    },
    providerAddServices: {
      colsWidth: ['5%', '14%', '14%', '14%', '14%', '14%', '14%', '8%'],
      header: [
        { ttl: 'ID' },
        { ttl: 'Provider' },
        { ttl: 'Service Name' },
        { ttl: 'Country' },
        { ttl: 'Service Type' },
        { ttl: 'Price' },
        { ttl: 'Service Time' },
        { ttl: '' },
      ],
    },
  }),
  actions: {
    async getOffers({ commit }, payload = { page: 1 }) {
      await axios.get('admin/software-offers?page=' + payload.page)
        .then(({ data }) => {
          commit('setOffersTableData', { data: data });
        })
    },
    async createOffer({ dispatch }, payload) {
      await axios.post('admin/software-offers', payload)
        .then(res => {
          dispatch('getOffers')
        })
    },
    async updateOffer({ dispatch }, payload) {
      await axios.post(`admin/software-offers/update/${payload.id}`, payload.form)
        .then(res => {
          dispatch('getOffers')
        })
    },
    async getOptions({ commit }, payload = { page: 1 }) {
      await axios.get('admin/software-options?page=' + payload.page)
        .then(({ data }) => {
          commit('setOptionsTableData', { data: data });
        })
    },
    async createOption({ dispatch }, payload) {
      await axios.post('admin/software-options', payload)
        .then(res => {
          dispatch('getOptions')
        })
    },
    async updateOption({ dispatch }, payload) {
      await axios.post(`admin/software-options/update/${payload.id}`, payload.form)
        .then(res => {
          dispatch('getOptions')
        })
    },
    async getAddServices({ commit }, payload = { page: 1 }) {
      const providers = payload.providers ? '&providers=true' : ''
      await axios.get('admin/software-add-services?page=' + payload.page + providers)
        .then(({ data }) => {
          commit('setAddServicesTableData', { data: data, providers: payload.providers });
        })
    },
    async createAddService({ dispatch }, payload) {
      await axios.post('admin/software-add-services', payload)
        .then(res => {
          dispatch('getAddServices')
        })
    },
    async updateAddService({ dispatch }, payload) {
      await axios.post(`admin/software-add-services/update/${payload.id}`, payload.form)
        .then(res => {
          dispatch('getAddServices')
        })
    }
  },
  mutations: {
    setOffersTableData(state, payload) {
      state.offersTableData.paginationData = payload.data;
      state.offersTableData.body = []

      payload.data.data.forEach(el => {
        state.offersTableData.body.push({
          id: el[0],
          name: el[1],
          description: el[2],
          options: el[3],
          created_at: el[4],
          price: el[5],
          currency_id: el[6],
          slug: el[7]
        })
      });
    },
    setOptionsTableData(state, payload) {
      state.optionsTableData.paginationData = payload.data;
      state.optionsTableData.body = []
      payload.data.data.forEach(el => {
        state.optionsTableData.body.push({
          id: el[0],
          name: el[1],
        })
      });
    },
    setAddServicesTableData(state, payload) {
      state.addServicesTableData.paginationData = payload.data;
      state.addServicesTableData.body = []

      payload.data.data.forEach(el => {
        const name = !el.provider_id && !el.is_legal_company
          ? el.service_name
          : el.service_name + ' (Provided by Legal company)';

        const addService = {
          id: el.id,
          name: name,
          country: el.country ? el.country.name : '',
          service_type: el.service_type,
          price: el.price,
          service_time: el.service_time,
        }

        if (payload.providers && el.provider) {
          addService.provider = {
            type: 'v_link',
            html: el.provider.full_name || 'noname',
            link: { name: "CustomerDetail", params: { id: el.provider_id } }
          }
        }

        state.addServicesTableData.body.push(addService)
      });
    },
  }
})
