<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">{{ isEdit ? 'Edit' : 'Create' }} Tariff Package</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Name</div>
        <div class="data">
          <input type="text" class="inpt" v-model="form.name">
        </div>
      </div>
      <div class="row row__price">
        <div class="label">Price</div>
        <div class="data">
          <div
            v-for="(p, key) in form.price" :key="key"
            class="item"
          >
            <div class="inputBlock">
              <input type="number" v-model="p.value">
              <select v-if="allCurrencies" v-model="p.currency_id">
                <option
                  :value="p.currency_id"
                  v-text="allCurrencies.find(c => c.id === p.currency_id).code"
                />
                <option
                  v-for="c in currencies" :key="c.id"
                  :value="c.id"
                  v-text="c.code"
                />
              </select>
            </div>
            <div v-if="key" class="btn btn__remove" @click="removePrice(key)">&#10006;</div>
          </div>
          <div v-if="currencies.length" class="btn btn__newPrice" @click="addNewPrice">+</div>
        </div>
      </div>
      <div class="row">
        <div class="label">Invoice file</div>
        <div class="data">
          <input type="file" class="dn" ref="invoice_file" accept="application/pdf" @change="handleFileInvoiceChange">
          <div v-if="form.invoice_file" class="fileBox">
            <div class="filename">{{ form.invoice_file.name }}</div>
            <div class="btn btn__remove" @click="removeInvoiceFile">&#10006;</div>
          </div>
          <div v-else class="fileBox">
            <div @click="$refs.invoice_file.click()" class="btn btn__fileUpload">Upload file</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="label">Description</div>
        <div class="data">
          <textarea rows="5" class="inpt inpt__ta" v-model="form.description"/>
        </div>
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    allCurrencies: null,
    form: {
      name: null,
      price: [],
      description: null,
      invoice_file: null
    },
    error: null
  }),
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    },
    currencies () {
      if (!this.allCurrencies) return []

      let data = this.allCurrencies.filter(el => el.code !== 'Other')

      if (this.form.price.length) {
        const idsArr = this.form.price.map(el => el.currency_id)
        data = data.filter(el => !idsArr.includes(el.id))
      }

      return data
    }
  },
  created() {
    this.getCurrencies()
    this.updateForm()
  },
  methods: {
    ...mapActions({
      // createBusinessOffer: 'bos_business/createOffer',
      updateTariffPackage: 'tariff_packages/updateTariffPackage'
    }),
    getCurrencies () {
      axios.get('admin/data/currencies')
        .then(({ data }) => {
          this.allCurrencies = data
        })
        .catch(err => console.dir(err))
    },
    updateForm () {
      if (!this.isEdit) return

      this.form.id = this.options.id
      this.form.name = this.options.form.name
      this.form.price = this.options.form.price || []
      this.form.description = this.options.form.description
      this.form.invoice_file = this.options.form.invoice_file
    },
    handleFileInvoiceChange (ev) {
      if (ev.target.files.length) {
        this.form.invoice_file = ev.target.files[0]
      }
    },
    removeInvoiceFile () {
      this.$refs.invoice_file.value = null
      this.form.invoice_file = null
    },
    async save () {
      const formData = new FormData()
      if (this.form.invoice_file) {
        if (this.form.invoice_file.type !== 'application/pdf') {
          return alert('Please upload PDF file!')
        }
        formData.append('file', this.form.invoice_file)
      }
      formData.append('id', this.form.id)
      formData.append('name', this.form.name)
      formData.append('price', JSON.stringify(this.form.price))
      formData.append('description', this.form.description)

      if (this.isEdit) {
        await this.updateTariffPackage(formData)
          .then(res => this.close())
          .catch(error => console.log(error))
      } else {
        // await this.createBusinessOffer(this.form)
        //   .then(() => {
        //     for (const key in this.form) {
        //       if (Object.hasOwnProperty.call(this.form, key)) {
        //         this.form[key] = null
        //       }
        //     }
        //     this.close()
        //   })
        //   .catch(error => console.log(error))
      }
    },
    addNewPrice () {
      this.form.price.push({
        value: 0,
        currency_id: this.currencies.length ? this.currencies[0].id : null,
        tariff_package_id: this.options.id
      })
    },
    removePrice (key) {
      this.form.price.splice(key, 1)
    },
    close () {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }

        .fileBox {
          display: flex;
          align-items: center;

          .filename {
            padding-right: .25rem;
          }
        }
      }

      &__price {
        .data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .item {
            display: flex;
            align-items: center;
            margin-bottom: .25rem;

            .inputBlock {
              display: inline-flex;
              width: 15rem;
              height: 2.75rem;
              padding: .25rem;
              border: 1px solid #0071e3;
              border-radius: 6px;

              input {
                flex-grow: 1;
                min-width: 1rem;
                height: 100%;
                text-align: center;
                border: none;

                &:focus {
                  outline: none;
                }
              }

              select {
                height: 100%;
                margin-left: .25rem;
                padding: .25rem .5rem;
                color: #fff;
                background-color: #000;
                border-radius: .25rem;
              }
            }
          }
        }
      }

      .fileBox {
        .btn {
          display: inline-flex;
          line-height: 1;
          color: #fff;
          border-radius: .25rem;

          &__fileUpload {
            padding: 0.75rem 1rem 10px;
            text-transform: uppercase;
            background-color: var(--c_green);
          }
        }
      }
    }

    .btn {
      display: inline-flex;
      line-height: 1;
      color: #fff;
      border-radius: .25rem;

      &__remove {
        padding: .25rem .5rem;
        margin-left: .5rem;
        font-size: 1.25rem;
        background-color: var(--c_error);
      }

      &__newPrice {
        padding: .25rem .75rem;
        margin-top: .25rem;
        font-size: 2rem;
        background-color: var(--c_green);
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}
</style>
