<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Document templates
      </div>
    </div>
    <div class="modal__body">
      <overlay-scrollbars>
        <div class="form">
          <div class="form-group">
            <div class="additional-items_wrapper">
              <div v-for="(document, documentIndex) in document_templates" class="additional-item">
                <div class="additional-item__header">
                  <label :for="'document_' + documentIndex">
                    Document template № {{ documentIndex + 1 }}
                  </label>
                  <div class="delete-btn" @click="deleteDocumentTemplate(documentIndex)">
                    <Close class="img"/>
                  </div>
                </div>
                <div class="additional-item__body">
                  <div class="additional-item__col">
                    <input type="text" name="name" v-model="document.name" placeholder="Document template name">
                  </div>
                  <div class="additional-item__col">
                    <div class="form-group files">
                      <label>Files</label>
                      <div class="box box_addFiles">
                        <div v-if="getUploadedFiles('documents', document, documentIndex).length" class="content content_many">
                          <draggable v-model="document.upload.documents" tag="div" class="draggable">
                            <div v-for="(file, key) in getUploadedFiles('documents', document, documentIndex)" :key="key" class="file">
                              <BlueFile class="img" />
                              <div class="btn btn_del" @click="delFile('documents', key, file, document, documentIndex)">
                                <Close class="img" />
                              </div>
                              <div class="btn btn_download" 
                                v-if="file.id" 
                                @click.stop.prevent="downloadFile(file, 'documents', document, documentIndex)">
                                <BlueDownload class="img" />
                              </div>
                              <div class="desc">{{ file.name }}</div>
                            </div>
                          </draggable>
                          <div class="add">
                            <div class="btn" @click="$refs[documentIndex + '_documents'][document.upload.documents.length - 1].click()">
                              <Close class="img" />
                            </div>
                          </div>
                        </div>
                        <div v-else class="content">
                          <div class="choose">
                            <div class="btn" @click="$refs[documentIndex + '_documents'][0].click()">Documents</div>
                          </div>
                        </div>
                        <input
                          v-for="(el, key) in document.upload.documents"
                          :key="key"
                          type="file"
                          :name="`documents_${documentIndex}_${key}`"
                          class="hide"
                          :ref="documentIndex + '_documents'"
                          @change="addInput($event, documentIndex + '_documents', key, 'documents', document, documentIndex)"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="additional-items__add-wrap">
                <button class="btn" id="contact_links_btn" @click="addDocumentTemplate">
                  Add document template
                </button>
              </div>
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="modal__footer">
      <div class="options">
        <div class="btn btn__save" @click="saveAll">Save data</div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import draggable from 'vuedraggable'
import BlueFile from '../img/BlueFile.vue'
import BlueDownload from '../img/BlueDownload.vue'

export default {
  components: {
    draggable,
    Close,
    BlueFile,
    BlueDownload,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      document_templates: [],
      document_templates_to_delete: [],
    };
  },
  async created () {
    await this.getDocumentTemplates()
    await this.getAllDocuments();
  },
  computed: {
    isEdit () {
      return this.options.id && this.options.actionType == 'edit'
    }
  },
  methods: {
    addDocumentTemplate(){
      this.document_templates.push({
        name: null,
        type: this.options?.type ?? '',
        upload: {
          documents: [{name: null}]
        }
      })
    },
    async deleteDocumentTemplate(index){
      if(this.document_templates[index].hasOwnProperty('id')){
        let that = this
        
        await axios.delete('admin/document-templates/' + this.document_templates[index].id).then(function (response) {
          if(response.data.success){
            that.$noty.success('Document template was successfully deleted.')
          }
        }).catch(function (error) {
          console.log(error);
        })
      }

      this.document_templates.splice(index, 1)
    },
    async getAllDocuments(){
      this.document_templates.forEach((item, i) => {
        if(item.hasOwnProperty('id')){
          this.getDocuments(item.id, i)
        }
      });
    },
    async getDocuments(id, index) {
      const that = this
      
      if(id){
        await axios.get('admin/document-templates/' + id + '/get-file/documents')
        .then((response) => {
          let docsData = response.data

          if(docsData.length > 0){
            if (!that.document_templates[index].upload) {
                that.$set(that.document_templates[index], 'upload', { documents: [{ name: null }] });
            }

            that.document_templates[index].upload.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
      }
    },
    getUploadedFiles(el, document, documentIndex) {
      return this.document_templates[documentIndex].upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file, document, documentIndex) {
      if (this.document_templates[documentIndex].upload[name].length > 1) {
        this.document_templates[documentIndex].upload[name].splice(key, 1)
      } else {
        this.document_templates[documentIndex].upload[name][0].name = null
      }

      if(file.id && document.hasOwnProperty('id')){
        axios.post('admin/document-templates/' + document.id +'/delete-file', {
          collection: name,
          media_id: file.id,
        })
        .then(function () {
          
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key, collection, document, documentIndex) {
      if (this.$refs[name][key].files.length) {
        const file_name = this.$refs[name][key].files[0].name

        if (this.getUploadedFiles(collection, document, documentIndex).some(e => e.name == file_name)) {
          this.$noty.error('Error! A file with this name has already been added.')

          return false
        }

        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        if (!availableExtensionsArr.includes(extension.toLowerCase())) {
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          
          return false
        }

        if (this.$refs[name][key].files[0].size / 1024 / 1024 > 5) {
          this.$noty.error('File is too large. Maximum - 5MB.')
          
          return false
        }

        this.document_templates[documentIndex].upload[collection] = this.document_templates[documentIndex].upload[collection].filter(e => e.name !== null)
        
        if (this.$refs[name].length < 10) {
          this.document_templates[documentIndex].upload[collection].push({ name: file_name, file: event.target.files[0]})
        } else {
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type, document, documentIndex) {
      const id = document.id

      if(file.url){
        this.openFile(file.url)
      }
      else if(id){
        axios.post('admin/document-templates/' + id +'/get-file-link', {
          collection: type,
          media_id: file.id,
        })
        .then(function (response) {
          if (response.data) {
            const url = response.data

            var fileURL = response.data;

            this.openFile(fileUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      var fileLink = document.createElement('a');

      fileLink.href = url;
      fileLink.setAttribute('target', '_blank')
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async getDocumentTemplates(){
      const that = this
      const type = this.options?.type ?? ''

      await axios.get('admin/document-templates/' + type)
      .then(function (response) {
        that.document_templates = response.data

        for(let documentIndex = 0; documentIndex < that.document_templates.length; documentIndex++) {
          if(!that.document_templates[documentIndex].upload) {
            that.$set(that.document_templates[documentIndex], 'upload', { documents: [{ name: null }] });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      })
	  },
    async saveAll(){
      for(let documentIndex = 0; documentIndex < this.document_templates.length; documentIndex++) {
        await this.save(this.document_templates[documentIndex], documentIndex);
      }

      await this.getDocumentTemplates()
      await this.getAllDocuments()
    },
    async save(document, documentIndex){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/document-templates'

      if (document.hasOwnProperty('id')) {
        url += '/' + document.id
      }

      await axios.post(url, document)
      .then((response) => {
        this.processFiles(response.data.documentTemplate.id, document, documentIndex)
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    processFiles(id, document, documentIndex){
      let data = new FormData()
      const uploadKeys = Object.keys(this.document_templates[documentIndex].upload)

      for (let z = 0; z < uploadKeys.length; z++) {
        const key = uploadKeys[z]
        for (let i = 0; i < this.document_templates[documentIndex].upload[key].length; i++) {
          if (this.document_templates[documentIndex].upload[key][i].name !== null) {
            let f = {}
            if (this.document_templates[documentIndex].upload[key][i].type == 'server') {
              f = JSON.stringify(this.document_templates[documentIndex].upload[key][i])
            } else {
              data.append(key + '_names[' + i + ']', this.document_templates[documentIndex].upload[key][i].name)
              f = this.document_templates[documentIndex].upload[key][i].file
            }
            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/document-templates/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      })
      .catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__body {
    height: 68vh;
    padding-right: 2.5rem;
    overflow: hidden;

    .os-host {
      height: 100%;
      width: 100%;
    }
  }

  .form {
    width: 100%;
    padding-right: 1rem;

    &-group {
      --select_height: 46px;

      label {
        font-weight: 700;
        font-size: 20px;
        line-height: 2;
      }

      input,
      select,
      textarea {
        min-width: 100%;
        max-width: 100%;
        height: var(--select_height);
        padding: 12px 25px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 24px;
        outline: none;
      }
      textarea{
        height: 120px;
      }

      select {
        appearance: none;
        cursor: pointer;
        color: #333 !important;
      }
      .btn{
        color: #ffffff;
        font-size: 13px;
        padding: .25rem .75rem;
        background: var(--gr_new);
        border-radius: 6px;
        border: none;
      }
      .file{
        .btn{
          padding: 0;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  .select {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: calc(var(--select_height) / 2);
      right: 1.5rem;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      pointer-events: none;
    }
  }

  &__footer {
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    &__delete {
      background: var(--c_error);
    }
  }
}
.box_addFiles {
  width: 30%;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  display: flex;
  flex-direction: column;
  .ttl {
    padding-bottom: .25rem;
  }
  .separ {
    width: 3rem;
    height: .25rem;
    background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 14px;
    margin: .25rem 0;
  }
  .content {
    padding: 1rem;
    flex-grow: 1;
    border: 1px dashed #0071e3;
    box-sizing: border-box;
    filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .choose {
      text-align: center;
      .btn {
        padding: 0.5rem;
        background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
        border-radius: 6px;
        color: #fff;
        font-size: 13px;
      }
      .desc {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
    &_many {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.75rem 6px;
      .draggable {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
      }
      .file {
        position: relative;
        text-align: center;
        width: 4.5rem;
        max-width: 4.5rem;
        svg.img {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;
          fill: #0071e3;
          path{
            fill: #0071e3;
          }
        }
        .btn {
          &_del {
            position: absolute;
            top: 0;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: #B03636;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 40%;
              height: 40%;
            }
          }
          &_download {
            position: absolute;
            top: 1.7rem;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 90%;
              height: 90%;
            }
          }
        }
        .desc {
          font-size: 10px;
          color: #333333;
          word-break: break-word;
        }
      }
      .add {
        text-align: center;
        width: 2.5rem;
        max-width: 2.5rem;
        .btn {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
          display: flex;
          align-items: center;
          justify-content: center;
          svg.img {
            width: 45%;
            height: 45%;
            transform: rotate(45deg);
          }
        }
        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#risks {
  #risks>label {
    margin-bottom: 10px;
  }

  .form-check-input[type=radio] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
  }

  .risks-items{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .risk-check{
    min-width: 120px;
    &>label{
      display: block;
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    margin: 0;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #0071e3;
    border-color: #0071e3;
  }
}
</style>
