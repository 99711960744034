<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Task
      </div>
    </div>
    <div class="modal__body">
      <overlay-scrollbars>
        <div class="form">
          <div class="form-group">
            <label for="task">Task</label>
            <input type="text" name="task" id="task"  v-model="form.task" placeholder="Task"/>
          </div>
          <div class="form-group" v-if="$store.state.user.is_super_admin">
            <label for="client_number">Manager can see</label>
            <Tmblr 
              :active="form.is_manager_can" 
              :text="'Manager can see'" 
              customClass="dark"
              @changeState="form.is_manager_can = !form.is_manager_can" />
          </div>
          <div class="form-group">
            <label for="client_name">Customer name</label>
            <input type="text" name="client_name" id="client_name"  v-model="form.client_name" placeholder="Customer name"/>
          </div>
          <div class="form-group" v-if="$store.state.user.is_super_admin">
            <label for="tasked_at">Date of task</label>
            <input type="datetime-local" name="tasked_at" id="tasked_at"  v-model="form.tasked_at" placeholder="Date of task"/>
          </div>
          <div class="form-group">
            <label for="task_description">Task Description</label>
            <textarea name="task_description" id="task_description"  v-model="form.task_description" placeholder="Task Description"/>
          </div>
          <div class="form-group" v-if="form.id">
            <label>Task comments</label>
            <button class="btn" id="comments_btn" @click="showTaskComment">
              Show task comments
            </button>
          </div>
          <div class="form-group select">
            <label for="status">Priorities</label>
            <div class="select-wrapper">
              <select id="status" name="priority" v-model="form.priority">
                <option :value="null">Select priority</option>
                <option 
                  v-for="(priority, i) in priorities" 
                  :key="i"
                  :value="priority"
                >
                  {{ priority }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group select">
            <label for="status">Status</label>
            <div class="select-wrapper">
              <select id="status" name="status" v-model="form.status">
                <option :value="null">Select status</option>
                <option 
                  v-for="(status, i) in statuses" 
                  :key="i"
                  :value="status"
                >
                  {{ status }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="contact_links_btn">Task links</label>
            <div class="additional-items_wrapper">
              <div v-for="(link, i) in form.task_links" :key="i" class="additional-item">
                <div class="additional-item__header">
                  <label :for="'link_' + i">
                    Link № {{ i + 1 }}
                  </label>
                  <div class="delete-btn" @click="deleteTaskLink(i)">
                    <Close class="img"/>
                  </div>
                </div>
                <div class="additional-item__body">
                  <div class="additional-item__col col-50">
                    <input type="text" name="service_name" v-model="link.service_name" placeholder="Service name">
                  </div>
                  <div class="additional-item__col col-50">
                    <input :id="'link_' + i" type="text" name="link" v-model="link.link" placeholder="Link">
                  </div>
                </div>
              </div>
              <div class="additional-items__add-wrap">
                <button class="btn" id="contact_links_btn" @click="addTaskLink">
                  Add task link
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="managers_btn">Responsible managers</label>
            <div class="additional-items_wrapper">
              <div v-for="(responsibleManager, responsibleManagerIndex) in form.responsible_managers" :key="responsibleManagerIndex" class="additional-item">
                <div class="additional-item__header">
                  <label :for="'manager_' + responsibleManagerIndex">
                    Manager № {{ responsibleManagerIndex + 1 }}
                  </label>
                  <div class="delete-btn" @click="deleteResponsibleManager(responsibleManagerIndex)">
                    <Close class="img"/>
                  </div>
                </div>
                <div class="additional-item__body">
                  <div class="additional-item__col">
                    <select :id="'manager_' + responsibleManagerIndex" name="responsible_manager_name" class="inpt" v-model="responsibleManager.name">
                      <option :value="null">Select responsible manager</option>
                      <option 
                        v-for="(manager, i) in managers" 
                        :key="i"
                        :value="manager.name"
                      >
                        {{ manager.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="additional-items__add-wrap">
                <button class="btn" id="managers_btn" @click="addResponsibleManager">
                  Add responsible manager
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="managers_btn">Control managers</label>
            <div class="additional-items_wrapper">
              <div v-for="(controlManager, controlManagerIndex) in form.control_managers" :key="controlManagerIndex" class="additional-item">
              <div class="additional-item__header">
                <label :for="'manager_' + controlManagerIndex">
                Manager № {{ controlManagerIndex + 1 }}
                </label>
                <div class="delete-btn" @click="deleteControlManager(controlManagerIndex)">
                <Close class="img"/>
                </div>
              </div>
              <div class="additional-item__body">
                <div class="additional-item__col">
                <select :id="'manager_' + controlManagerIndex" name="control_manager_name" class="inpt" v-model="controlManager.name">
                  <option :value="null">Select control manager</option>
                  <option 
                  v-for="(manager, i) in managers" 
                  :key="i"
                  :value="manager.name"
                  >
                  {{ manager.name }}
                  </option>
                </select>
                </div>
              </div>
              </div>
              <div class="additional-items__add-wrap">
              <button class="btn" id="managers_btn" @click="addControlManager">
                Add control manager
              </button>
              </div>
            </div>
          </div>
          <div class="form-group select">
            <label for="country_of_incorporation">Assistant</label>
            <div class="select-wrapper">
              <select id="asystent_name" name="asystent_name" v-model="form.asystent_name">
                <option :value="null">Select assistant</option>
                <option 
                  v-for="(asystent, i) in asystents" 
                  :key="i"
                  :value="asystent.name"
                >
                  {{ asystent.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="$store.state.user.is_super_admin">
            <label for="deadline_at">Deadline at</label>
            <input type="datetime-local" name="deadline_at" id="deadline_at"  v-model="form.deadline_at" placeholder="Deadline at"/>
          </div>
          <div class="form-group" v-if="(form.status == 'Close Win' || form.status == 'Close Lost')">
            <label for="closed_at">Closed at</label>
            <input type="datetime-local" name="closed_at" id="closed_at"  v-model="form.closed_at" placeholder="Closed at"/>
          </div>
          <div class="form-group files">
            <label>Files</label>
            <div class="box box_addFiles">
              <div v-if="getUploadedFiles('documents').length" class="content content_many">
                <draggable v-model="upload.documents" tag="div" class="draggable">
                  <div v-for="(file, key) in getUploadedFiles('documents')" :key="key" class="file">
                    <BlueFile class="img" />
                    <div class="btn btn_del" @click="delFile('documents', key, file)"><Close class="img" /></div>
                    <div class="btn btn_download" v-if="file.id" v-on:click.stop.prevent="downloadFile(file, 'documents')">
                      <BlueDownload class="img" />
                    </div>
                    <div class="desc">{{ file.name }}</div>
                  </div>
                </draggable>
                <div class="add">
                  <div class="btn" @click="$refs.documents[upload.documents.length - 1].click()">
                    <Close class="img" />
                  </div>
                </div>
              </div>
              <div v-else class="content">
                <div class="choose">
                  <div class="btn" @click="$refs.documents[0].click()">Task Documents</div>
                </div>
              </div>
              <input
                v-for="(i, key) in upload.documents"
                :key="key"
                type="file"
                :name="`documents_${key}`"
                class="hide"
                ref="documents"
                @change="addInput($event, 'documents', key)"
              >
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="modal__footer">
      <div v-if="confirmDelete && isEdit" class="confirm-delete">
        <h3>Are you sure you want to delete the Task?</h3>
        <div class="options">
          <div class="btn btn__save" @click="deleteTask">Yes, delete</div>
          <div class="btn btn__delete" @click="confirmDelete = false">NO</div>
        </div>
      </div>
      <div v-else class="options">
        <div class="btn btn__save" @click="submit">Save data</div>
        <div class="btn btn__delete" @click="confirmDelete = true" v-if="isEdit">Delete</div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectSimple from '@/components/common/Select.vue'
import Close from './../img/Close.vue'
import draggable from 'vuedraggable'
import BlueFile from '../img/BlueFile.vue'
import BlueDownload from '../img/BlueDownload.vue'
import Tmblr from '@/components/elem/Tmblr'

export default {
  components: {
    draggable,
    SelectSimple,
    Close,
    BlueFile,
    BlueDownload,
    Tmblr
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countries: [],
      managers: [],
      asystents: [],
      statuses: [
        'Active',
        'In Progress',
        'On Hold',
        'Close Win',
        'Close Lost'
      ],
      priorities: [
        'High (1 hour)',
        'Middle (day)',
        'Low (week)',
      ],
      form: {
        task: null,
        client_name: null,
        tasked_at: null,
        task_description: null,
        status: null,
        is_manager_can: false,
        responsible_manager_name: null,
        control_manager_name: null,
        asystent_name: null,
        deadline_at: null,
        task_links: [],
        task_links_to_delete: [],
        responsible_managers: [],
        control_managers: [],
      },
      error: null,
      upload: {
        documents: [{ name: null }],
      },
      confirmDelete: false,
    };
  },
  async created () {
    if(this.isEdit){
      await this.getTask()
      await this.updateFormData()
      await this.getDocuments()
    }
    
    await this.getManagers()
    await this.getAsystents()
  },
  computed: {
    isEdit () {
      return this.options.id && this.options.actionType == 'edit'
    }
  },
  methods: {
    showTaskComment(){
      this.$store.commit('setModal', {
        template: 'task-comments',
        options: {
          form: this.form,
          id: this.form.id,
          actionType: 'show-comments',
          table: 'tasks',
          objectType: 'task',
        }
      })
    },
    updateFormData(){
      let arrResponsibleManagers = [];
      let arrControlManagers = [];
      
      if(this.form.responsible_manager_name){
        arrResponsibleManagers = this.form.responsible_manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      if(this.form.control_manager_name){
        arrControlManagers = this.form.control_manager_name.split('|').map(name => {
          return { name: name };
        });
      }
      
      this.$set(this.form, 'responsible_managers',  arrResponsibleManagers)
      this.$set(this.form, 'control_managers',  arrControlManagers)
    },
    async getDocuments(){
      const that = this
      const id = this.options.id
      
      if(id){
        await axios.get('admin/tasks/' + id + '/get-file/documents').then((response) => {
          let docsData = response.data

          if(docsData.length > 0) {
            that.upload.documents = docsData.map((el) => {
              return {
                id: el.id,
                uuid: el.uuid,
                name: el.name,
                type: 'server',
                url: el.original_url,
                order: el.order
              }
            })
          }
        }).catch((errors) => {
          console.dir(errors)
        })
      }
    },
    async getTask(){
      const that = this

      await axios.get('admin/tasks/' + this.options.id).then(function (response) {
        that.form = response.data
        that.form.task_links_to_delete = []
      }).catch(function (error) {
        console.log(error);
      })
	  },
    async getManagers () {
      await axios.get('admin/data/managers').then(({data}) => {
        this.managers = data
      }).catch(function (error) {
        console.log(error);
      })
	  },
    async getAsystents() {
      await axios.get('admin/data/asystents').then(({data}) => {
        this.asystents = data
      }).catch(function (error) {
        console.log(error);
      })
    },
    addTaskLink(){
      this.form.task_links.push({
        service_name: null,
        link: null
      })
    },
    deleteTaskLink(index){
      if(this.form.task_links[index].hasOwnProperty('id')){
        this.form.task_links_to_delete.push(this.form.task_links[index].id)
      }

      this.form.task_links.splice(index, 1)
    },
    addResponsibleManager(){
      this.form.responsible_managers.push({
        name: null
      })
    },
    deleteResponsibleManager(index){
      this.form.responsible_managers.splice(index, 1)
    },
    addControlManager(){
      this.form.control_managers.push({
        name: null
      })
    },
    deleteControlManager(index){
      this.form.control_managers.splice(index, 1)
    },
    getUploadedFiles(el){
      return this.upload[el].filter(e => e.name !== null)
    },
    delFile(name, key, file){
      if(this.upload[name].length > 1){
        this.upload[name].splice(key, 1)
      } else{
        this.upload[name][0].name = null
      }

      if(file.id && this.options.id){
        axios.post('admin/tasks/' + this.options.id +'/delete-file', {
          collection: name,
          media_id: file.id,
        }).then(function () { 

        }).catch(function (error) {
          console.log(error);
        })
      }
    },
    addInput(event, name, key) {
      if(this.$refs[name][key].files.length){
        const file_name = this.$refs[name][key].files[0].name
        const extension = this.$refs[name][key].files[0].name.split('.').pop()
        const availableExtensionsArr = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx', 'xlsx']
        const maxFileSize = 10
        const maxFilesCount = 10

        if(this.getUploadedFiles(name).some(e => e.name == file_name)){
          this.$noty.error('Error! A file with this name has already been added.')

          return false
        }

        if(!availableExtensionsArr.includes(extension.toLowerCase())){
          this.$noty.error('You can upload files only with these extensions: ' + availableExtensionsArr.join(', '))
          
          return false
        }

        if(this.$refs[name][key].files[0].size / 1024 / 1024 > maxFileSize){
          this.$noty.error('File is too large. Maximum - 10MB.')

          return false
        }

        this.upload[name] = this.upload[name].filter(e => e.name !== null)

        if(this.$refs[name].length < maxFilesCount){
          this.upload[name].push({ name: file_name, file: event.target.files[0]})
        } else{
          this.$noty.error('Maximum - 10 files')
        }
      }
    },
    downloadFile(file, type){
      const id = this.options.id

      if(file.url){
        this.openFile(file.url)
      } else if(id){
        axios.post('admin/tasks/' + id +'/get-file-link', {
          collection: type,
          media_id: file.id,
        }).then(function (response){
          if(response.data){
            let fileURL = response.data

            this.openFile(fileURL)
          }
        }).catch(function (error) {
          console.log(error);
        })
      }
    },
    openFile(url){
      let fileLink = document.createElement('a')

      fileLink.href = url
      fileLink.setAttribute('target', '_blank')

      document.body.appendChild(fileLink)

      fileLink.click()
    },
    async submit(){
      let url = 'admin/tasks'

      if(this.isEdit){
        url += '/' + this.options.id
      }

      this.form.responsible_manager_name = this.form.responsible_managers.map(obj => obj.name).join('|');
      this.form.control_manager_name = this.form.control_managers.map(obj => obj.name).join('|');

      await axios.post(url, this.form).then((response) => {
        this.close()

        this.processFiles(response.data.task.id)

        if(this.isEdit){
          this.$store.dispatch('editItemFromTable', {
            id: this.options.id,
            table: this.options.table,
            actionType: this.options.actionType,
          })
        } else{
          this.$store.dispatch('createItemInTable', {
            table: this.options.table,
            actionType: this.options.actionType,
          })
        }
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    async deleteTask() {
      axios.delete(`admin/tasks/${this.options.id}`).then(({ data }) => {
        if(data.success){
          this.$noty.success('Internal deals successfully deleted')

          this.$store.dispatch('deleteItemFromTable', {
            id: this.options.id,
            table: this.options.table,
            actionType: this.options.actionType,
          })
        } else{
          this.$noty.error('Internal deals was not deleted')
        }

        this.close()
      }).catch(error => console.log(error))
    },
    processFiles(id){
      let data = new FormData()

      const uploadKeys = Object.keys(this.upload)

      for(let z = 0; z < uploadKeys.length; z++){
        const key = uploadKeys[z]

        for(let i = 0; i < this.upload[key].length; i++){
          if(this.upload[key][i].name !== null){
            let f = {}

            if(this.upload[key][i].type == 'server'){
              f = JSON.stringify(this.upload[key][i])
            } else{
              data.append(key + '_names[' + i + ']', this.upload[key][i].name)
              f = this.upload[key][i].file
            }

            data.append(key + '[' + i + ']', f)
          }
        }
      }
      
      axios.post('admin/tasks/' + id + '/files', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((response) => {
        this.close()
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__body {
    height: 68vh;
    padding-right: 2.5rem;
    overflow: hidden;

    .os-host {
      height: 100%;
      width: 100%;
    }
  }

  .form {
    width: 100%;
    padding-right: 1rem;

    &-group {
      --select_height: 46px;

      label {
        font-weight: 700;
        font-size: 20px;
        line-height: 2;
      }

      input,
      select,
      textarea {
        min-width: 100%;
        max-width: 100%;
        height: var(--select_height);
        padding: 12px 25px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 24px;
        outline: none;
      }
      textarea{
        height: 120px;
      }

      select {
        appearance: none;
        cursor: pointer;
        color: #333 !important;
      }
      .btn{
        color: #ffffff;
        font-size: 13px;
        padding: .25rem .75rem;
        background: var(--gr_new);
        border-radius: 6px;
        border: none;
      }
      .file{
        .btn{
          padding: 0;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  .select {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: calc(var(--select_height) / 2);
      right: 1.5rem;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      pointer-events: none;
    }
  }

  &__footer {
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    &__delete {
      background: var(--c_error);
    }
  }
}
.box_addFiles {
  width: 30%;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  display: flex;
  flex-direction: column;
  .ttl {
    padding-bottom: .25rem;
  }
  .separ {
    width: 3rem;
    height: .25rem;
    background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 14px;
    margin: .25rem 0;
  }
  .content {
    padding: 1rem;
    flex-grow: 1;
    border: 1px dashed #0071e3;
    box-sizing: border-box;
    filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .choose {
      text-align: center;
      .btn {
        padding: 0.5rem;
        background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
        border-radius: 6px;
        color: #fff;
        font-size: 13px;
      }
      .desc {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
    &_many {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.75rem 6px;
      .draggable {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
      }
      .file {
        position: relative;
        text-align: center;
        width: 4.5rem;
        max-width: 4.5rem;
        svg.img {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;
          fill: #0071e3;
          path{
            fill: #0071e3;
          }
        }
        .btn {
          &_del {
            position: absolute;
            top: 0;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: #B03636;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 40%;
              height: 40%;
            }
          }
          &_download {
            position: absolute;
            top: 1.7rem;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 90%;
              height: 90%;
            }
          }
        }
        .desc {
          font-size: 10px;
          color: #333333;
          word-break: break-word;
        }
      }
      .add {
        text-align: center;
        width: 2.5rem;
        max-width: 2.5rem;
        .btn {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
          display: flex;
          align-items: center;
          justify-content: center;
          svg.img {
            width: 45%;
            height: 45%;
            transform: rotate(45deg);
          }
        }
        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#risks {
  #risks>label {
    margin-bottom: 10px;
  }

  .form-check-input[type=radio] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
  }

  .risks-items{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .risk-check{
    min-width: 120px;
    &>label{
      display: block;
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    margin: 0;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #0071e3;
    border-color: #0071e3;
  }
}
</style>
