import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import store from '../store' 
import Customer from '../views/Customer.vue'
import LegalProvider from '../views/LegalProvider.vue'
import Administrators from '../views/Administrators.vue'
import CustomerDetail from '../views/CustomerDetail.vue'
import Offer from '../views/Offer.vue'
import AppliedOffers from '../views/AppliedOffers.vue'
import Accounts from '../views/Accounts.vue'
import Request from '../views/Request.vue'
import LegalAtlas from '../views/LegalAtlas.vue'
import ComplianceService from '../views/ComplianceService.vue'
import PaymentAtlas from '../views/PaymentAtlas.vue'
import Notifications from '../views/Notifications.vue'
import MySettings from '../views/MySettings.vue'
import NeoBank from '../views/NeoBank'
import BuyOrSellBusiness from '../views/BuyOrSellBusiness'
import SoftwareNet from '../views/SoftwareNet'
import Chat from '../views/Chat.vue'
import AllChats from '../views/AllChats.vue'
import Meta from '../views/Meta.vue'
import Industries from '../views/Industries.vue'
import AdminRequests from '../views/AdminRequests.vue'
import Companies from '../views/Companies.vue'
import PaidRequests from '../views/PaidRequests.vue'
import Balance from '../views/Balance.vue'
import BanksSugestions from '../views/BanksSugestions.vue'
import Information from '../views/Information.vue'
import Leads from '../views/Leads.vue'
import Tasks from '../views/Tasks.vue'
import AccountRequests from '../views/AccountRequests.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Customer',
    component: Customer,
    meta: {
        requiresAuth: true,
        headerName: 'Customer'
      }
  },
  {
    path: '/legal-providers',
    name: 'LegalProvider',
    component: LegalProvider,
    meta: {
        requiresAuth: true,
        headerName: 'Legal Provider'
      }
  },
  {
    path: '/',
    name: 'Admins',
    component: Administrators,
    meta: {
        requiresAuth: true,
        headerName: 'Administrators'
      }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/customer-detail/:id',
    name: 'CustomerDetail',
    component: CustomerDetail,
    meta: {
      requiresAuth: true ,
      headerName: 'Customer',
      // breadcrumbs: [
      //   {
      //     type: 'link',
      //     text: 'Customer',
      //     routeName: 'Customer'
      //   },
      //   {
      //     text: 'Customer Details'
      //   }
      // ]
    }
  },
  {
    path: '/offer',
    name: 'Offer',
    component: Offer,
    meta: {
        requiresAuth: true,
        headerName: 'Offer configurations'
      }
  },
  {
    path: '/applied-offer',
    name: 'Applied Offers',
    component: AppliedOffers,
    meta: {
        requiresAuth: true,
        headerName: 'Applied Offers'
      }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: {
        requiresAuth: true,
        headerName: 'Accounts'
      }
  },
  {
    path: '/request',
    name: 'Request',
    component: Request,
    meta: {
        requiresAuth: true,
        headerName: 'Request'
      }
  },
  {
    path: '/legal-atlas',
    name: 'LegalAtlas',
    component: LegalAtlas,
    meta: {
        requiresAuth: true,
        headerName: 'Legal Net'
      }
  },
  {
    path: '/compliance-services',
    name: 'ComplianceService',
    component: ComplianceService,
    meta: {
        requiresAuth: true,
        headerName: 'Compliance Service'
      }
  },
  {
    path: '/payment-atlas',
    name: 'PaymentAtlas',
    component: PaymentAtlas,
    meta: {
        requiresAuth: true,
        headerName: 'Payment Atlas'
      }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
        requiresAuth: true,
        headerName: 'Notifications'
      }
  },
  {
    path: '/my-settings',
    name: 'MySettings',
    component: MySettings,
    meta: {
        requiresAuth: true,
        headerName: 'My Settings'
      }
  },
  {
    path: '/neobank',
    name: 'NeoBank',
    component: NeoBank,
    meta: {
        requiresAuth: true,
        headerName: 'NEO Bank'
      }
  },
  {
    path: '/bos_business',
    name: 'BuyOrSellBusiness',
    component: BuyOrSellBusiness,
    meta: {
      requiresAuth: true,
      headerName: 'Buy & Sell NET License'
    }
  },
  {
    path: '/software_net',
    name: 'SoftwareNet',
    component: SoftwareNet,
    meta: {
      requiresAuth: true,
      headerName: 'Software Net'
    }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    meta: {
      requiresAuth: true,
      desc: 'Chat',
      isChat: true
    }
  },
  {
    path: '/all_chats',
    name: 'AllChats',
    component: AllChats,
    meta: {
      requiresAuth: true,
      headerName: 'All chats'
    }
  },
  {
    path: '/meta',
    name: 'Meta',
    component: Meta,
    meta: {
      requiresAuth: true,
      headerName: 'Meta'
    }
  },
  {
    path: '/industries',
    name: 'Industries',
    component: Industries,
    meta: {
      requiresAuth: true,
      headerName: 'Meta'
    }
  },
  {
    path: '/requests/:id',
    name: 'Requests',
    component: AdminRequests,
    meta: {
      requiresAuth: true,
      headerName: 'Requests'
    }
  },
  {
    path: '/companies/:id?',
    name: 'Companies',
    component: Companies,
    meta: {
        requiresAuth: true,
        headerName: 'Companies'
      }
  },
  {
    path: '/paid-requests',
    name: 'PaidRequests',
    component: PaidRequests,
    meta: {
      requiresAuth: true,
      headerName: 'Paid requests'
    }
  },
  {
    path: '/balance',
    name: 'Balance',
    component: Balance,
    meta: {
      requiresAuth: true,
      requiredIsSuperAdmin: true,
      headerName: 'Balance'
    }
  },
  {
    path: '/internal-deals',
    name: 'Leads',
    component: Leads,
    meta: {
      requiresAuth: true,
      headerName: 'Internal Deals'
    }
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    meta: {
      requiresAuth: true,
      headerName: 'Tasks'
    }
  },
  {
    path: '/account-requests',
    name: 'AccountRequests',
    component: AccountRequests,
    meta: {
      requiresAuth: true,
      headerName: 'Account Requests'
    }
  },
  {
    path: '/banks-sugestions',
    name: 'BanksSugestions',
    component: BanksSugestions,
    meta: {
      requiresAuth: true,
      headerName: 'Banks Sugestions'
    }
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: {
      requiresAuth: true,
      headerName: 'Information'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredIsSuperAdmin)) {
    if (!store.state.user.is_super_admin) {
      next({ name: 'Leads' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
