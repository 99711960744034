
import Vue from 'vue'

export default ({
  namespaced: true,
  state () {
  	return {
      viewUpdateProfileFields: null,
      viewUpdatedBusinessInformationFields: null,
      viewUpdatedLogo: null,
  	  updateRequest: null,
      applyOffer: null,
      cancelOffer: null,
      updateCompliance: null,
      gotoAllNotifications: false,
      notifications: [],
      totalUnreadCount: 0,
    }
  },
  getters: {
    unreadCount: (state) => {
      return state.totalUnreadCount
    },
    notifications: state => state.notifications,
    viewUpdateProfileFields: state => state.viewUpdateProfileFields,
    viewUpdatedBusinessInformationFields: state => state.viewUpdatedBusinessInformationFields,
    viewUpdatedLogo: state => state.viewUpdatedLogo,
    updateRequest: state => state.updateRequest,
    applyOffer: state => state.applyOffer,
    cancelOffer: state => state.cancelOffer,
    updateCompliance: state => state.updateCompliance,
    gotoAllNotifications: state => state.gotoAllNotifications,
  },
  actions: {
  	updateTotalUnreadCountNotifications ({ commit }, payload) {
      commit('updateTotalUnreadCountNotifications', payload)
    },
    clearNotifications ({ commit }) {
      commit('clearNotifications')
    },
    viewUpdateProfileFields ({ commit }, payload) {
      commit('viewUpdateProfileFields', payload)
    },
    viewUpdatedBusinessInformationFields ({ commit }, payload) {
      commit('viewUpdatedBusinessInformationFields', payload)
    },
    viewUpdatedLogo ({ commit }, payload) {
      commit('viewUpdatedLogo', payload)
    },
    updateRequest ({ commit }, payload) {
      commit('updateRequest', payload)
    },
    applyOffer ({ commit }, payload) {
      commit('applyOffer', payload)
    },
    cancelOffer ({ commit }, payload) {
      commit('cancelOffer', payload)
    },
    updateCompliance ({ commit }, payload) {
      commit('updateCompliance', payload)
    },
    gotoAllNotifications ({ commit }, payload) {
      commit('gotoAllNotifications', payload)
    },
    clearGotoAllNotifications ({ commit }, payload) {
      commit('clearGotoAllNotifications', payload)
    },
  },
  mutations: {
    viewNotification (state, ids) {
      const notifications = state.notifications
        .filter(notifications => ids.includes(notifications.id))
        .map(notification => {
          notification.reat_at = '1'
          return notification
        })
    },
    clearNotifications (state) {
      state.notifications = []
      state.totalUnreadCount = 0
    },
    updateTotalUnreadCountNotifications: (state, count) => {
      Vue.set(state, 'totalUnreadCount', count)
    },
    viewUpdateProfileFields: (state, notification) => {
      state.viewUpdateProfileFields = notification
    },
    viewUpdatedBusinessInformationFields: (state, notification) => {
      state.viewUpdatedBusinessInformationFields = notification
    },
    viewUpdatedLogo: (state, notification) => {
      state.viewUpdatedLogo = notification
    },
    updateRequest: (state, notification) => {
      state.updateRequest = notification
    },
    applyOffer: (state, notification) => {
      state.applyOffer = notification
    },
    cancelOffer: (state, notification) => {
      state.cancelOffer = notification
    },
    updateCompliance: (state, notification) => {
      state.updateCompliance = notification
    },
    gotoAllNotifications: (state, notification) => {
      state.gotoAllNotifications = true
    },
    clearGotoAllNotifications: (state, notification) => {
      state.gotoAllNotifications = false
    },
  },
})
