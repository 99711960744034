<template>
  <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Companies Requests</span>
      </h2>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="requestsTableData.body">
            <Column field="user_name" header="User">
              <template #body="{ data, field }">
                <router-link :to="{name: 'CustomerDetail', params: {id: data['id']}}">{{ data[field] }}</router-link>
              </template>
            </Column>
            <Column field="company_name" header="Company" sortable>
              <template #body="{ data, field }">
                <router-link :to="{name: 'Companies', params: {id: data['company_id']}}">{{ data['company_name'] }}</router-link>
              </template>
            </Column>
            <Column field="account_types" header="Bank account types" sortable></Column>
            <Column field="ba_minimum_ammount" header="Bank minimum ammount" sortable></Column>
            <Column field="ba_maximum_ammount" header="Bank maximum ammount" sortable></Column>
            <Column field="ba_payment_purpose" header="Bank purpose of payment" sortable></Column>
            <Column field="ba_payment_method" header="Bank payment method" sortable></Column>
            <Column field="ba_account_currencies_text" header="Bank currencies" sortable></Column>
            <!-- <Column field="incoming_fee" header="Bank incoming fee" sortable></Column> -->
            <!-- <Column field="outgoing_fee" header="Bank outgoing fee" sortable></Column> -->
            <!-- <Column field="ba_recall_reason" header="Recall reason" sortable></Column> -->
            <Column field="ma_minimum_ammount" header="Merchant minimum ammount" sortable></Column>
            <Column field="ma_maximum_ammount" header="Merchant maximum ammount" sortable></Column>
            <Column field="ma_incoming_fee" header="Merchant incoming fee" sortable></Column>
            <Column field="ma_outgoing_fee" header="Merchant outgoing fee" sortable></Column>
            <Column field="ma_mc_code_type" header="Merchant MC code" sortable></Column>
            <Column field="ma_chargeback_reason" header="Merchant chargeback rate" sortable></Column>
            <Column field="ma_card_types_text" header="Merchant type of cards" sortable></Column>
            <Column field="ma_processing_currencies_text" header="Merchant processing currencies" sortable></Column>
            <Column field="ca_minimum_ammount" header="Crypto account minimum ammount" sortable></Column>
            <Column field="ca_maximum_ammount" header="Crypto account maximum ammount" sortable></Column>
            <Column field="ca_payment_purpose" header="Crypto account exchange rate" sortable></Column>
            <Column field="ca_payment_method" header="Crypto account payment method" sortable></Column>
            <Column field="ca_account_currencies_text" header="Crypto account currencies" sortable></Column>
            <Column field="aa_minimum_ammount" header="Alternative account minimum ammount" sortable></Column>
            <Column field="aa_maximum_ammount" header="Alternative account maximum ammount" sortable></Column>
            <Column field="aa_incoming_fee" header="Alternative account incoming fee" sortable></Column>
            <Column field="aa_outgoing_fee" header="Alternative account outgoing fee" sortable></Column>
            <Column field="aa_country" header="Alternative account country" sortable>
              <template #body="{ data, field }">
                {{ data[field].name ? data[field].name : '' }}
              </template>
            </Column>
            <Column field="aa_chargeback_reason" header="Alternative account chargeback reason" sortable></Column>
            <Column field="aa_processing_currencies_text" header="Alternative account processing currencies" sortable></Column>
          </DataTable>
          <pagination v-if="requestsTableData.paginationData" :data="requestsTableData.paginationData"
            @pagination-change-page="getRequests">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>

    <!-- <Table :key="requestsTableKey" :options="requestsTableData" @change-page="getRequests"
      @scroll-top="toElTop" /> -->
  </overlay-scrollbars>
</template>

<script>

export default {
  components: {
   
  },
  data: () => ({
    requestsTableData: {
      body: [],
      paginationData: null,
    },
  }),
  created() {
    this.getRequests()
  },
  methods: {
    getRequests(page = 1) {
      axios.get('admin/request?page=' + page).then(response => {
        this.requestsTableData.paginationData = response.data.payment_settings
        this.requestsTableData.body = [];

        response.data.payment_settings.data.forEach(el => {
          const requestObj = {
            id: el.user.id,
            user_name: el.user.full_name,
            ba_payment_method: el.ba_payment_method,
            ba_minimum_ammount: el.ba_minimum_ammount?.toString(),
            ba_maximum_ammount: el.ba_maximum_ammount?.toString(),
            ba_payment_purpose: el.ba_payment_purpose,
            ma_incoming_fee: el.ma_incoming_fee?.toString(),
            ba_recall_reason: el.ba_recall_reason,
            //ba_account_currencies_text: el.ba_account_currencies_text,
            ba_account_currencies_text: this.objectToString(el.bank_account_currencies, 'code'),
            //ma_card_types_text: el.ma_card_types_text,
            ma_card_types_text: this.objectToString(el.acquiring_account_card_types),
            ma_minimum_ammount: el.ma_minimum_ammount?.toString(),
            ma_maximum_ammount: el.ma_maximum_ammount?.toString(),
            ma_mc_code_type: el.ma_mc_code_type,
            ma_incoming_fee: el.ma_incoming_fee?.toString(),
            ma_outgoing_fee: el.ma_outgoing_fee?.toString(),
            ma_chargeback_reason: el.ma_chargeback_reason,
            //ma_processing_currencies_text: el.ma_processing_currencies_text,
            ma_processing_currencies_text: this.objectToString(el.acquiring_account_currencies, 'code'),
            aa_minimum_ammount: el.aa_minimum_ammount?.toString(),
            aa_maximum_ammount: el.aa_maximum_ammount?.toString(),
            aa_incoming_fee: el.aa_incoming_fee?.toString(),
            aa_outgoing_fee: el.aa_outgoing_fee?.toString(),
            aa_chargeback_reason: el.aa_chargeback_reason,
            aa_country: el.alternative_account_country ?? {name: ''},
            //aa_processing_currencies_text: el.aa_processing_currencies_text,
            aa_processing_currencies_text: this.objectToString(el.alternative_account_currencies, 'code'),
            ca_minimum_ammount: el.ca_minimum_ammount,
            ca_maximum_ammount: el.ca_maximum_ammount,
            ca_payment_purpose: el.ca_payment_purpose,
            //ca_account_currencies_text: el.ca_account_currencies_text,
            ca_account_currencies_text: this.objectToString(el.crypto_account_currencies, 'code'),
            ca_payment_method: el.ca_payment_method,
            account_types: el.account_types,
            company: el.company,
            company_name: el.company?.name ?? '',
            company_id: el.company_id
          }

          this.requestsTableData.body.push(requestObj)
        })
      }).catch((errors) => {
        console.dir(errors)
      })
    },
    objectToString(arr, property = 'name'){
      return arr.map(obj => obj[property]).join(',');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>