<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Edit mark
      </div>
    </div>
    <div class="modal__body">
      <div class="row">
        <label>Rating</label>
        <input v-model="rating" type="text" class="inpt">
      </div>
      <div class="row">
        <label>Color</label>
        <div class="inpt inpt__color">
          <compact-picker v-model="ratingColor" :palette="ratingPallete"/>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import { Compact } from 'vue-color'

export default {
  components: {
    Close,
    'compact-picker': Compact,
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    rating: null,
    ratingColor: {
      hex: '#ffffff'
    },
    ratingPallete: []
  }),
  created () {
    this.getRatingColors()
    this.setData()
  },
  methods: {
    async getRatingColors () {
      await axios.get('admin/data/rating-colors')
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            this.ratingPallete.push(response.data[i].code)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    setData () {
      this.rating = this.options.rating || null
      this.ratingColor.hex = this.options.rating_color || this.ratingColor.hex
    },
    async save () {
      const postData = {
        rating: +this.rating,
        rating_color: this.ratingColor.hex
      }
      await axios.post(`admin/feedback/${this.options.id}`, postData)
        .then(response => {
          this.close()
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    close() {
      this.$emit("close");
    },
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    max-width: 30rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 2rem;
  }

  &__body {
    flex-direction: column;
    align-items: flex-start;

    .row {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;

      label {
        min-width: 5rem;
        padding: .25rem .75rem .25rem .25rem;
        font-weight: bold;
      }

      .inpt {
        border-width: 2px;
        color: #000000;

        &__color {
          height: auto;
          padding: .25rem .5rem;

          ::v-deep .vc-compact {
            box-shadow: none;
            background-color: transparent;
          }
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}
</style>
