<template>
    <overlay-scrollbars class="page page-offers shapes-bg" ref="os">
        <div class="section-title-wrap">
            <h2 class="page-details__title section-title">
                <span class="bc-first">Information</span>
            </h2>
        </div>

        <h2 class="section-title">Documents</h2>
        <div class="documents-form">
            <InputText v-model="documentName" placeholder="Document name" />

            <div class="form-files">
                <div class="drag-box" :class="{ '-active': $refs.upload && $refs.upload.dropActive }">
                    <label for="file" class="btn select-files" />
                    <file-upload :extensions="extensions" :multiple="false" :drop="true" v-model="files" ref="upload" />

                    <div class="drag-box__title">Drag your item or select files to upload</div>
                    <div class="drag-box__subtitle">PNG, jPG, JPEG or PDF. Maximum file size 100 Mb.</div>
                </div>
            </div>

            <div class="btn-gradient btn-document" @click="submit">Add document</div>
        </div>

        <div class="main-table">
            <DataTable :value="documents" :loading="loading">
                <Column field="title" header="Title"></Column>
                <Column field="file" header="File"></Column>
                <Column header="">
                    <template #body="{ data }">
                        <div class="col-dark">
                            <!-- <div class="table-col-icon" @click="editEWallet(data.id)">
                    <EditIcon fill="var(--c_black)" />
                  </div> -->
                            <div class="table-col-icon" @click="deleteDocument(data.id)">
                                <TableRemoveItemIcon style="width: 30px; height: 30px" />
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
        <h2 class="section-title">Notifications content</h2>

        <div class="notifications-content-form">
            <InputText v-model="notification.type" placeholder="Notification type" />
            <InputText v-model="notification.text" placeholder="Notification text" />
            <Dropdown v-model="notification.route" :options="routes" placeholder="Notification page" />
            <div class="btn-gradient" @click="newNotificationContent">Submit</div>
        </div>

        <div class="main-table">
            <DataTable :value="notificationContents" :loading="loading">
                <Column field="type" header="Type"></Column>
                <Column field="text" header="Text"></Column>
                <Column field="route" header="Route"></Column>
                <Column header="">
                    <template #body="{ data }">
                        <div class="col-dark">
                            <div class="table-col-icon" @click="deleteNotificationContent(data.id)">
                                <TableRemoveItemIcon style="width: 30px; height: 30px" />
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>

        <h2 class="section-title">Proposals congratulations modal content</h2>
        <div class="notifications-content-form">
            <InputText v-model="proposalsPaidModal.title" placeholder="Title" />
            <div class="oportunity-input">
                <InputText v-model="proposalsPaidModal.oportunity" placeholder="Oportunity" />
                <div class="btn-gradient" @click="addOportunity">Add</div>
            </div>
            <ul class="oportunities">
                <li v-for="oportunity in proposalsPaidModalOportunities">
                    {{ oportunity }}
                    <div class="oportunity-delete" @click="removeOportunity(oportunity)">x</div>
                </li>
            </ul>
            <div class="btn-gradient" @click="updateProposalsPaidModal">Update</div>
        </div>

        <h2 class="section-title">Calendly settings</h2>
        <div class="notifications-content-form">
            <Textarea v-model="calendly_embed" />
            <span v-if="calendly_error" style="color: red;">{{ calendly_error }}</span>
            <div class="btn-gradient" @click="updateCalendly">Update</div>
        </div>
    </overlay-scrollbars>
</template>
  
<script>
import FileUpload from 'vue-upload-component'
import EditIcon from "@/components/icons/EditIcon.vue";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
    components: {
        FileUpload,
        EditIcon,
        TableRemoveItemIcon
    },
    data: () => ({
        documentName: '',
        files: [],
        extensions: ['jpg', 'jpeg', 'png', 'pdf'],
        documents: [],
        notificationContents: [],
        notification: {
            type: '',
            text: '',
            route: '',
        },
        proposalsPaidModal: {
            title: '',
            oportunity: '',
        },
        proposalsPaidModalOportunities: [],
        calendly_embed: '',
        calendly_error: '',
        routes: [
            'MyCompanies',
            'BusinessRequestPage',
            'Proposals',
            'UsersClients',
            'ClientsDetails',
            'Profile',
            'Subscriptions',
            'BuySellNetLicense',
            'BankAccounts',
            'CompanyProfile',
            'Company',
            'BanksSugestions',
            'LegalServiceIncorporation',
            'LegalSupport',
            'LegalService',
        ],
        loading: false,
    }),
    created() {
        this.updatePage();
    },
    methods: {
        async updateProposalsPaidModal(){
            this.loading = true;

            await axios.post('admin/settings/set-proposals-modal-content', {
                title: this.proposalsPaidModal.title,
                oportunities: this.proposalsPaidModalOportunities
            })
                .then(res => {
                    this.documents = res.data;
                })
                .catch(err => console.dir(err));

            this.loading = false;
        },
        addOportunity(){
            this.proposalsPaidModalOportunities.push(this.proposalsPaidModal.oportunity);
            this.proposalsPaidModal.oportunity = '';
        },
        removeOportunity(oportunity){
            this.proposalsPaidModalOportunities = this.proposalsPaidModalOportunities.filter(item => item !== oportunity);
        },
        async getProposalsPaidModalContent(){
            this.loading = true;
            await axios.get('settings/get-proposals-paid-modal-content')
                .then(res => {
                    this.proposalsPaidModal.title = res.data.proposals_paid_modal_title;
                    this.proposalsPaidModalOportunities = JSON.parse(res.data.proposals_paid_modal_oportunities);
                })
                .catch(err => console.dir(err));

            this.loading = false;
        },
        async getDocuments() {
            this.loading = true;
            await axios.get('admin/data/documents')
                .then(res => {
                    this.documents = res.data;
                })
                .catch(err => console.dir(err));

            this.loading = false;
        },
        async submit() {
            const formData = new FormData();
            formData.append('title', this.documentName)
            formData.append('file', this.files[0].file);

            await axios.post('admin/data/new-document', formData)
                .then(res => console.log(res))
                .catch(err => console.dir(err));

            this.updatePage();
        },
        async deleteDocument(id) {
            await axios.post('admin/data/delete-document/' + id)
                .catch(err => console.dir(err))

            this.updatePage();
        },
        async newNotificationContent() {
            const payload = {
                type: this.notification.type,
                text: this.notification.text,
                route: this.notification.route,
            };

            await axios.post('admin/data/notifications-content', payload)
                .then(res => console.log(res))
                .catch(err => console.dir(err));

            this.updatePage();
        },
        async getNotificationContent() {
            this.loading = true;

            await axios.get('admin/data/notifications-content')
                .then(res => {
                    this.notificationContents = res.data;
                })
                .catch(err => console.dir(err));

            this.loading = false;
        },
        async deleteNotificationContent(id) {
            await axios.delete('admin/data/notifications-content/' + id)
                .catch(err => console.dir(err))

            this.updatePage();
        },
        async updateCalendly() {
            this.loading = true;
            this.calendly_error = '',

            await axios.post('admin/settings/set-calendly-embed-url', {
                value: this.calendly_embed
            })
                .then(res => {
                    console.log(res.data)
                    if(res.data.error){
                        this.calendly_error = res.data.error;
                    }
                })
                .catch(err => console.dir(err));

            this.loading = false;
        },
        updatePage() {
            this.getDocuments();
            this.getNotificationContent();
            this.getProposalsPaidModalContent();
        },
    },
    computed: {
        postAction: function () {
            return process.env.VUE_APP_BACKEND_API_URL + 'documents';
        }
    }
}
</script>
  
<style lang="scss" scoped>
.oportunities{
    li{
        color: var(--c_black);
        display: flex;
        
        .oportunity-delete{
            color: red;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
.oportunity-input{
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .btn-gradient{
        margin: 0 0 0 10px !important;
    }

    .p-inputtext{
        margin-bottom: 0 !important;
    }
}

.main-table {
    margin-top: 20px;
    margin-bottom: 40px;
}

.documents-form,
.notifications-content-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30%;

    .btn-gradient {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .p-inputtext,
    .p-dropdown {
        border-radius: 30px;
        margin-bottom: 10px;
        width: 100%;
    }
}

.btn-document {
    display: inline-block;
}

.form-files {
    color: var(--c_black);
}

.drag-box {
    position: relative;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 2rem;
    margin: 1rem auto 1.75rem;
    background: linear-gradient(95.63deg, rgba(210, 236, 255, 0.5) 0%, rgba(214, 236, 255, 0.5) 9.37%, rgba(245, 232, 255, 0.5) 100%);
    border-radius: 24px;
    cursor: pointer;

    &.-active {
        border: 1px dashed var(--c_black);
    }

    .select-files {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__icon {
        width: 80px;
        height: 60px;
        margin-bottom: 1.25rem;
    }

    &__title {
        width: 400px;
        padding-bottom: .25rem;
        color: var(--c_black);
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
    }

    &__subtitle {
        color: #525252;
        font-weight: 400;
        font-size: 14px;
        // line-height: 22px;
        text-align: center;
        text-transform: capitalize;
    }
}
</style>
  