export default ({
  namespaced: true,
  state: () => ({
    paymentAtlasTableData: {
      colsWidth: [],
      header: [],
      body: [],
      paginationData: null,
    },
    adminAtlases: {
      colsWidth: ['5%', '14%', '14%', '14%', '14%', '14%', '8%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name of Bank' },
        { ttl: 'Country of Bank' },
        { ttl: 'Type of Account' },
        { ttl: 'Transaction History' },
        { ttl: 'Balance' },
        { ttl: '' },
      ],
    },
    providerAtlases: {
      colsWidth: ['5%', '14%', '14%', '14%', '14%', '14%', '14%', '8%'],
      header: [
        { ttl: '#' },
        { ttl: 'Provider' },
        { ttl: 'Name of Bank' },
        { ttl: 'Country of Bank' },
        { ttl: 'Type of Account' },
        { ttl: 'Transaction History' },
        { ttl: 'Balance' },
        { ttl: '' },
      ],
    },
  }),
  actions: {
    getPaymentAtlases({ commit }, payload = { page: 1 }) {
      const providers = payload.showProvidersAtlases ? '&providers=true' : ''
      axios.get('admin/payment-atlas?page=' + payload.page + providers)
        .then(({ data }) => {
          commit('setPaymentAtlases', { data: data, providers: payload.showProvidersAtlases })
        })
        .catch(err => console.dir(err))
    },
  },
  mutations: {
    setPaymentAtlases(state, payload) {
      state.paymentAtlasTableData.paginationData = payload.data
      state.paymentAtlasTableData.body = [];

      payload.data.data.forEach(el => {
        const name = !el.provider_id && !el.is_legal_company
          ? el.bank_name
          : el.bank_name + ' (Provided by Legal company)';

        const paymentAtlas = {
          id: el.id,
          name: name,
          country: el.country ? el.country.name : '',
          type: el.account_type,
          history: el.transaction_history,
          balance: +el.balance,
        }

        if (payload.providers && el.provider) {
          paymentAtlas.provider = {
            html: el.provider.full_name || 'noname',
            link: { name: "CustomerDetail", params: { id: el.provider_id } }
          }
        }
      })
    }
  }
})