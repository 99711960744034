<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">{{ isEdit ? 'Edit' : 'Create' }} Additional Services</div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Service Name</div>
        <div class="data">
          <input type="text" name="service_name" class="inpt" v-model="form.service_name">
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <select name="country_id" class="inpt" v-model="form.country_id">
            <option value="null">Select country</option>
            <option
              v-for="country in countries" :key="country.id"
              :selected="country.id === form.country_id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Service Type</div>
        <div class="data">
          <input type="text" name="service_type" class="inpt" v-model="form.service_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Price</div>
        <div class="data">
          <input type="text" name="price" class="inpt" v-model="form.price">
        </div>
      </div>
      <div class="row">
        <div class="label">Service Time</div>
        <div class="data">
          <input type="text" name="service_time" class="inpt" v-model="form.service_time">
        </div>
      </div>
      <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="save">Save</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Close from '@/components/img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    countries: [],
    form: {
      service_name: null,
      country_id: null,
      service_type: null,
      price: null,
      service_time: null,
      provider: true
    },
    error: null
  }),
  computed: {
    isEdit () {
      return this.options && this.options.id && this.options.form
    }
  },
  created () {
    this.getCountries()
  },
  methods: {
    ...mapActions({
      getAddServices: 'software_net/getAddServices'
    }),
    async getCountries () {
      await axios.get('admin/data/countries')
        .then(({data}) => {
          this.countries = data
          this.updateForm()
        })
        .catch(error => console.log(error))
    },
    updateForm () {
      if (!this.isEdit) return
      Object.assign(this.form, this.options.form)
    },
    async save () {
      const that = this
      await axios.post(`admin/software-add-services/update/${this.options.id}/${this.options.providerId}`, this.form)
          .then(({ data }) => {
            if (data.success) {
              this.close()
              that.$store.dispatch('editItemFromTable', {
                id: that.options.id,
                table: that.options.table,
                actionType: that.options.actionType
              })  
            }
          })
          .catch(error => console.log(error))  
        /*await axios.post(process.env.VUE_APP_BACKEND_API_URL +'admin/software-add-services', this.form)
          .then(({ data }) => {
            if (data.success) {
              this.getAddServices()
              for (const key in this.form) {
                if (Object.hasOwnProperty.call(this.form, key)) {
                  this.form[key] = null
                }
              }
              this.close()
              this.$noty.success('You successfully created additional service')
            }
          })
          .catch(error => console.log(error))*/
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 100%;
    max-height: 80vh;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    overflow-y: auto;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: .25rem 0;
      margin: .25rem 0 0;

      .label {
        min-width: 10rem;
        padding-right: .75rem;
        font-weight: bold;
      }

      .data {
        flex-grow: 1;

        .inpt {
          width: 100%;
          color: #000000;

          &__ta {
            height: 7rem;
          }
        }
      }

      &__desc {
        display: block;

        .label {
          padding-bottom: .25rem;
        }
      }
    }
  }

  &__footer {
    padding: 1rem 1.5rem 1.5rem;
  }
}
</style>
