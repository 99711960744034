<template>
	<div class="box box_referrals box_bg-currencies">
		<div class="box-header header-blur box-header_center">
			<div class="box-title">
				Referrals
			</div>
		</div>
		<div class="box-body">
			<div class="transparent-table">
				<div class="transparent-table__headers">
					<div class="transparent-table__header">
						<div class="transparent-table-header__img">
							<Mail />
						</div>
						Email
					</div>
					<div class="transparent-table__header">
						<div class="transparent-table-header__img">
							<User />
						</div>
						Fullname
					</div>
					<div class="transparent-table__header">
						<div class="transparent-table-header__img">
							<Stat />
						</div>
						Level
					</div>
				</div>
				<DataTable :value="referrals">
					<Column field="email" header="Email"></Column>
					<Column field="name" header="Name"></Column>
					<Column field="level" header="Level"></Column>
				</DataTable>
			</div>
		</div>

	</div>
</template>

<script>
import Employment from '@/components/img/Employment.vue'
import Mail from '@/components/img/Mail.vue'
import User from '@/components/img/User.vue'
import Stat from '@/components/img/Stat.vue'

export default {
	components: {
		Employment,
		Mail,
		User,
		Stat
	},
	props: {
		customer: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			initRequest: false,
			referrals: null
		}
	},
	methods: {
		getReferrals(page = 1) {
			axios.get('admin/customer-referrals/' + this.customerObj.id + '?page=' + page + '&per_page=5')
				.then(res => {
					this.referrals = [];
				
					res.data.data.forEach(el => {
						this.referrals.push({
							email: el.email,
							name: el.full_name,
							level: (el.depth - this.customerObj.depth),
						})
					})
				})
				.catch(err => console.dir(err))
		},
	},
	computed: {
		customerObj: function () {
			return this.customer
		}
	},
	watch: {
		customerObj: function (customerObj) {
			if (customerObj && !this.initRequest) {
				this.getReferrals()
				this.initRequest = true
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.box_referrals {
	overflow: hidden;
}

.transparent-table__headers {
	.transparent-table__header {
		width: 33.3%;
	}
}
</style>