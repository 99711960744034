<template>
	<overlay-scrollbars class="page page-softwareNet" ref="os">
    <div class="box box__addService">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Additional services</div>
          </div>
          <Table
            class="additional-services"
            @editItem="editAddService"
            @deleteItem="deleteAddService"
            :options="addServicesTableData"
            @change-page="getAdditionalServices"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Table from '@/components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
	components: {
		Table,
		Pagination,
	},
	data () {
		return {
			initRequest: false
		}
	},
	props: {
	    provider: {
	      type: Object,
	      required: true
	    }
	  },
	data () {
		return {
			addServicesTableData: {
		      colsWidth: ['5%', '20%', '20%', '15%', '15%', '15%', '10%'],
		      header: [
		        { ttl: 'ID' },
		        { ttl: 'Service Name' },
		        { ttl: 'Country' },
		        { ttl: 'Service Type' },
		        { ttl: 'Price' },
		        { ttl: 'Service Time' },
		        { ttl: '' },
		      ],
		      body: [],
		      paginationData: null
		    },
		}
	}, 
	methods: {
	    async getAdditionalServices (page = 1) {
		    await axios.get(process.env.VUE_APP_BACKEND_API_URL +'admin/software-add-services?page='+ page +'&provider_id=' + this.provider.id + '&per_page=5')
	        .then(({ data }) => {
	          this.setAddServicesTableData(data)
	        })
	    },
	    setAddServicesTableData (data) {
	      this.addServicesTableData.paginationData = data;
	      this.addServicesTableData.body = []
	      data.data.forEach(el => {
	        const name = !el.provider_id && !el.is_legal_company
	          ? el.service_name
	          : {
	              type: 'info',
	              html: el.service_name,
	              info: {
	                pos: 'tr',
	                txt: 'Provided by Legal company'
	              },
	            }
	        this.addServicesTableData.body.push([
	          el.id,
	          name,
	          el.country ? el.country.name : '',
	          el.service_type,
	          el.price,
	          el.service_time,
	          { type: 'edit-delete-actions', id: el.id }
	        ])
	      });
	    },
	    editAddService (obj) {
	      const item = this.addServicesTableData.paginationData.data.find(el => +el.id === obj.id)
	      this.$store.commit('setModal', {
	        template: 'provider-service',
	        options: {
	          id: obj.id,
	          actionType: 'edit',
	          table: 'additional-services',
	          type: 'additional services', 
	          providerId: this.provider.id,
	          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/software-add-services/update/' + obj.id + '/' + this.provider.id,
	          form: {
	            service_name: item.service_name,
	            country_id: item.country ? item.country.id : null,
	            service_type: item.service_type,
	            price: item.price,
	            service_time: item.service_time
	          }
	        }
	      })
	    },
	    deleteAddService (obj) {
	      this.$store.commit('setModal', {
	        template: 'delete',
	        options: {
	          id: obj.id,
	          actionType: 'delete',
	          table: 'additional-services',
	          type: 'additional services',
	          url: process.env.VUE_APP_BACKEND_API_URL + 'admin/software-add-services/delete/' + obj.id + '/' + this.provider.id,
	          successfullMessage: 'You successfully deleted additional service',
	        }
	      })
	    },
	    updateCurrentPage(tableName, actionType) {
	      let currentPage = 1
	      if (actionType == 'edit' || actionType == 'delete') {
	        try {
	        currentPage = document.getElementsByClassName(tableName)[0]
	          .getElementsByClassName('pagination')[0]
	          .getElementsByClassName('active')[0]
	          .getElementsByClassName('page-link')[0]
	          .innerText.trim().replace( /\D+/g, '')
	        } catch (e) {
	           console.log(e)
	        }
	      }
	      if (tableName == 'additional-services') {
	        this.getAdditionalServices(currentPage)
	      }
	    },
	},
	created () {
		if (this.provider && !this.initRequest) {
			this.getAdditionalServices()
			this.initRequest = true
		}
	},
	watch: {
		provider: function(provider) {
			if (this.provider && !this.initRequest) {
				this.getAdditionalServices()
				this.initRequest = true
			}
		},
		'$store.state.deleteItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    },
	    '$store.state.editItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    }
	}
}
</script>

<style scoped>
.title {
	position: relative;
    padding-bottom: 0.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.91);
    margin-bottom: 10px;
}

.ttl {
	margin-bottom: 20px;
}

.page-bosBusiness .box_legalService .content .block .hdr .ttl {
    padding: 0.25rem 0.5rem;
    margin: 5px;
} 

.page-bosBusiness .box_legalService .content .block {
    margin: 1rem 0;
}

.page {
    padding: 0;
}
</style>