<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Task
      </div>
    </div>
    <div class="modal__body">
      <overlay-scrollbars>
        <div class="row info">
          <div class="label">
            #
          </div>
          <div class="data">
            Comment
          </div>
        </div>
        <div class="row" v-for="(comment, i) in form.task_comments" v-if="form.task_comments.length" style="width:100%; margin: 0; padding: 0">
          <div class="row info" v-if="comment.hasOwnProperty('id')" style="margin: 0; padding: 0">
            <div class="label">
              Comment # {{ i + 1 }}
              <br />
              {{ getFormattedDate(comment.created_at) }}  
              <br />
              <div class="svg-delete-btn" @click="deleteTaskComment(i)">
                <Close class="img"/>
              </div>
            </div>
            <div class="data" :style="{ whiteSpace: 'pre-line' }">
              {{ comment.comment }}
            </div>
          </div>
          <div v-else>
            <div class="form-group">
              <label for="task_description">
                Comment № {{ i + 1 }}
                <span class="svg-delete-btn" @click="deleteTaskComment(i)">
                  <Close class="img"/>
                </span>
              </label>
              <textarea name="comment" id="comment"  v-model="comment.comment" placeholder="Comment"/>
            </div>
          </div>
        </div>
        <div style="margin-top: 24px">
          <div class="row">
            <div class="data">
              <center>
                <button class="btn-add" @click="addTaskComment">
                  Add task comment
                </button>
              </center>
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
    <div class="modal__footer">
      <div class="btn btn__save" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import SelectSimple from '@/components/common/Select.vue'
import Close from './../img/Close.vue'
import draggable from 'vuedraggable'
import BlueFile from '../img/BlueFile.vue'
import BlueDownload from '../img/BlueDownload.vue'
import Tmblr from '@/components/elem/Tmblr'

export default {
  components: {
    draggable,
    SelectSimple,
    Close,
    BlueFile,
    BlueDownload,
    Tmblr
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        task: null,
        client_name: null,
        tasked_at: null,
        task_description: null,
        status: null,
        is_manager_can: false,
        responsible_manager_name: null,
        control_manager_name: null,
        asystent_name: null,
        deadline_at: null,
        task_links: [],
        task_links_to_delete: [],
        responsible_managers: [],
        control_managers: [],
        task_comments: [],
      },
      error: null,
    };
  },
  async created () {
    await this.getTask()
  },
  methods: {
    addTaskComment(){
      this.form.task_comments.push({
        comment: null,
      })
    },
    deleteTaskComment(index){
      if(this.form.task_comments[index].hasOwnProperty('id')){
        this.form.task_comments_to_delete.push(this.form.task_comments[index])
      }
    
      this.form.task_comments.splice(index, 1)
    },
    async getTask(){
      const that = this
      await axios.get('admin/tasks/' + this.options.id)
      .then(function (response) {
        that.form = response.data
        that.form.task_comments_to_delete = []
        console.log(that.form.task_comments)
      })
      .catch(function (error) {
        console.log(error);
      })
    },
    getFormattedDate(value){
      if(value){
        const date = new Date(value);
        
        const formattedDate = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        
        return formattedDate;
      }
    },
    async submit(){
      let url = process.env.VUE_APP_BACKEND_API_URL + 'admin/tasks/' + this.options.id + '/comments'
      
      await axios.post(url, this.form)
        .then((response) => {
          this.close();
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__body {
    height: 68vh;
    padding-right: 2.5rem;
    overflow: hidden;

    .os-host {
      height: 100%;
      width: 100%;
    }
  }
  .svg-delete-btn{
    width: 1.5rem;
    height: 1.5rem;
    background-color: #B03636;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form {
    width: 100%;
    padding-right: 1rem;

    &-group {
      --select_height: 46px;

      label {
        font-weight: 700;
        font-size: 20px;
        line-height: 2;
      }

      input,
      select,
      textarea {
        min-width: 100%;
        max-width: 100%;
        height: var(--select_height);
        padding: 12px 25px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        border-radius: 24px;
        outline: none;
      }
      textarea{
        height: 120px;
      }

      select {
        appearance: none;
        cursor: pointer;
        color: #333 !important;
      }
      .btn{
        color: #ffffff;
        font-size: 13px;
        padding: .25rem .75rem;
        background: var(--gr_new);
        border-radius: 6px;
        border: none;
      }
      .file{
        .btn{
          padding: 0;
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  .btn-add{
    color: #ffffff;
    font-size: 13px;
    padding: .25rem .75rem;
    background: var(--gr_new);
    border-radius: 6px;
    border: none;
  }
  .select {
    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-arrow {
      position: absolute;
      top: calc(var(--select_height) / 2);
      right: 1.5rem;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      pointer-events: none;
    }
  }

  &__footer {
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    &__delete {
      background: var(--c_error);
    }
  }
}
.box_addFiles {
  width: 30%;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  display: flex;
  flex-direction: column;
  .ttl {
    padding-bottom: .25rem;
  }
  .separ {
    width: 3rem;
    height: .25rem;
    background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 14px;
    margin: .25rem 0;
  }
  .content {
    padding: 1rem;
    flex-grow: 1;
    border: 1px dashed #0071e3;
    box-sizing: border-box;
    filter: drop-shadow(0px 12px 23px rgba(62, 73, 84, 0.04));
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .choose {
      text-align: center;
      .btn {
        padding: 0.5rem;
        background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
        border-radius: 6px;
        color: #fff;
        font-size: 13px;
      }
      .desc {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
      }
    }
    &_many {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.75rem 6px;
      .draggable {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
      }
      .file {
        position: relative;
        text-align: center;
        width: 4.5rem;
        max-width: 4.5rem;
        svg.img {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;
          fill: #0071e3;
          path{
            fill: #0071e3;
          }
        }
        .btn {
          &_del {
            position: absolute;
            top: 0;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background-color: #B03636;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 40%;
              height: 40%;
            }
          }
          &_download {
            position: absolute;
            top: 1.7rem;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg.img {
              width: 90%;
              height: 90%;
            }
          }
        }
        .desc {
          font-size: 10px;
          color: #333333;
          word-break: break-word;
        }
      }
      .add {
        text-align: center;
        width: 2.5rem;
        max-width: 2.5rem;
        .btn {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background: linear-gradient(270deg, #0071e3 0%, #0071e3 100%), #0071e3;
          display: flex;
          align-items: center;
          justify-content: center;
          svg.img {
            width: 45%;
            height: 45%;
            transform: rotate(45deg);
          }
        }
        .desc {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#risks {
  #risks>label {
    margin-bottom: 10px;
  }

  .form-check-input[type=radio] {
    border-radius: .25em;
  }

  .form-check .form-check-input {
    float: left;
  }

  .risks-items{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .risk-check{
    min-width: 120px;
    &>label{
      display: block;
    }
  }

  .form-check-input {
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    margin: 0;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .form-check-input:checked {
    background-color: #0071e3;
    border-color: #0071e3;
  }
}
.gap-12{
  gap: 12px;
}
.row.info{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  border-bottom: 1px dashed #ababb2;
  margin-top: 0 !important;
  .label{
    font-weight: 700;
    padding: 12px 12px 12px 0;
    max-width: 35%;
    flex: 0 0 35%;
    border-right: 1px dashed #ababb2;
  }
  .data{
    display: flex;
    max-width: 65%;
    flex: 0 0 65%;
    padding: 12px 0 12px 12px;
  }
}
</style>
