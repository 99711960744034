<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Applied Offer
      </div>
    </div>
    <div class="modal__body">
      <div class="box box_selectedAcc">
        <div class="header">
        </div>
        <div class="content">
          <div class="block">
            <div class="hdr">
              <!-- <Bank2 class="img" /> -->
              <div class="ttl">Bank account</div>
            </div>
            <Table :key="bankAccKey" :options="bankAcc" :chatAppliedOfferType="'bank-account'" @close="close" />
          </div>
          <div class="block block_mt">
            <div class="hdr">
              <!-- <CreditCards class="img" /> -->
              <div class="ttl">Merchant account</div>
            </div>
            <Table :key="merchantAccKey" :options="merchantAcc" :chatAppliedOfferType="'merchant-account'" @close="close" />
          </div>
          <div class="block block_mt">
            <div class="hdr">
              <!-- <CreditCards class="img" /> -->
              <div class="ttl">Legal Service</div>
            </div>
            <Table :key="legalKey" :options="legalService"  :chatAppliedOfferType="'legal-service'" @close="close" />
          </div>
          <div class="block block_mt" ref="bosBlock">
            <div class="hdr">
              <div class="ttl">Buy & Sell NET License</div>
            </div>
            <Table :options="businesOffers" :key="businesOffersKey"  :chatAppliedOfferType="'buy-sell'" @close="close" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import Info from './../elem/Info.vue'
import Compliance from './../elem/Compliance.vue'
import Table from './../elem/Table.vue'
import Bank2 from './../img/Bank2.vue'
import CreditCards from './../img/CreditCards.vue'

export default {
  components: {
    Close,
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      bankOffersData: [],
      bankAccKey: 0,
      merchantOffersData: [],
      merchantAccKey: 0,
      legalData: [],
      legalKey: 0,
      bankAcc: {
        colsWidth: ['3%', '43%', '43%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Name', info: null },
          { ttl: 'Country', info: null },
        ],
        body: [],
      },
      merchantAcc: {
        colsWidth: ['3%', '43%', '43%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Name', info: null },
          { ttl: 'Country', info: null },
        ],
        body: [],
      },
      legalService: {
        colsWidth: ['3%', '43%', '45%'],
        header: [
          { ttl: '#', info: null },
          { ttl: 'Type of Services', info: null },
          { ttl: 'Country', info: null },
        ],
        body: [],
      },
      businesOffersKey: 1,
      businesOffers: {
        colsWidth: ['4%', '43%', '43%'],
        header: [
          { ttl: '#' },
          { ttl: 'Type of Business' },
          { ttl: 'Country' },
        ],
        body: []
      }
    };
  },
  created () {
    this.getBankOffers()
    this.getMerchantOffers()
    this.getAppliedLegalServices()
    this.getAppliedBusinesOffers()
  },
  methods: {
    getBankOffers () {
      const that = this
      axios.get('admin/offers/applied/bank/' + this.options.offerUser.id)
        .then(function (response) {
          that.bankOffersData = response.data
          that.bankAcc.body = []
          that.bankOffersData.forEach(el => {
            that.bankAcc.body.push([
              el.serial_number, el.financial_institution_name, el.country ? el.country.code : '', {
                type: 'hidden-id', id: el.id }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantOffers () {
      const that = this
      axios.get('admin/offers/applied/merchant/' + this.options.offerUser.id)
        .then(function (response) {
          that.merchantOffersData = response.data
          that.merchantAcc.body = []
          that.merchantOffersData.forEach(el => {
            that.merchantAcc.body.push([
              el.serial_number, el.financial_institution_name, el.country ? el.country.code : '', {
                type: 'hidden-id', id: el.id }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedLegalServices () {
      const that = this
      axios.get('admin/legal-atlas/applied/' + this.options.offerUser.id)
        .then(function (response) {
          that.legalService.body = []
          that.legalData = response.data
          that.legalData.forEach(el => {
            that.legalService.body.push([
              el.serial_number, el.services_type, el.country ? el.country.code : '', {
                type: 'hidden-id', id: el.id }
            ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedBusinesOffers () {
      const that = this
      axios.get('admin/business-offers/applied/' + this.options.offerUser.id)
        .then(({ data }) => {
          this.businesOffers.body = []
          const serverData = data.data
          serverData.forEach(el => {
            console.log(el)
            that.businesOffers.body.push([
              el[0], el[1], el[3], {
                type: 'hidden-id', id: el[0] }
            ])
          })
          setTimeout(() => {
            var element = this.$refs.bosBlock
            console.log(element)
            element.scrollIntoView({ behavior: 'smooth', inline: 'start' })
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    // height: 25rem;
  }

  &__header {
    padding: 2.5rem 0.75rem 1.5rem;
  }

  &__body {
    max-height: 80vh;
    display: block;
    padding-bottom: 2rem;
    overflow: auto;
  }
}

.box {
  &_selectedAcc {
    width: 100%;
  }
}

.tbl {
  color: #ffffff;
}

.block_mt {
  margin-top: 20px;
}
</style>
