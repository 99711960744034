<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Create individual offer (bank account)
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group half">
          <label>Name of financial institution</label>
          <input v-model="form.financial_institution_name" type="text" class="form-control" placeholder="EMI">
          <span v-show="formErrors.financial_institution_name" class="invalid-feedback">{{ formErrors.financial_institution_name }}</span>
        </div>
        <div class="form-group half">
          <label>Type of account</label>
          <input v-model="form.account_type" type="text" class="form-control" placeholder="SEPA">
          <span v-show="formErrors.account_type" class="invalid-feedback">{{ formErrors.account_type }}</span>
        </div>
        <div class="form-group half">
          <label>Open/Integration fee</label>
          <div class="inpt-block">
            <input v-model="form.open_integration_fee" type="text" class="form-control" placeholder="2.500.00">
            <div class="currency-symbol">€</div>
          </div>
          <span v-show="formErrors.open_integration_fee" class="invalid-feedback">{{ formErrors.open_integration_fee }}</span>
        </div>
        <div class="form-group half">
          <label>Onboarding time</label>
          <div class="inpt-block">
            <input v-model="form.onboarding_time" type="text" class="form-control" placeholder="3">
            <div class="desc">works day</div>
          </div>
          <span v-show="formErrors.onboarding_time" class="invalid-feedback">{{ formErrors.onboarding_time }}</span>
        </div>
        <div class="form-group">
          <label>Incoming fee</label>
          <input v-model="form.incoming_fee" type="text" class="form-control" placeholder="0.2%, min 10.00€, max 100.00€">
          <span v-show="formErrors.incoming_fee" class="invalid-feedback">{{ formErrors.incoming_fee }}</span>
        </div>
        <div class="form-group">
          <label>Outgoing fee</label>
          <input v-model="form.outgoing_fee" type="text" class="form-control" placeholder="0.5%, min 15.00€, max 350.00€">
          <span v-show="formErrors.outgoing_fee" class="invalid-feedback">{{ formErrors.outgoing_fee }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      message: '',
      error: '',
      form: {
        financial_institution_name: '',
        account_type: '',
        open_integration_fee: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: ''
      },
      formErrors: {
        financial_institution_name: '',
        account_type: '',
        open_integration_fee: '',
        onboarding_time: '',
        incoming_fee: '',
        outgoing_fee: '',
      }
    };
  },
  methods: {
    clearErrors() {
      for (let key in this.form) {
        this.formErrors[key] = ''
      }
    },
    async submit() {
      const that = this
      this.clearErrors()
      axios.post('admin/customers/individual-offer/' + that.options.id, this.form)
      .then(function (response) { 
        if (response.data.errors) {
          const errors = response.data.errors
          for (let key in errors) {
            that.formErrors[key] = errors[key][0]
          }
        }
        if (response.data.success) {
          that.close()
          that.$noty.success('You successfully created individual offer')
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
}
</style>
