<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close"/>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Contact us
      </div>
    </div>
    <div class="modal__body" style="flex-direction: column">
        <textarea v-model="message" rows="5" class="form-control" placeholder="Enter text..."></textarea>
        <span v-if="error.length > 0" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Send</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data() {
    return {
      message: '',
      error: ''
    };
  },
  methods: {
    async submit() {
      if (this.message.length === 0) {
        this.error = 'Please, enter message'
        return
      }
      const that = this
      axios.post('contact-message', {
          message: this.message,
        })
      .then(function (response) { 
        if (response.data.success) {
          that.close()
          that.$noty.success('You successfully send message')
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>

.modal__wrapper {
  height: 25rem;
}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin: auto 0rem;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 0.75rem;
    box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    outline: none;
    -webkit-appearance: none;
}

.modal__footer {
  .btn {
    font-size: 18px;
    padding: 1rem 3rem;
    margin: 0.25rem 1rem;
    background: var(--gr_new);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
  }
}
</style>
